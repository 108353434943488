export default class MuterSingleton {
  private static _instance: MuterSingleton;
  private _shouldMute: boolean = false;
  private _currentMediaElements: HTMLMediaElement[] = [];

  public isMuting(): boolean {
    return this._shouldMute;
  }

  public static get instance(): MuterSingleton {
    if (!MuterSingleton._instance) {
      MuterSingleton._instance = new MuterSingleton();
    }
    return MuterSingleton._instance;
  }

  private constructor() {
    // When a play() is called on a video/audio element, add the media to the list of media elements
    // Store the original play method
    const originalPlay = HTMLMediaElement.prototype.play;

    // Override the play method
    HTMLMediaElement.prototype.play = function () {
      if (MuterSingleton.instance.isMuting()) {
        this.muted = true;
      }

      // Check if the element is already in the list of media elements
      if (!MuterSingleton.instance._currentMediaElements.includes(this)) {
        // Add the element to the list
        MuterSingleton.instance._currentMediaElements.push(this);
      }
      return originalPlay.call(this);
    };
  }

  public toggleMute(): void {
    if (this._shouldMute) {
      this.unmuteMedia();
    } else {
      this.muteMedia();
    }
  }

  public static getInstance(): MuterSingleton {
    if (!MuterSingleton.instance) {
      MuterSingleton._instance = new MuterSingleton();
    }
    return MuterSingleton.instance;
  }

  public muteMedia(): void {
    this._shouldMute = true;
    MuterSingleton.instance._currentMediaElements.forEach((element) => {
      element.muted = true;
    });
  }

  public unmuteMedia(): void {
    this._shouldMute = false;

    // Get the player and check if the BGMedia elements are muted from After Effects or not before unmuting
    const player = (window as any).p;
    const bgMediaElements = player?.bgMedia;
    MuterSingleton.instance._currentMediaElements.forEach((element) => {
      if (bgMediaElements) {
        const bgMediaElement = bgMediaElements.find(
          (bgMediaElement) => bgMediaElement.mediaEl.src === element.src
        );
        if (bgMediaElement && bgMediaElement.origin.mutedFromAe) return;
      }
      element.muted = false;
    });
  }
}
