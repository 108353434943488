import { observer } from "mobx-react-lite";
import { dropDownGroups, IGroupsDict } from "../mod-definitions";
import { Dropdown, Menu, Tooltip } from "antd";
import React from "react";
import { ButtonRender } from "./NewModButtonRender";
import { ProjectBasicInfo } from "../../../stores/PlatformStore";
import { ConnectorIcon } from "../../../icons-and-animations/Icons";

export const DropDownMenuRender = observer(
  ({
    changeDefGroup,
    playerStore,
    selectedProject,
    modsStore,
    email,
    selectedProjectBasicInfo,
  }: {
    changeDefGroup: IGroupsDict;
    email;
    playerStore;
    selectedProject;
    modsStore;
    selectedProjectBasicInfo: ProjectBasicInfo | null;
  }) => {
    const { color, dropDownText, animation } = dropDownGroups[
      changeDefGroup.name
    ];
    const menu = (
      <Menu theme={"dark"}>
        {changeDefGroup.defs.map((def, key, a) => {
          const enabled =
            !def.fromVersion ||
            !selectedProjectBasicInfo?.playerVersionToUse ||
            def.fromVersion.split(".")[0] <=
              selectedProjectBasicInfo.playerVersionToUse.split(".")[0];
          const menuItem = (
            <Menu.Item
              key={key}
              onClick={() => {
                if (enabled) modsStore.addMod(def.change);
              }}
              style={{
                color: enabled ? "white" : "grey",
                cursor: enabled ? "pointer" : "not-allowed",
              }}
            >
              <div
                style={{
                  color: def.color,
                  display: "flex",
                  marginRight: "0.3rem",
                }}
              >
                <ConnectorIcon type={def.change.type} />
              </div>

              {def.text}
            </Menu.Item>
          );
          return (
            <React.Fragment key={key}>
              {enabled ? (
                <>
                  {menuItem}
                  {def.text === "Expression" && (
                    <div className="divider-dropdown" />
                  )}
                </>
              ) : (
                <Tooltip
                  title={`This connector is not available for your project's player version, please rerender the project to use the latest features.`}
                >
                  {menuItem}
                </Tooltip>
              )}
            </React.Fragment>
          );
        })}
      </Menu>
    );

    return (
      <Dropdown
        overlay={menu}
        placement="bottom"
        arrow
        overlayClassName={"NewModGroupDropdown-overlay"}
      >
        <span className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <ButtonRender
            color={color}
            isInDropdown={true}
            disabledDef={false}
            text={dropDownText}
            // changeDef={changeDefGroup.defs}
            playerStore={playerStore}
            selectedProject={selectedProject}
            modsStore={modsStore}
            animation={animation}
          />
        </span>
      </Dropdown>
    );
  }
);
