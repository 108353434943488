import { Modal } from "antd";

class ConfirmationModal {
  confirm(
    message: string,
    okText?: string,
    maskClosable?: boolean,
    cancel?: () => void
  ): Promise<boolean> {
    return new Promise((resolve) => {
      Modal.confirm({
        width: 520,
        autoFocusButton: "cancel",
        maskClosable: maskClosable,
        content: (
          <div className="popup-text" style={{ fontSize: "12px" }}>
            {message}
          </div>
        ),
        icon: null,
        okText: okText ? okText : "Proceed without saving",
        okType: "text",
        cancelText: "Cancel",
        className: "blings-confirm-modal",
        cancelButtonProps: { className: "blings-btn", id: "modal-cancel" },
        okButtonProps: {
          id: "modal-ok",
          style: {
            fontSize: "12px",
            textDecoration: "underline",
            cursor: "pointer",
            background: "none",
            border: "none",
          },
        },
        onOk() {
          resolve(true); // Resolve the promise with true if confirmed
        },
        onCancel() {
          cancel && cancel();
          resolve(false); // Resolve the promise with false if canceled
        },
      });
    });
  }
}

const confirmationModal = new ConfirmationModal();
export default confirmationModal;
