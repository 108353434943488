import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { Instance } from "mobx-state-tree";
import { CSS } from "@dnd-kit/utilities";
import { SceneModel } from "../../stores/FlowStore";
import { Button } from "antd";
import {
  useSortable,
  defaultAnimateLayoutChanges,
  AnimateLayoutChanges,
} from "@dnd-kit/sortable";
import { UniqueIdentifier } from "@dnd-kit/core";
import styles from "./styles.module.scss";
import {
  RightArrowIcon,
  DragIcon,
  CloseIcon,
} from "../../icons-and-animations/Icons";

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

export const SortableScene = observer(
  ({
    scene,
    isActive,
    id,
    isLastIndex,
    onRemove,
    dragOverlay = false,
    dragging = false,
  }: {
    scene: Instance<typeof SceneModel>;
    isActive: boolean;
    id: UniqueIdentifier;
    isLastIndex: boolean;
    onRemove: (id: string) => void;
    dragOverlay?: boolean;
    dragging?: boolean;
  }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
    } = useSortable({ id: id as UniqueIdentifier, animateLayoutChanges });

    const removeScene = () => {
      onRemove(scene.id as string);
    };

    const sceneElement = () => {
      if (!isActive) {
        return (
          <>
            <div className={styles["content-wrapper"]}>
              <div className={styles["scene-name"]}> {scene.label}</div>
            </div>
            {isLastIndex ? null : (
              <span style={{ paddingLeft: "10px" }}>
                <RightArrowIcon />
              </span>
            )}
          </>
        );
      } else {
        return (
          <div className={styles["active-scene-item-wrapper"]}>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
              {...listeners}
            >
              <span style={{ display: "flex" }}>
                <DragIcon />
              </span>
              <div
                className={classNames(
                  styles["content-wrapper"],
                  styles["active-scene"]
                )}
              >
                <div className={styles["scene-name"]}> {scene.label}</div>
              </div>
            </div>
            <span style={{ display: "flex" }}>
              <Button
                onClick={removeScene}
                tabIndex={0}
                type="text"
                className="remove-scene-btn"
                style={{ padding: "0" }}
              >
                <CloseIcon />
              </Button>
            </span>
          </div>
        );
      }
    };

    return (
      <>
        <li
          className={classNames(
            styles.Wrapper,
            styles.fadeIn,
            dragging && styles.dragging,
            styles.sorting,
            dragOverlay && styles.dragOverlay,
            isActive && styles.active
          )}
          style={{ pointerEvents: isActive ? "auto" : "none" }}
        >
          <div
            className={classNames(
              dragging && styles.dragging,
              dragOverlay && styles.dragOverlay,
              isActive && styles.active,
              styles.Item,
              isActive && styles["tag-item"]
            )}
            ref={setNodeRef}
            {...attributes}
            {...transform}
            key={scene.id}
            data-cypress="draggable-item"
            style={{
              transition,
              transform: CSS.Translate.toString(transform),
              opacity: true ? 0.5 : undefined,
            }}
          >
            {sceneElement()}
          </div>
        </li>
      </>
    );
  }
);
