import { useState, useCallback, useEffect, useMemo, useRef } from "react";
import styles from "./styles.module.scss";
import { Button, Dropdown } from "antd";
import type { MenuProps } from "antd";
import { observer } from "mobx-react-lite";
import { Instance } from "mobx-state-tree";
import { ILineupModel, LineupModel } from "../../stores/FlowStore";
import {
  DotsMenuIcon,
  CrownIcon,
  EditIcon,
} from "../../icons-and-animations/Icons";
import { flowsStore } from "../../stores/FlowStore";
import classNames from "classnames";
import confirmationModal from "../BlingsModal/CustomConfirm";

const validateFlowName = (
  label: string,
  flows: Array<ILineupModel>,
  flowId: string
) => {
  let message = "";
  const cleanLable = label.trim();
  if (cleanLable === "") {
    message = "Name must be not empty.";
  }
  if (
    flows.some((item) => item.lineupId === cleanLable && item.id !== flowId)
  ) {
    message = "Name must be unique.";
  }
  if (label.length > 50) {
    message = "Name must be less than 50 characters.";
  }
  return message;
};

export const FlowHeader = observer(
  ({
    flow,
    isActive,
  }: {
    flow: Instance<typeof LineupModel>;
    isActive: boolean;
  }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newLabel, setNewLabel] = useState(flow.lineupId);
    const inputRef = useRef<HTMLInputElement>(null);
    const headerMenuRef = useRef<HTMLInputElement>(null);

    const { flows, selectedFlow, isInEdit } = flowsStore;
    const { scenes, lineupId, isDefault } = flow;

    const handleEdit = () => {
      setIsEditing((isEditing) => !isEditing);
    };

    useEffect(() => {
      if (isEditing) {
        inputRef.current?.focus();
      }
    }, [isEditing]);

    const getDropDownMenu = useMemo((): MenuProps => {
      const menuItems: MenuProps["items"] = [
        {
          key: "1",
          label: (
            <div
              className={classNames(
                scenes.length === 0 ? styles["header-menu-item-disabled"] : "",
                styles["header-menu-item"]
              )}
              onClick={() => flowsStore.duplicateFlow(flow.id)}
            >
              Duplicate Flow
            </div>
          ),
          disabled: scenes.length === 0,
        },
        {
          key: "2",
          label: (
            <div
              className={styles["header-menu-item"]}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                flowsStore.deleteFlow(flow.id);
              }}
            >
              Delete Flow
            </div>
          ),
        },
        {
          key: "3",
          label: (
            <div
              className={classNames(
                isDefault ? styles["header-menu-item-disabled"] : "",
                styles["header-menu-item"]
              )}
              onClick={() => flowsStore.setDefaultFlow(lineupId)}
            >
              Set this Flow as Default
            </div>
          ),
          disabled: isDefault || scenes.length === 0,
        },
      ];

      return { items: menuItems };
    }, [flow, scenes.length, isDefault, lineupId]);

    useEffect(() => {
      if (!isActive) {
        setIsEditing(false);
      }
    }, [isActive]);

    const handleChange = (event) => {
      setNewLabel(event.target.value);
    };

    const handleOnBlur = useCallback(
      async (event) => {
        event.stopPropagation();
        event.preventDefault();

        const message = validateFlowName(newLabel, flows, flow.id);
        if (message === "") {
          setIsEditing(false);
          flowsStore.changeFlowLabel(flow.id, newLabel.trim());
          flowsStore.setUnsavedChanges(false);
        } else {
          flowsStore.setUnsavedChanges(true);

          const confirmed = await confirmationModal.confirm(
            message,
            "Discard Changes",
            undefined,
            () => setNewLabel(flow.lineupId)
          );

          if (!confirmed) {
          } else {
            setNewLabel(flow.lineupId);
            flowsStore.setUnsavedChanges(false);
            setIsEditing(false);
          }
        }
      },
      [newLabel, flows, flow.id, flow.lineupId, setIsEditing]
    );

    const headerElement = () => {
      if (isInEdit && selectedFlow?.id === flow.id) {
        return (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              {flow.isDefault ? (
                <span style={{ display: "flex", padding: "0 5px" }}>
                  <CrownIcon />
                </span>
              ) : (
                <div className={styles.Actions}></div>
              )}
              {isEditing ? (
                <input
                  ref={inputRef}
                  type="text"
                  value={newLabel}
                  tabIndex={0}
                  onChange={handleChange}
                  onBlur={handleOnBlur}
                  className={styles["input-flow-name"]}
                />
              ) : (
                <div className={styles.Label}>{flow.lineupId}</div>
              )}
            </div>
            <>
              <Button
                type="text"
                onClick={handleEdit}
                style={{
                  padding: "0",
                  display: isEditing && isInEdit ? "none" : "block",
                }}
                className={styles["edit-flow-name-btn"]}
              >
                <EditIcon />
              </Button>
            </>
            <Dropdown
              menu={getDropDownMenu}
              getPopupContainer={() => headerMenuRef.current || document.body}
              trigger={["click"]}
              overlayClassName="action-flow"
              overlayStyle={{
                background: "blue !important",
                backgroundColor: "blue !important",
              }}
            >
              <div
                onClick={(e) => e.preventDefault()}
                style={{ cursor: "pointer" }}
              >
                <DotsMenuIcon />
              </div>
            </Dropdown>
          </>
        );
      } else {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {flow.isDefault ? (
              <span style={{ display: "flex", padding: "0 5px" }}>
                <CrownIcon />
              </span>
            ) : (
              <div className={styles.Actions}></div>
            )}
            <div className={styles.Label}>{flow.lineupId}</div>
          </div>
        );
      }
    };
    return (
      <div
        className={classNames(
          styles.Header,
          isActive && isInEdit ? styles["active-header"] : ""
        )}
      >
        {headerElement()}
      </div>
    );
  }
);
