import React from "react";
import "./BlingsButton.scss";
import { Spin } from "antd";
import { AsyncOpState } from "../../stores/async-op-state";

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
  children?: string | JSX.Element;
  disabled?: boolean;
  type?: "submit";
  className?: string;
  outline?: boolean;
  opState?: AsyncOpState;
  btnTexts?: IBtnTexts;
};

export const BlingsButton = ({
  onClick,
  children,
  disabled,
  type,
  className,
  opState,
  btnTexts = {},
  outline = false,
}: Props) => {
  disabled = disabled || (opState && isDisabled(opState));
  const btnContent = children || (opState && getBtnContent(opState, btnTexts));
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={[
        "BlingsButton",
        disabled ? "Disabled" : "",
        className ? className : "",
        outline ? "Outlined" : "",
      ].join(" ")}
    >
      {btnContent}
    </button>
  );
};

const isDisabled = (opState: AsyncOpState) => {
  const disableBtnState = [
    AsyncOpState.Untouched,
    AsyncOpState.Saving,
    AsyncOpState.Success,
    AsyncOpState.Error,
  ];

  return disableBtnState.includes(opState);
};

const getBtnContent = (opState: AsyncOpState, btnTexts: IBtnTexts) => {
  return opState === AsyncOpState.Saving ? (
    <Spin size="small" style={{ height: "17.6px" }} />
  ) : (
    getBtnTxt(opState, btnTexts)
  );
};
export type IBtnTexts = {
  [key in AsyncOpState]?: string;
};

const getBtnTxt = (opState: AsyncOpState, btnTexts: IBtnTexts) => {
  switch (opState) {
    case AsyncOpState.Error:
      return btnTexts[AsyncOpState.Error] || "Error";
    case AsyncOpState.Saving:
      return btnTexts[AsyncOpState.Saving] || "Saving";
    case AsyncOpState.Success:
      return btnTexts[AsyncOpState.Success] || "Saved";
    case AsyncOpState.Untouched:
      return btnTexts[AsyncOpState.Untouched] || "Save";
    case AsyncOpState.Changed:
      return btnTexts[AsyncOpState.Changed] || "Save";

    default:
      return "Save";
  }
};
