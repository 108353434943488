import { InputNumber, Space, Typography } from "antd";
import { ModsPanelCompProps } from "../ModsPanel";
import { ITransitionTimingMod } from "@blings/blings-player";

export function TransitionModForm(
  props: ModsPanelCompProps<ITransitionTimingMod>
) {
  const { change } = props;

  return (
    <Space style={{ width: "100%" }} direction={"vertical"}>
      <Typography.Text>
        Connect scenes, and set transition time at the start/end of this scene
        to overlap the animation
      </Typography.Text>
      <span>
        In frame:{" "}
        <InputNumber
          step={1}
          value={change.ip}
          onChange={(value) => props.onChange(props.index, "ip", value || 0)}
        />
      </span>
      <span>
        Out frame:{" "}
        <InputNumber
          step={1}
          value={change.op}
          onChange={(value) => props.onChange(props.index, "op", value || 0)}
        />
      </span>
    </Space>
  );
}
