import { flow, getRoot, Instance, types, cast, onPatch } from "mobx-state-tree";
import { generateClient, post } from "aws-amplify/api";
import { insertIntoGraphQlString } from "modifygraphqlstring";
import { Slide, toast } from "react-toastify";
import { SettingsApiEnv } from "@blings/blings-player/lib/src/fetchData/formatApiUrl";
import { updateProjectVersion } from "../graphql/mutations";
import { Image2WebpMicroserviceResponse } from "../types/microservices";
import { canRunWebP, isImage } from "../utils/image";
import { fetchAuthSession, signOut } from "aws-amplify/auth";
import { uploadData } from "@aws-amplify/storage";
import { UploadFile } from "antd";
import { savedDate2Relative } from "../components/playground-top-bar/PublishStatus";
import { DocumentType } from "@aws-amplify/core/internals/utils";
import { FLOW_DIAGRAM_NODE_TYPE } from "@blings/blings-player";
import {
  Mod,
  OnUpdateProjectSubSubscription,
  ProjectVersion,
  Experiment,
  ExperimentOptimizationTechnique,
  UpdateProjectVersionMutationVariables,
} from "../API";
import {
  emptySchema,
  jsonSchemaGetExamples,
} from "../helpers/jsonShema.helpers";
import {
  onUpdateProjectSub,
  onUpdateProjectVersionSub,
} from "../graphql/subscriptions";
import {
  createVersion,
  getLatestProjectVersion,
  getPublishedProject,
  getPublishedProjectBasicInfo,
  publishVersion,
} from "../utils/projectTools";
import confirmationModal from "../components/BlingsModal/CustomConfirm";
import { RootInstance, rootStore } from "./main";
import { CDN, getENV, MICROSERVICE_API_URLS } from "./consts";
import {
  AsyncOpState,
  asyncOpStateType,
  updateAsyncStatus,
} from "./async-op-state";
import { ILineupModel } from "./FlowStore";

const client = generateClient();
const FlowDiagramEdgeModel = types.model("FlowDiagramEdge", {
  source: types.string,
  target: types.string,
  sourceHandle: types.maybeNull(types.boolean),
});
const FlowDiagramNodeModel = types.model("FlowDiagramNode", {
  id: types.string,
  label: types.string,
  type: types.enumeration(
    "FlowDiagramNodeType",
    Object.values(FLOW_DIAGRAM_NODE_TYPE)
  ),
  data: types.maybeNull(types.string),
  lineupId: types.maybeNull(types.string),
});
export const FlowDiagramModel = types
  .model("FlowDiagram", {
    edges: types.array(FlowDiagramEdgeModel),
    nodes: types.array(FlowDiagramNodeModel),
    isDirty: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get lineups() {
      return self.nodes.reduce<Array<string>>((acc, node) => {
        if (node.lineupId) {
          acc.push(node.lineupId);
        }
        return acc;
      }, []);
    },
    get asFlows(): Array<ILineupModel> {
      const flowsGroups = {};
      const tempEdges = self.edges.map((edge) => ({ ...edge }));

      self.nodes.forEach((node) => {
        if (node.type === "scene" && node.lineupId) {
          const firstScene = {
            id: node.id,
            label: node.label,
            data: node.data,
          };
          flowsGroups[node.lineupId] = {
            id: node.id,
            lineupId: node.lineupId,
            scenes: [firstScene],
            isDefault: false,
          };

          let count = 0;
          const egdesLength = tempEdges.length;
          let sourceNodeId = node.id;

          while (
            tempEdges.length > 0 &&
            count < egdesLength &&
            sourceNodeId !== ""
          ) {
            const currentSourceNodeId = sourceNodeId;
            const edge = tempEdges.findIndex(
              (edge) => edge.source === currentSourceNodeId
            );
            if (edge > -1) {
              const nextNode = self.nodes.find(
                (node) => node.id === tempEdges[edge].target
              );
              if (nextNode && nextNode.id !== currentSourceNodeId) {
                sourceNodeId = nextNode.id;
                flowsGroups[node.lineupId].scenes.push({
                  id: nextNode.id,
                  label: nextNode.label,
                  lineupId: nextNode.lineupId,
                  data: nextNode.data,
                });
              } else {
                sourceNodeId = "";
              }
              tempEdges.splice(edge, 1);
            }
            count++;
          }
        }
      });
      return Object.values(flowsGroups);
    },
    get getFlows() {
      return self.nodes.reduce<Array<object>>((acc, node) => {
        if (node.lineupId && node.type === "lineup") {
          acc.push(node);
        }
        return acc;
      }, []);
    },
    get defaultLineup() {
      return this.lineups[0];
    },
    get hasUnsavedChanges() {
      return self.isDirty;
    },
    get flowDiagramDBFormat() {
      if (!self.nodes || !self.edges) return null;
      return {
        nodes: JSON.parse(JSON.stringify(self.nodes)),
        edges: JSON.parse(JSON.stringify(self.edges)),
      };
    },
  }))
  .actions((self) => ({
    isSceneInFlow(sceneId: string) {
      if (!self.nodes) return false;
      return self.nodes.some(
        (node) => node.type === "scene" && node.label === sceneId
      );
    },
    setNodesAndEdges(nodes: any[], edges: any[]) {
      self.nodes = cast(nodes);
      self.edges = cast(edges);
    },
    afterCreate() {
      onPatch(self, (patch) => {
        if (patch.path === "/nodes" || patch.path === "/edges") {
          console.log("Nodes or edges updated:", patch);
        }
      });
    },
    setIsDirty(value: boolean) {
      self.isDirty = value;
    },
  }));
export type ProjectBasicInfo = {
  title: string;
  id: string;
  updatedAt: string;
  createdAt: string;
  playerVersionToUse: string;
  publishedAt?: string;
  thumbS3Url: string;
  experimentOptimizationTechnique: ExperimentOptimizationTechnique;
  projectAccountId: string;
};

export const PlatformModel = types
  .model({
    initialProjectLoadSuccess: types.optional(types.boolean, false),
    selectedProjectId: types.maybeNull(types.string),
    selectedProjectBasicInfo: types.maybeNull(types.frozen<ProjectBasicInfo>()),
    selectedVideoPartName: types.maybeNull(types.string),
    allProjects: types.optional(
      types.array(types.frozen<ProjectBasicInfo>()),
      []
    ),
    projectWorkspaceVersion: types.maybeNull(types.frozen<ProjectVersion>()),
    projectWorkspaceFlowDiagram: types.maybeNull(FlowDiagramModel),
    uploadJsonAndImagesStatus: asyncOpStateType,
    saveModsStatus: asyncOpStateType,
    saveFlowStatus: asyncOpStateType,
    saveProjectSchemaStatus: asyncOpStateType,
    saveProjectLiveControlSchemaStatus: asyncOpStateType,
    uploadAssetStatus: asyncOpStateType,
    dynamicFormUploadAssetStatus: types.map(
      types.model({ id: types.identifier, status: asyncOpStateType })
    ),
    isLoadingProjectList: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get selectedVideoPart() {
      return self.projectWorkspaceVersion?.videoParts?.find(
        (vp) => vp.name === self.selectedVideoPartName
      );
    },
    get platformStorageProjectAssetsRoot() {
      return `projects/${self.selectedProjectId}/assets`;
    },
    get selectedProjectDataSchema() {
      if (self.projectWorkspaceVersion?.stateJsonSchemaStr)
        return JSON.parse(self.projectWorkspaceVersion?.stateJsonSchemaStr);
      return emptySchema;
    },
    get selectedProjectLiveControlSchema() {
      if (self.projectWorkspaceVersion?.settingsJsonSchemaStr)
        return JSON.parse(self.projectWorkspaceVersion?.settingsJsonSchemaStr);
      return emptySchema;
    },
    get selectedProjectLiveControl() {
      if (self.projectWorkspaceVersion?.settings)
        return JSON.parse(self.projectWorkspaceVersion?.settings);
      return {};
    },

    get selectedProjectHasLiveVersion(): boolean {
      try {
        const live =
          self.selectedProjectBasicInfo?.updatedAt &&
          (self.projectWorkspaceVersion?.updatedAt ||
            self.selectedProjectBasicInfo?.updatedAt ===
              self.selectedProjectBasicInfo?.createdAt);
        return !!live;
      } catch (e) {
        return false;
      }
    },
    get selectedProjectIsLatestVersionPublished(): boolean {
      const published =
        self.selectedProjectBasicInfo?.publishedAt ||
        self.selectedProjectBasicInfo?.updatedAt;
      const workspace = self.projectWorkspaceVersion?.updatedAt;
      if (!published || !workspace) return false;
      return !!published && published > workspace;
    },
    get publishedTime() {
      if (this.selectedProjectHasLiveVersion) {
        const editText = savedDate2Relative(
          self.selectedProjectBasicInfo?.updatedAt || ""
        );
        return `Published version.
        ${editText}`;
      }
      // else {
      //   if (projectWorkspaceVersion?.updatedAt) {
      //     const editText = savedDate2Relative(projectWorkspaceVersion.updatedAt);
      //     const savedDate = new Date(projectWorkspaceVersion?.updatedAt);
      //     return `Last edit was ${editText}`}
      //   }
      return null;
    },
    get videoScenes() {
      return self.projectWorkspaceVersion?.videoParts || [];
    },
  }))
  .views((self) => ({
    get inputNames() {
      const inputNames: string[] = [];
      if (
        self.projectWorkspaceVersion &&
        self.projectWorkspaceVersion.videoParts
      ) {
        const { modsStore } = getRoot<RootInstance>(self);
        const currentVideoPartName = self.selectedVideoPartName;
        const allOtherVideoParts = self.projectWorkspaceVersion.videoParts.filter(
          (vp) => vp.name !== currentVideoPartName
        );
        allOtherVideoParts
          .map((vp) => vp.modsArr)
          .forEach((ma) => {
            ma?.map((mod) => (mod?.dataStr ? JSON.parse(mod.dataStr) : []))
              .filter((mod) => mod.type === "interactiveInput")
              .forEach((mod) => {
                inputNames.push(mod.value);
              });
          });
        modsStore.mods.forEach((mod) => {
          mod.moddata.type === "interactiveInput" &&
            inputNames.push(mod.moddata.value);
        });
      }
      return inputNames;
    },
    /**
     * Verify if there are unsaved changes between the current state of the project and the last saved version
     */
    get hasUnsavedChanges() {
      //Check unsaved changes for connectors
      const {
        dynamicDataStore: { hasUnsavedChanges: hasUnsavedChangesSchema },
        modsStore: { hasUnsavedChanges: hasUnsavedChangesConnectors },
        experimentStore: { hasUnsavedChanges: hasUnsavedChangesExperiments },
      } = getRoot<any>(self);

      return (
        hasUnsavedChangesConnectors ||
        hasUnsavedChangesSchema ||
        hasUnsavedChangesExperiments
      );
    },

    get isControlDeprecated() {
      const keys = Object.keys(self.selectedProjectLiveControl || {});
      return keys.length === 0;
    },
  }))
  .actions((self) => ({
    // ...updateAsyncStatus(self, 'uploadJsonAndImagesStatus'),
    updateUploadJsonAndImagesStatus: updateAsyncStatus(
      self,
      "uploadJsonAndImagesStatus"
    ),
    updateUploadAssetStatus: updateAsyncStatus(self, "uploadAssetStatus"),
    updateSaveModsStatus: updateAsyncStatus(self, "saveModsStatus"),
    updateSaveFlowStatus: updateAsyncStatus(self, "saveFlowStatus"),
    updateSaveProjectSchemaStatus: updateAsyncStatus(
      self,
      "saveProjectSchemaStatus"
    ),
    updateSaveProjectLiveControlSchemaStatus: updateAsyncStatus(
      self,
      "saveProjectLiveControlSchemaStatus"
    ),
    getDynamicFormAssetsUploadStatus(id: string) {
      return self.dynamicFormUploadAssetStatus.get(id)?.status;
    },
    setLoadingProjectList(isLoading: boolean) {
      self.isLoadingProjectList = isLoading;
    },
    setProjectWorkspaceFlowDiagramModel(flowDiagram: IFlowDiagramModel) {
      self.projectWorkspaceFlowDiagram = flowDiagram;
    },
  }))
  .actions((self) => {
    let selectedProjectObservable: ZenObservable.Subscription;
    let projectVersionObservable: ZenObservable.Subscription;
    return {
      async logout() {
        await signOut();
        // await Auth.signOut();
        window.location.reload();
      },
      setProjects(projects: ProjectBasicInfo[]) {
        self.initialProjectLoadSuccess = true;
        self.allProjects.replace(projects);
      },
      setWorkspaceProject(project: ProjectVersion | null) {
        self.projectWorkspaceVersion = project;
        if (project?.flowDiagram) {
          if (self.projectWorkspaceFlowDiagram) {
            self.projectWorkspaceFlowDiagram.setNodesAndEdges(
              project.flowDiagram.nodes,
              project.flowDiagram.edges
            );
            self.projectWorkspaceFlowDiagram.setIsDirty(false);
          } else
            self.setProjectWorkspaceFlowDiagramModel(
              //@ts-ignore
              FlowDiagramModel.create(project.flowDiagram)
            );
        } else {
          self.setProjectWorkspaceFlowDiagramModel(
            //@ts-ignore
            FlowDiagramModel.create({
              edges: [],
              nodes: [],
              isDirty: false,
            })
          );
        }
      },
      setVideoParts(vdps) {
        if (self.projectWorkspaceVersion?.videoParts) {
          const reorderedProject = {
            ...self.projectWorkspaceVersion,
            videoParts: vdps,
          };
          self.projectWorkspaceVersion = reorderedProject;
        }
      },
      async loadProjects() {
        self.setLoadingProjectList(true);
        const listProjectBasicInfo = `query ListProjects(
          $filter: ModelProjectFilterInput
          $limit: Int
          $nextToken: String
        ) {
          listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
              id
              title
              updatedAt
              createdAt
              publishedAt
              projectAccountId
              thumbS3Url
            }
            nextToken
          }
        }`;

        let nextToken = null;
        const projects: ProjectBasicInfo[] = [];
        do {
          const projectsData = (await client.graphql({
            query: listProjectBasicInfo,
            variables: { nextToken },
          })) as { data: any };
          nextToken = projectsData?.data?.listProjects?.nextToken;
          if (projectsData?.data?.listProjects?.items) {
            projects.push(...projectsData.data.listProjects.items);
          }
        } while (nextToken);
        this.setProjects(projects.filter((p) => !!p.id));
        self.setLoadingProjectList(false);
      },
      async publishCurrentVersion() {
        if (self.projectWorkspaceVersion) {
          await publishVersion(self.projectWorkspaceVersion);
          await this.loadProjects();
        } else throw new Error("Workspace version is not defined");
      },
      updateSelectedVideoPart(name: string | null) {
        self.selectedVideoPartName = name;
      },
      setSelectedProjectBasicInfo(projectBasicInfo: ProjectBasicInfo) {
        if (!projectBasicInfo.experimentOptimizationTechnique)
          projectBasicInfo.experimentOptimizationTechnique =
            ExperimentOptimizationTechnique.DISABLED;
        self.selectedProjectBasicInfo = projectBasicInfo;
      },

      setExperiments(experiments: Array<Experiment>) {
        if (self.projectWorkspaceVersion)
          self.projectWorkspaceVersion.experiments = experiments;
      },
      async selectProject(pid: string, sceneName?: string) {
        self.selectedProjectBasicInfo = null as ProjectBasicInfo | null;
        this.setWorkspaceProject(null);
        self.selectedProjectId = pid || null;
        if (!pid) {
          return this.updateSelectedVideoPart(null);
        }
        let jsonVidUrl;
        let mods: Array<Mod> | null | undefined;

        let latestProjectVersion = await getLatestProjectVersion(pid);
        if (!latestProjectVersion) {
          // if there is no workspace version, create one out of the published project
          const publishedProject = await getPublishedProject(pid);
          latestProjectVersion = await createVersion(publishedProject);
        }
        // TODO subscribe to version and published
        this.setSelectedProjectBasicInfo(
          await getPublishedProjectBasicInfo(pid)
        );
        this.setWorkspaceProject(latestProjectVersion);

        if (
          self.projectWorkspaceVersion?.videoParts &&
          self.projectWorkspaceVersion.videoParts.length
        ) {
          let selectedVideoPart;
          if (
            sceneName &&
            self.projectWorkspaceVersion.videoParts.some(
              (vp) => vp.name === sceneName
            )
          ) {
            selectedVideoPart = self.projectWorkspaceVersion.videoParts.find(
              (vp) => vp.name === sceneName
            );
          } else {
            selectedVideoPart = self.projectWorkspaceVersion.videoParts[0];
          }
          this.updateSelectedVideoPart(selectedVideoPart.name);

          jsonVidUrl = selectedVideoPart.jsonUrl;
          mods = selectedVideoPart.modsArr;
        }
        console.log(
          "selectedProjectBasicInfo - experimentOptimizationTechnique",
          self.selectedProjectBasicInfo?.experimentOptimizationTechnique
        );
        console.log(
          "projectWorkspaceVersion - experimentOptimizationTechnique",
          self.projectWorkspaceVersion?.experimentOptimizationTechnique
        );
        if (
          self.projectWorkspaceVersion?.experimentOptimizationTechnique !==
          ExperimentOptimizationTechnique.DISABLED
        ) {
          getRoot<RootInstance>(self).experimentStore.replaceExperiments(
            self.projectWorkspaceVersion?.experiments || []
          );
          getRoot<RootInstance>(
            self
          ).experimentStore.setExperimentOptimizationTechnique(
            self.selectedProjectBasicInfo?.experimentOptimizationTechnique ||
              ExperimentOptimizationTechnique.DISABLED
          );
        }

        this.updateJsonAndMods(jsonVidUrl, mods);
        const { dynamicDataStore } = getRoot<RootInstance>(self);
        dynamicDataStore.setInitialPerVideoSchema(
          latestProjectVersion.stateJsonSchemaStr || "{}"
        );
        dynamicDataStore.setInitialLiveControSchemaAndData(
          latestProjectVersion.settingsJsonSchemaStr || "{}",
          latestProjectVersion.settings || "{}"
        );
        this.subscribeToProject(pid);
        this.subscribeToProjectDraft(pid);
        if (
          !(self.selectedProjectBasicInfo as ProjectBasicInfo | null)
            ?.thumbS3Url
        )
          this.generateThumbnail();
      },
      async subscribeToProject(pid: string) {
        selectedProjectObservable?.unsubscribe();

        const responseBasicInformation = await client.graphql({
          query: onUpdateProjectSub,
          variables: { id: pid },
        });
        selectedProjectObservable = responseBasicInformation.subscribe({
          next: (value: { data: OnUpdateProjectSubSubscription }) => {
            const { data } = value;
            const basicInfo = data.onUpdateProjectSub as ProjectBasicInfo;
            this.setSelectedProjectBasicInfo(basicInfo);
          },
          error: (errorValue) => {
            console.error(errorValue);
            selectedProjectObservable.unsubscribe();
            this.subscribeToProject(pid);
          },
        });
      },

      async subscribeToProjectDraft(pid: string) {
        projectVersionObservable?.unsubscribe();

        const modifiedSub = insertIntoGraphQlString(
          insertIntoGraphQlString(
            insertIntoGraphQlString(
              insertIntoGraphQlString(
                insertIntoGraphQlString(onUpdateProjectVersionSub, {
                  path: ["videoParts"],
                  key: "modsArr",
                  value: {
                    id: true,
                    dataStr: true,
                    origin: true,
                    name: true,
                  },
                }),
                {
                  path: ["videoParts"],
                  key: "fonts",
                  value: {
                    family: true,
                    style: true,
                    url: true,
                    weight: true,
                  },
                }
              ),
              {
                path: ["experiments"],
                key: "variants",
                value: {
                  id: true,
                  value: true,
                  successFactors: {
                    conversionSuccessData: true,
                    engagementSuccessData: true,
                    selectionCount: true,
                    watchTimeSuccessData: true,
                  },
                },
              }
            ),
            {
              path: ["flowDiagram"],
              key: "nodes",
              value: {
                id: true,
                label: true,
                type: true,
                data: true,
                lineupId: true,
              },
            }
          ),
          {
            path: ["flowDiagram"],
            key: "edges",
            value: {
              source: true,
              target: true,
              sourceHandle: true,
            },
          }
        ).replaceAll("__typename", "");

        const responseWorkspaceProject = (await client.graphql({
          query: modifiedSub,
          variables: { id: pid },
        })) as any;
        projectVersionObservable = responseWorkspaceProject.subscribe({
          next: (projectDraftUpdate) => {
            const { data } = projectDraftUpdate;
            const partialProjectInDb = data.onUpdateProjectVersionSub as ProjectVersion;
            // update the workspace project with the modified fields in the db
            const workspaceVersion = JSON.parse(
              JSON.stringify(self.projectWorkspaceVersion)
            );
            if (!workspaceVersion) return;
            if (partialProjectInDb.videoParts) {
              workspaceVersion.videoParts = partialProjectInDb.videoParts;
            }
            if (partialProjectInDb.stateJsonSchemaStr) {
              workspaceVersion.stateJsonSchemaStr =
                partialProjectInDb.stateJsonSchemaStr;
            }
            if (partialProjectInDb.settingsJsonSchemaStr) {
              workspaceVersion.settingsJsonSchemaStr =
                partialProjectInDb.settingsJsonSchemaStr;
            }
            if (partialProjectInDb.settings) {
              workspaceVersion.settings = partialProjectInDb.settings;
            }
            if (partialProjectInDb.experiments) {
              workspaceVersion.experiments = partialProjectInDb.experiments;
            }
            if (partialProjectInDb.flowDiagram) {
              workspaceVersion.flowDiagram = partialProjectInDb.flowDiagram;
            }
            workspaceVersion.updatedAt = partialProjectInDb.updatedAt;

            this.setWorkspaceProject(workspaceVersion);
            const selectedVideoPart = partialProjectInDb.videoParts?.find(
              (videoPart) => videoPart.name === self.selectedVideoPartName
            );
            if (selectedVideoPart) {
              this.updateJson(selectedVideoPart.jsonUrl);
              getRoot<RootInstance>(
                self
              ).modsStore.mergeWithModsFromSubscription(
                selectedVideoPart.modsArr
              );
            }
            const { dynamicDataStore } = getRoot<RootInstance>(self);
            if (partialProjectInDb.settingsJsonSchemaStr) {
              dynamicDataStore.setInitialLiveControSchemaAndData(
                partialProjectInDb.settingsJsonSchemaStr || "{}",
                partialProjectInDb.settings || "{}",
                false
              );
            }
            if (partialProjectInDb.stateJsonSchemaStr) {
              // dont change the initials values if you already have.
              if (!dynamicDataStore.initialPerVideoData) {
                dynamicDataStore.setInitialPerVideoData(
                  jsonSchemaGetExamples(self.selectedProjectDataSchema)
                );
              }
              dynamicDataStore.setInitialPerVideoSchema(
                partialProjectInDb?.stateJsonSchemaStr || "",
                false
              );
            }
            if (partialProjectInDb.experiments) {
              getRoot<RootInstance>(
                self
              ).experimentStore.mergeWithExperimentsFromSubscription(
                partialProjectInDb.experiments
              );
            }
          },
          error: (errorValue) => {
            console.error("ERROR ON PROJECT DRAFT SUBSCRIPTION:", errorValue);
            selectedProjectObservable.unsubscribe();
            this.subscribeToProjectDraft(pid);
          },
        });
      },
      updateJsonAndMods(jsonVidUrl: string, mods?: Array<Mod> | null): void {
        this.updateJson(jsonVidUrl);
        getRoot<RootInstance>(self).modsStore.replaceMods(mods);
      },
      updateJson(jsonVidUrl: string): void {
        const playerStore = getRoot<RootInstance>(self).playerStore;
        if (jsonVidUrl) {
          playerStore.downloadJsonFile(jsonVidUrl);
        } else {
          playerStore.removePlayer();
        }
      },
      changeVideoPart(selectedVideoPartName) {
        if (
          self.projectWorkspaceVersion &&
          self.projectWorkspaceVersion.videoParts
        ) {
          getRoot<RootInstance>(self).modsStore.setActiveMod(); // Reset active mod
          self.selectedVideoPartName = selectedVideoPartName;
          const selectedVideoPart = self.projectWorkspaceVersion.videoParts.find(
            (vp) => vp.name === selectedVideoPartName
          );
          if (selectedVideoPart) {
            this.updateJsonAndMods(
              selectedVideoPart.jsonUrl,
              selectedVideoPart.modsArr || null
            );
          }
        }
      },

      async deleteVideoPart(videoPartName) {
        if (self.projectWorkspaceFlowDiagram?.isSceneInFlow(videoPartName)) {
          return await confirmationModal.confirm(
            "Scene in use. To delete this scene, first go to the Lineup Builder and remove it from the Flow",
            "Ok"
          );
        }
        const vps = self.projectWorkspaceVersion?.videoParts?.filter(
          (vp) => vp.name !== videoPartName
        );
        if (vps) {
          const modifiedSub = insertIntoGraphQlString(
            insertIntoGraphQlString(updateProjectVersion, {
              path: ["videoParts"],
              key: "modsArr",
              value: {
                id: true,
                dataStr: true,
                origin: true,
                name: true,
              },
            }),
            {
              path: ["videoParts"],
              key: "fonts",
              value: {
                family: true,
                style: true,
                url: true,
                weight: true,
              },
            }
          );
          await client.graphql({
            query: modifiedSub,
            variables: {
              input: { id: self.selectedProjectId, videoParts: vps },
            },
          });
          const projectWorkspaceVersion = JSON.parse(
            JSON.stringify(self.projectWorkspaceVersion)
          );
          projectWorkspaceVersion.videoParts = vps;
          this.setWorkspaceProject(projectWorkspaceVersion);
        }
      },

      async renameVideoPart(oldName, newName) {
        const vps = self.projectWorkspaceVersion?.videoParts;
        if (vps) {
          const vp = vps.find((vp) => vp.name === oldName);
          if (vp) {
            vp.name = newName;
            const modifiedSub = insertIntoGraphQlString(
              insertIntoGraphQlString(updateProjectVersion, {
                path: ["videoParts"],
                key: "modsArr",
                value: {
                  id: true,
                  dataStr: true,
                  origin: true,
                  name: true,
                },
              }),
              {
                path: ["videoParts"],
                key: "fonts",
                value: {
                  family: true,
                  style: true,
                  url: true,
                  weight: true,
                },
              }
            );
            await client.graphql({
              query: modifiedSub,
              variables: {
                input: { id: self.selectedProjectId, videoParts: vps },
              },
            });
            const projectWorkspaceVersion = JSON.parse(
              JSON.stringify(self.projectWorkspaceVersion)
            );
            projectWorkspaceVersion.videoParts = vps;
            this.setWorkspaceProject(projectWorkspaceVersion);
            // Change selected video part name if it was renamed
            if (self.selectedVideoPartName === oldName) {
              this.changeVideoPart(newName);
              window.history.pushState(
                {},
                "",
                `/${self.selectedProjectId}/${newName}`
              );
            }
          }
        }
      },

      async duplicateScene(sceneName: string) {
        const vps = JSON.parse(
          JSON.stringify(self.projectWorkspaceVersion?.videoParts)
        );
        if (vps) {
          const vp = vps.find((vp) => vp.name === sceneName);
          if (vp) {
            const duplicatedVp = {
              ...vp,
              modsArr: vp.modsArr.map((mod) => ({
                ...mod,
                id: Math.floor(Math.random() * 100000),
              })),
            };
            const newScene = JSON.parse(JSON.stringify(duplicatedVp));
            newScene.name = `${sceneName} copy`;
            vps.push(newScene);
            const modifiedSub = insertIntoGraphQlString(
              insertIntoGraphQlString(updateProjectVersion, {
                path: ["videoParts"],
                key: "modsArr",
                value: {
                  id: true,
                  dataStr: true,
                  origin: true,
                  name: true,
                },
              }),
              {
                path: ["videoParts"],
                key: "fonts",
                value: {
                  family: true,
                  style: true,
                  url: true,
                  weight: true,
                },
              }
            );
            await client.graphql({
              query: modifiedSub,
              variables: {
                input: { id: self.selectedProjectId, videoParts: vps },
              },
            });
            const projectWorkspaceVersion = JSON.parse(
              JSON.stringify(self.projectWorkspaceVersion)
            );
            projectWorkspaceVersion.videoParts = vps;
            this.setWorkspaceProject(projectWorkspaceVersion);
          }
        }
      },

      async savePerVideoSchema(schema: any) {
        if (!self.projectWorkspaceVersion) {
          return;
        }
        /* const schema = createSchemaFromData(data); */
        self.updateSaveProjectSchemaStatus(AsyncOpState.Saving);
        try {
          const modifiedUpdate = `
          mutation UpdateProjectVersion(
            $input: UpdateProjectVersionInput!
          ) {
            updateProjectVersion(input: $input) {
              id
              stateJsonSchemaStr
              accountOwner
              updatedAt
              createdAt
            }
          }
        `;
          await client.graphql({
            query: modifiedUpdate,
            variables: {
              input: {
                id: self.selectedProjectId,
                stateJsonSchemaStr: JSON.stringify(schema),
              },
            },
          });
          const projectWorkspaceVersion = JSON.parse(
            JSON.stringify(self.projectWorkspaceVersion)
          );

          projectWorkspaceVersion.stateJsonSchemaStr = JSON.stringify(schema);
          this.setWorkspaceProject(projectWorkspaceVersion);
          const { dynamicDataStore } = getRoot<RootInstance>(self);
          dynamicDataStore.setInitialPerVideoSchema(
            projectWorkspaceVersion.stateJsonSchemaStr
          );

          self.updateSaveProjectSchemaStatus(AsyncOpState.Success);
        } catch (e) {
          self.updateSaveProjectSchemaStatus(AsyncOpState.Error);
          console.error("err", e);
        }

        setTimeout(() => {
          self.updateSaveProjectSchemaStatus(AsyncOpState.Changed);
        }, 2000);
      },
      async saveLiveControlSchema(schema: any, data: any) {
        if (!self.projectWorkspaceVersion) {
          return;
        }
        /* const schema = createSchemaFromData(data); */
        self.updateSaveProjectLiveControlSchemaStatus(AsyncOpState.Saving);
        try {
          const modifiedUpdate = `
          mutation UpdateProjectVersion(
            $input: UpdateProjectVersionInput!
          ) {
            updateProjectVersion(input: $input) {
              id
              accountOwner
              settingsJsonSchemaStr
              settings
              createdAt
              updatedAt
            }
          }
        `;
          await client.graphql({
            query: modifiedUpdate,
            variables: {
              input: {
                id: self.selectedProjectId,
                settingsJsonSchemaStr: JSON.stringify(schema),
                settings: JSON.stringify(data),
              },
            },
          });
          const projectWorkspaceVersion = JSON.parse(
            JSON.stringify(self.projectWorkspaceVersion)
          );
          projectWorkspaceVersion.settings = JSON.stringify(data);
          projectWorkspaceVersion.settingsJsonSchemaStr = JSON.stringify(
            schema
          );
          this.setWorkspaceProject(projectWorkspaceVersion);
          const { dynamicDataStore } = getRoot<RootInstance>(self);
          dynamicDataStore.setInitialLiveControSchemaAndData(
            self.projectWorkspaceVersion.settingsJsonSchemaStr || "{}",
            self.projectWorkspaceVersion.settings || "{}"
          );
          self.updateSaveProjectLiveControlSchemaStatus(AsyncOpState.Success);
        } catch (e) {
          self.updateSaveProjectLiveControlSchemaStatus(AsyncOpState.Error);
          console.error("err", e);
        }

        setTimeout(() => {
          self.updateSaveProjectLiveControlSchemaStatus(AsyncOpState.Changed);
        }, 2000);
      },
      async updateSaveFlowDiagram() {
        try {
          self.updateSaveFlowStatus(AsyncOpState.Saving);
          const modifiedMutation = `
          mutation UpdateProjectVersion(
            $input: UpdateProjectVersionInput!
          ) {
            updateProjectVersion(input: $input) {
              id
              flowDiagram {
                nodes {
                  id
                  label
                  type
                  data
                  lineupId
                }
                edges {
                  source
                  target
                  sourceHandle
                }
              }
              accountOwner
              updatedAt
              createdAt
            }
          }
        `;

          const variables: UpdateProjectVersionMutationVariables = {
            input: {
              id: self.selectedProjectId || "",
              flowDiagram:
                self.projectWorkspaceFlowDiagram?.flowDiagramDBFormat,
            },
          };
          try {
            await client.graphql({
              query: modifiedMutation,
              variables,
            });
          } catch (e) {
            console.log("error", e);
          }
          const newProjectVersion = JSON.parse(
            JSON.stringify(self.projectWorkspaceVersion)
          );
          newProjectVersion.flowDiagram =
            self.projectWorkspaceFlowDiagram?.flowDiagramDBFormat;
          this.setWorkspaceProject(newProjectVersion);
        } catch (e) {
          self.updateSaveFlowStatus(AsyncOpState.Error);
          console.error("err", e);
        }
        self.updateSaveFlowStatus(AsyncOpState.Success);
        setTimeout(() => {
          self.updateSaveFlowStatus(AsyncOpState.Changed);
        }, 2000);
      },

      async updateVideoPartMods() {
        if (
          !self.selectedVideoPartName ||
          !self.projectWorkspaceVersion?.videoParts
        ) {
          return;
        }

        const vp = self.projectWorkspaceVersion.videoParts.find(
          (vp) => vp.name === self.selectedVideoPartName
        );

        if (vp) {
          self.updateSaveModsStatus(AsyncOpState.Saving);
          const modsStore = getRoot<RootInstance>(self).modsStore;
          vp.modsArr = modsStore.JsonConfig as Mod[];
          try {
            const modifiedMutation = `
          mutation UpdateProjectVersion(
            $input: UpdateProjectVersionInput!
          ) {
            updateProjectVersion(input: $input) {
              id
              videoParts {
                name
                jsonUrl
                origin
                playerVersionToUse
                modsArr {
                  id
                  dataStr
                  origin
                  name
                }
                updatedAt
                hasVideo
                fonts {
                  family
                  style
                  url
                  weight
                }
              }
                experiments {
                  id
                  type
                  variants {
                    id
                    value
                    successFactors {
                      conversionSuccessData
                      engagementSuccessData
                      selectionCount
                      watchTimeSuccessData
                    }
                  }
                }
              accountOwner
              updatedAt
              createdAt
            }
          }
        `;

            const variables: UpdateProjectVersionMutationVariables = {
              input: {
                id: self.selectedProjectId || "",
                videoParts: self.projectWorkspaceVersion.videoParts.map(
                  (vp) => {
                    const { __typename, ...rest } = vp;
                    return rest;
                  }
                ),
                //@ts-ignore
                experiments:
                  getRoot<RootInstance>(self).experimentStore
                    .changedExperiments || null,
              },
            };
            await client.graphql({
              query: modifiedMutation,
              variables,
            });
            modsStore.setUnchangedMods(modsStore.JsonConfig as Mod[]);
            getRoot<RootInstance>(self).experimentStore.changedToUnchanged();
            const newProjectVersion = JSON.parse(
              JSON.stringify(self.projectWorkspaceVersion)
            );
            newProjectVersion.experiments = getRoot<RootInstance>(
              self
            ).experimentStore.unchangedExperiments;
            this.setWorkspaceProject(newProjectVersion);
            self.updateSaveModsStatus(AsyncOpState.Success);
          } catch (e) {
            self.updateSaveModsStatus(AsyncOpState.Error);
            console.error("err", e);
            toast.info("Changes were not saved!", {
              transition: Slide,
            });
          }

          setTimeout(() => {
            self.updateSaveModsStatus(AsyncOpState.Changed);
          }, 2000);
        }
      },
      getFullUrlOfAsset(assetOriginalName, folder) {
        return (
          self.platformStorageProjectAssetsRoot +
          `/${folder}/${Date.now() + "_" + assetOriginalName}`
        );
      },
      getOriginalNameOfAsset(fullName: string) {
        if (fullName.includes(self.platformStorageProjectAssetsRoot)) {
          const fileName = fullName.split("/").pop(); // remove entire folder structure
          if (fileName) {
            const assetName = fileName.split("_").slice(1).join("_");
            if (assetName) return assetName;
          }
        }
        return fullName;
      },

      async uploadAssetToProject(
        file: File | UploadFile<any>,
        s3Folder: string,
        cb?: (key: string) => void,
        progressCallback?: any
      ) {
        file = file as File;
        self.updateUploadAssetStatus(AsyncOpState.Saving);
        try {
          const _isImage = isImage(file);
          const fullAssetName = this.getFullUrlOfAsset(file.name, s3Folder);
          const res = await this.uploadFileToS3(
            fullAssetName,
            file,
            file.type,
            progressCallback,
            _isImage
          );
          if (isImage(file, ["gif", "webp"])) {
            const res = await this.convertImageToWebP(
              fullAssetName,
              cb,
              progressCallback
            );
            await new Promise((resolve) => setTimeout(resolve, 1000));
            if (cb) {
              if (await canRunWebP()) cb(res.cloudfront.webp);
              else cb(res.cloudfront.original);
            }
          } else {
            if (cb) {
              cb(`${CDN[getENV()]}/${res.key}`);
            }
          }
          self.updateUploadAssetStatus(AsyncOpState.Success);
        } catch (e) {
          await progressCallback({
            loaded: -1,
            total: 100,
            errorMessage:
              "Error when uploading.\nPlease check the uploaded file",
          });

          self.updateUploadAssetStatus(AsyncOpState.Error);
          console.error("err", e);
        }

        setTimeout(() => {
          self.updateUploadAssetStatus(AsyncOpState.Changed);
        }, 2000);
      },

      uploadFileToS3(
        Key: string,
        file: string | File,
        type?: string,
        pcb?: any,
        halveProgress?: boolean
      ) {
        return uploadData({
          key: Key,
          data: file,
          options: {
            accessLevel: "guest", // public
            contentType: type || "application/json",
            onProgress(event) {
              if (pcb) {
                pcb({
                  loaded: event.transferredBytes,
                  total: halveProgress
                    ? (event.totalBytes || 1) * 2
                    : event.totalBytes,
                });
              }
            },
          },
        }).result as Promise<{ key: string }>;
      },

      async convertImageToWebP(
        path: string,
        cb?: (key: string) => void,
        progressCallback?: any
      ): Promise<Image2WebpMicroserviceResponse> {
        const apiURL = "https://cors.blings.io/" + MICROSERVICE_API_URLS.image;
        //const apiURL = "http://localhost:3000/dev/v1/private/convert/image"
        if (progressCallback)
          await progressCallback({ loaded: 60, total: 100 });

        const body = {
          key: path,
        };

        if (progressCallback)
          await progressCallback({ loaded: 75, total: 100 });
        const authSession = await fetchAuthSession();
        const authJWTToken = authSession.tokens?.accessToken.toString() as string;
        const res = await fetch(apiURL, {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            Authorization: authJWTToken,
          },
        });
        const data = (await res.json()) as Image2WebpMicroserviceResponse;

        if (progressCallback)
          await progressCallback({ loaded: 100, total: 100 });

        return data;
      },
      async generateThumbnail() {
        const playerParams = {
          project: {
            id: self.selectedProjectBasicInfo?.id,
            env: getENV() as SettingsApiEnv,
          },
          data: rootStore.dynamicDataStore.perVideoCurrentData,
          settings: {
            posterFrame: 30,
          },
          scenes: [self.selectedVideoPartName],
        };
        await post({
          apiName: "BlingsAPI",
          path: "/v1/private/utils/generateThumbnail",
          options: {
            body: playerParams as DocumentType,
          },
        });
      },
    };
  })
  .actions((self) => ({
    selectProject: flow(function* selectProject(
      pid: string,
      sceneName?: string
    ) {
      self.selectedProjectBasicInfo = null;
      self.setWorkspaceProject(null);
      self.selectedProjectId = pid || null;
      if (!pid) {
        return self.updateSelectedVideoPart(null);
      }
      let jsonVidUrl;
      let mods: Array<Mod> | null | undefined;

      let latestProjectVersion = (yield getLatestProjectVersion(
        pid
      )) as ProjectVersion;
      if (!latestProjectVersion) {
        // if there is no workspace version, create one out of the published project
        const publishedProject = yield getPublishedProject(pid);
        latestProjectVersion = yield createVersion(publishedProject);
      }
      // TODO subscribe to version and published
      self.setSelectedProjectBasicInfo(yield getPublishedProjectBasicInfo(pid));

      if (self.selectedProjectId === pid) {
        self.setWorkspaceProject(latestProjectVersion);

        if (
          self.projectWorkspaceVersion?.videoParts &&
          self.projectWorkspaceVersion.videoParts.length
        ) {
          let selectedVideoPart;
          if (
            sceneName &&
            self.projectWorkspaceVersion.videoParts.some(
              (vp) => vp.name === sceneName
            )
          ) {
            selectedVideoPart = self.projectWorkspaceVersion.videoParts.find(
              (vp) => vp.name === sceneName
            );
          } else {
            selectedVideoPart = self.projectWorkspaceVersion.videoParts[0];
          }
          self.updateSelectedVideoPart(selectedVideoPart.name);

          jsonVidUrl = selectedVideoPart.jsonUrl;
          mods = selectedVideoPart.modsArr;
        }
      }

      if (
        self.projectWorkspaceVersion?.experimentOptimizationTechnique !==
        ExperimentOptimizationTechnique.DISABLED
      ) {
        getRoot<RootInstance>(self).experimentStore.replaceExperiments(
          self.projectWorkspaceVersion?.experiments || []
        );
        getRoot<RootInstance>(
          self
        ).experimentStore.setExperimentOptimizationTechnique(
          (self.selectedProjectBasicInfo as ProjectBasicInfo | null)
            ?.experimentOptimizationTechnique ||
            ExperimentOptimizationTechnique.DISABLED
        );
      }

      self.updateJsonAndMods(jsonVidUrl, mods);
      const { dynamicDataStore } = getRoot<RootInstance>(self);
      dynamicDataStore.setInitialPerVideoSchema(
        latestProjectVersion.stateJsonSchemaStr || "{}"
      );
      dynamicDataStore.setInitialLiveControSchemaAndData(
        latestProjectVersion.settingsJsonSchemaStr || "{}",
        latestProjectVersion.settings || "{}"
      );
      self.subscribeToProject(pid);
      self.subscribeToProjectDraft(pid);

      if (
        !(self.selectedProjectBasicInfo as ProjectBasicInfo | null)?.thumbS3Url
      )
        self.generateThumbnail();
    }),
  }));

export type IPlatformModel = Instance<typeof PlatformModel>;
export type IFlowDiagramModel = Instance<typeof FlowDiagramModel>;

export type IFlowDiagramNodeModel = Instance<typeof FlowDiagramNodeModel>;
export type IFlowDiagramEdgeModel = Instance<typeof FlowDiagramEdgeModel>;
