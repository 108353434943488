import * as React from "react";
import { Tooltip } from "antd";
import "./InfoTooltip.scss";
import { theme } from "../theme";
import { AbstractTooltipProps } from "antd/lib/tooltip";
import { CSSProperties } from "react";
import { QuestionMark } from "../icons-and-animations/Icons";

interface Props extends AbstractTooltipProps {
  info: string;
  style?: CSSProperties;
  tooltipStyle?: CSSProperties;
  outline?: boolean;
}

export function InfoTooltip({
  info,
  style,
  tooltipStyle,
  outline,
  ...rest
}: Props) {
  return (
    <div className={"InfoTooltip"} style={{ cursor: "help", ...style }}>
      <Tooltip
        title={info}
        style={tooltipStyle}
        placement={"right"}
        arrow={{ pointAtCenter: true }}
        color={theme.blings_purple + "f7"}
        {...rest}
      >
        <div>
          <QuestionMark />
        </div>
      </Tooltip>
    </div>
  );
}
