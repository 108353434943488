import { useEffect, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import confirmationModal from "../components/BlingsModal/CustomConfirm";
import { MSTContext } from "../stores/main";

export const useNavigationPrompt = () => {
  const history = useHistory();

  const { hasUnsavedChanges } = useContext(MSTContext).modsStore;

  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!hasUnsavedChanges) {
        // console.log("handleBeforeUnload unsaved changes.");
        event.preventDefault();
        event.returnValue = "dirtyStateStore.message";
      }
    },
    [hasUnsavedChanges]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  const navigateWithPrompt = useCallback(
    async (to: string, action?: () => void) => {
      let confirmed = true;
      if (hasUnsavedChanges) {
        confirmed = await confirmationModal.confirm(
          "You have pending changes in your connectors. Leaving this page will discard these changes"
        );
      }
      if (confirmed) {
        history.push(to);
        if (action) {
          action();
        }
      }
    },
    [history, hasUnsavedChanges]
  );

  return navigateWithPrompt;
};
