import { getRoot, Instance, types } from "mobx-state-tree";
import { RootInstance } from "./main";
import { JSONSchema7 } from "json-schema";
import { jsonSchemaGetExamples } from "../helpers/jsonShema.helpers";
import { isEqual } from "lodash";
export const DynamicDataModel = types
  .model({
    liveControlCurrentSchema: types.maybeNull(types.string),
    perVideoCurrentSchema: types.maybeNull(types.string),
    perVideoCurrentData: types.frozen(),
    liveControlCurrentData: types.frozen(),
    liveControlInitialSchema: types.maybeNull(types.string),
    perVideoInitialSchema: types.maybeNull(types.string),
    initialPerVideoData: types.frozen(),
    initialLiveControlData: types.frozen(),
  })
  .views((self) => ({
    get hasUnsavedChanges() {
      return (
        self.liveControlInitialSchema !== self.liveControlCurrentSchema ||
        !isEqual(self.initialLiveControlData, self.liveControlCurrentData) ||
        self.perVideoInitialSchema !== self.perVideoCurrentSchema
      );
    },
    get hasUnsavedChangesPerVideo() {
      return (
        self.perVideoInitialSchema !== self.perVideoCurrentSchema ||
        !isEqual(self.initialPerVideoData, self.perVideoCurrentData)
      );
    },
    get hasUnsavedChangesLiveControl() {
      return (
        self.liveControlInitialSchema !== self.liveControlCurrentSchema ||
        !isEqual(self.initialLiveControlData, self.liveControlCurrentData)
      );
    },
  }))
  .actions((self) => ({
    setInitialPerVideoSchema(schema: string, force = true) {
      if (force === false && self.hasUnsavedChangesPerVideo) return;
      self.perVideoInitialSchema = schema;
      const data = jsonSchemaGetExamples(
        JSON.parse(self.perVideoInitialSchema)
      );
      self.perVideoCurrentSchema = schema;
      this.setInitialPerVideoData(data);
    },
    setInitialLiveControSchemaAndData(
      schema: string,
      liveControlData: string,
      force = true
    ) {
      if (force === false && self.hasUnsavedChangesLiveControl) return;
      let treatedSchema = JSON.parse(schema);
      if (
        treatedSchema.properties === undefined ||
        Object.keys(treatedSchema.properties).length === 0
      )
        treatedSchema = {
          $schema: "http://json-schema.org/draft-07/schema",
          $id: "http://example.com/example.json",
          type: "object",
          title: "",
          description: "",
          default: {},
          examples: [],
          required: [],
          properties: {},
          additionalProperties: true,
        };
      treatedSchema = JSON.stringify(treatedSchema);
      self.liveControlInitialSchema = treatedSchema;
      self.liveControlCurrentSchema = treatedSchema;
      this.setInitialLiveControlData(JSON.parse(liveControlData));
    },

    setInitialPerVideoData(data) {
      self.initialPerVideoData = data;
      this.setCurrentPerVideoData(data);
    },
    setInitialLiveControlData(data) {
      self.initialLiveControlData = data;
      this.setCurrentLiveControlData(data);
    },

    setCurrentPerVideoSchema(schema: JSONSchema7) {
      self.perVideoCurrentSchema = JSON.stringify(schema);
      getRoot<RootInstance>(self).modsStore.checkAllModsForDataErrors();
    },
    setCurrentLiveControlSchema(schema: JSONSchema7) {
      self.liveControlCurrentSchema = JSON.stringify(schema);
      getRoot<RootInstance>(self).modsStore.checkAllModsForDataErrors();
    },

    setCurrentPerVideoData(data) {
      self.perVideoCurrentData = data;
      getRoot<RootInstance>(self).playerStore.runPlayer();
      getRoot<RootInstance>(self).modsStore.checkAllModsForDataErrors();
    },
    setCurrentLiveControlData(data) {
      self.liveControlCurrentData = data;
      getRoot<RootInstance>(self).playerStore.runPlayer();
      getRoot<RootInstance>(self).modsStore.checkAllModsForDataErrors();
    },
  }));

export type IDynamicDataModel = Instance<typeof DynamicDataModel>;
