import React from "react";
import "./HorizontalDividerName.scss";
import { BinIcon } from "../icons-and-animations/Icons";

export const DividerWithText = ({
  text,
  onIconClick,
}: {
  text: String;
  onIconClick?: () => void;
}) => {
  return (
    <div className="HorizontalDivider">
      <div className="line left"></div>
      <span className="text">{text}</span>
      <div className="line right"></div>
      {onIconClick && (
        <div className="bin-icon" onClick={onIconClick}>
          <BinIcon />
        </div>
      )}
    </div>
  );
};
