import { Col, Input, Row, Select, Space, Switch } from "antd";
import {
  COMMON_PATHS,
  findLayersOrLayerAssets,
  getPath,
  IInteractiveModForm,
} from "@blings/blings-player";
import { ModsPanelCompProps } from "../ModsPanel";
import { useMst } from "../../../stores/main";
import { JsEditor } from "../../Editor/JsEditor";
import { LayerSelector } from "../../LayerSelector";
import MainCTAButton from "../../MainCTA/MainCTAButton";
import { ExperimentOptimizationTechnique } from "../../../API";
import { InfoTooltip } from "../../../BaseComps/InfoTooltip";
import { useEffect } from "react";

export const DEFAULT_FUNCTION_FOR_EXPRESSION = (
  isControlDeprecated: boolean
) => `(inputs, sessionId, userId, data${
  !isControlDeprecated ? ", liveControlData" : ""
}) => {
  fetch("ENDPOINT", {
    method: "POST",
    body: JSON.stringify({
      sessionId,
      userId,
      data: inputs
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
}`;
export function InputFormForm(props: ModsPanelCompProps<IInteractiveModForm>) {
  const {
    change: { inputKeys },
  } = props;
  const { change } = props;
  const {
    platformStore: { inputNames, isControlDeprecated },
    experimentStore: { selectedExperimentOptimizationTechnique },
  } = useMst();
  const hasVariantsEnabled =
    selectedExperimentOptimizationTechnique &&
    selectedExperimentOptimizationTechnique !==
      ExperimentOptimizationTechnique.DISABLED;

  const inputNameOption = inputNames
    .filter((name, index, self) => self.indexOf(name) === index)
    .map((inputName) => (
      <Select.Option key={inputName}>{inputName}</Select.Option>
    ));

  useEffect(() => {
    if (!change.ctaName) {
      const layers = findLayersOrLayerAssets({
        assetId: change.assetId,
        layerName: change.layerName,
        layerUid: change.layerUid,
        jsonVid: props.json,
        additionals: change.additionals,
      });
      const layer =
        layers?.length &&
        layers.find((layer) => getPath(layer, COMMON_PATHS.TEXT_IN_LAYER));
      if (layer) {
        const text = getPath(layer, COMMON_PATHS.TEXT_IN_LAYER);
        text && props.onChange(props.index, "ctaName", text);
      }
    }
  }, [props.change.layerName, props.change.additionals?.length]);

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <Row>
        <Col style={{ marginBottom: "8px", width: "100%" }}>
          <span>Submit button Layers:</span>
          <LayerSelector
            isMulti={true}
            assetLayerPairs={props.assetLayerPairs}
            selectedAssetId={change.assetId}
            selectedLayerName={change.layerName}
            selectedLayerUid={change.layerUid}
            onChange={props.onLayerChange}
            selectedAdditionals={change.additionals}
            index={props.index}
            jsonVid={props.json}
          />
        </Col>
        <Col style={{ width: "100%" }}>
          <span>Name this button (Required for analytics)</span>
          <InfoTooltip
            style={{ marginLeft: "5px" }}
            info="This name is used to track how many viewers clicked on the button."
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "25px",
              width: "100%",
            }}
          >
            <Input
              size={"large"}
              id="connector-cta-name"
              style={{ width: "100% !important" }}
              value={props.change.ctaName}
              addonBefore={"Name"}
              // eslint-disable-next-line no-template-curly-in-string
              placeholder="Use a clear & unique name"
              onChange={(e) =>
                props.onChange(props.index, ["ctaName"], [e.target.value])
              }
              allowClear={true}
            />
          </div>
        </Col>
      </Row>

      <Col>
        <span>Add input fields to be saved on click:</span>
        <InfoTooltip
          style={{ marginLeft: "5px" }}
          info="Select the fields to be saved to the database."
        />
        <Select
          placeholder="Choose inputs"
          mode="multiple"
          value={inputKeys}
          size="large"
          onChange={(e) => {
            props.onChange(props.index, "inputKeys", e);
          }}
          allowClear
          style={{ width: "100%" }}
        >
          {inputNameOption}
        </Select>
      </Col>

      <Row align="middle">
        <span>Send to Blings</span>
        <Switch
          checked={change.sendToThirdParty}
          onChange={(e) => props.onChange(props.index, "sendToThirdParty", e)}
        />
        <span>Customize your own</span>
      </Row>
      {change.sendToThirdParty ? (
        <JsEditor
          defaultValue={
            change.functionString ||
            DEFAULT_FUNCTION_FOR_EXPRESSION(isControlDeprecated)
          }
          onChange={(e) => props.onChange(props.index, "functionString", e)}
        />
      ) : null}
      {hasVariantsEnabled && (
        <MainCTAButton
          change={props.change}
          onChange={props.onChange}
          index={props.index}
        />
      )}
    </Space>
  );
}
