import React from "react";
import { dynamicValueType } from "./DynamicValueMod";
import {
  DataKeyIcon,
  ExpressionIcon,
  InputNameIcon,
  LiveControlIcon,
  ValueIcon,
} from "../../icons-and-animations/Icons";

export function getDynamicValueTypeIcon(vt: dynamicValueType) {
  let icon;
  switch (vt) {
    case "dataKey":
      icon = <DataKeyIcon />;
      break;
    case "liveControlKey":
      icon = <LiveControlIcon />;
      break;
    case "exposeToPlatform":
      icon = <ValueIcon />;
      break;
    case "value":
      icon = <ValueIcon />;
      break;
    case "expression":
      icon = <ExpressionIcon />;
      break;
    case "inputName":
      icon = <InputNameIcon />;
      break;
  }
  return <div className="panel-icon">{icon}</div>;
}
