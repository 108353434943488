import React, { useContext } from "react";
import { MSTContext } from "./stores/main";
import { observer } from "mobx-react-lite";
import { AsyncStateBtn } from "./BaseComps/AsyncStateBtn";
import { Tooltip } from "antd";
import { AsyncOpState } from "./stores/async-op-state";

export const SideFormActionButtons = observer(() => {
  const {
    platformStore: { selectedProjectId, updateVideoPartMods, saveModsStatus },
    modsStore: { hasUnsavedChanges: hasUnsavedChangesMods },
    playerStore,
  } = useContext(MSTContext);
  const disableProjectBtns =
    !playerStore.jsonFile ||
    !selectedProjectId ||
    saveModsStatus === AsyncOpState.Saving;
  return (
    <>
      {!hasUnsavedChangesMods ? (
        <Tooltip title={`No unsaved changes`}>
          <div className={"SideFormActionButtons"}>
            <AsyncStateBtn
              className="secondary"
              type="primary"
              onClick={async () => {
                if (!hasUnsavedChangesMods) return;
                await updateVideoPartMods();
              }}
              disabled={true}
              asyncState={saveModsStatus}
              btnText={"Save Connectors"}
            />
          </div>
        </Tooltip>
      ) : (
        <div className={"SideFormActionButtons"}>
          <AsyncStateBtn
            className="look-like-enable secondary"
            type="primary"
            onClick={async () => {
              if (!hasUnsavedChangesMods) return;
              await updateVideoPartMods();
            }}
            disabled={disableProjectBtns}
            asyncState={saveModsStatus}
            btnText={"Save Connectors"}
            ghost={!hasUnsavedChangesMods}
          />
        </div>
      )}
    </>
  );
});
