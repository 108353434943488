import { ModsPanelCompProps } from "../../ModsPanel";
import {
  COMMON_PATHS,
  findLayersOrLayerAssets,
  getPath,
  IInteractiveModConcat,
  interactiveConcatJumpTypes,
} from "@blings/blings-player";
import { AddInteractiveHOC } from "./InteractiveFormHOC";
import { useMst } from "../../../../stores/main";
import { Button, Col, Input, InputNumber, Row, Select, Space } from "antd";
import { JsEditor } from "../../../Editor/JsEditor";
import { useEffect, useState } from "react";
import MainCTAButton from "../../../MainCTA/MainCTAButton";
import { ExpressionIcon } from "../../../../icons-and-animations/Icons";
import { ExperimentOptimizationTechnique } from "../../../../API";
import { InfoTooltip } from "../../../../BaseComps/InfoTooltip";

const { Option } = Select;

const InteractiveConcatRender = (
  props: ModsPanelCompProps<IInteractiveModConcat>
) => {
  const {
    platformStore,
    experimentStore: { selectedExperimentOptimizationTechnique },
  } = useMst();
  const { change } = props;
  const hasVariantsEnabled =
    selectedExperimentOptimizationTechnique &&
    selectedExperimentOptimizationTechnique !==
      ExperimentOptimizationTechnique.DISABLED;

  const [useCode, setUseCode] = useState(props.change.functionString != null);
  const [prevState, setPrevState] = useState<{
    value?: Array<string>;
    jumpType?: interactiveConcatJumpTypes;
    jumpValue?: string | number;
  } | null>(null);
  const defaultJumpsValue = {
    sceneStart: props.change.value && props.change.value[0],
    sceneEnd: props.change.value && props.change.value[0],
    frame: 1,
    currentFrame: undefined,
  };

  useEffect(() => {
    if (!change.ctaName) {
      const layers = findLayersOrLayerAssets({
        assetId: change.assetId,
        layerName: change.layerName,
        layerUid: change.layerUid,
        jsonVid: props.json,
        additionals: change.additionals,
      });
      const layer =
        layers?.length &&
        layers.find((layer) => getPath(layer, COMMON_PATHS.TEXT_IN_LAYER));
      if (layer) {
        const text = getPath(layer, COMMON_PATHS.TEXT_IN_LAYER);
        text && props.onChange(props.index, "ctaName", text);
      }
    }
  }, [props.change.layerName, props.change.additionals?.length]);

  const jumpValueRender = () => {
    switch (props.change.jumpType) {
      case "sceneStart":
      case "sceneEnd":
        return (
          <Select
            style={{ minWidth: "2rem" }}
            value={props.change.jumpValue as string}
            onChange={(v) => {
              props.onChange(props.index, ["jumpValue"], [v]);
            }}
          >
            {props.change.value?.map((vp) => (
              <Option value={vp} key={vp}>
                {vp}
              </Option>
            ))}
          </Select>
        );
      case "frame":
        return (
          <InputNumber
            value={props.change.jumpValue as number}
            onChange={(value) =>
              props.onChange(props.index, ["jumpValue"], [value])
            }
          />
        );
      default:
        return <></>;
    }
  };
  return (
    <Space
      direction={"vertical"}
      style={{ width: "100%" }}
      className="interactive-space"
    >
      <Row>
        <Col style={{ width: "100%" }}>
          <span>Name this button (Required for analytics)</span>
          <InfoTooltip
            style={{ marginLeft: "5px" }}
            info="This name is used to track how many viewers clicked on the button."
          />
          <div style={{ display: "flex", alignItems: "center", gap: "25px" }}>
            <Input
              size={"large"}
              id="connector-cta-name"
              value={props.change.ctaName}
              addonBefore={"Name"}
              // eslint-disable-next-line no-template-curly-in-string
              placeholder="Use a clear & unique name"
              onChange={(e) =>
                props.onChange(props.index, ["ctaName"], [e.target.value])
              }
              allowClear={true}
            />
          </div>
        </Col>
      </Row>
      {useCode ? (
        <Col>
          <Row justify="end">
            <Button
              style={{
                backgroundColor: "var(--blings_pink)",
                borderColor: "var(--blings_teal)",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                setUseCode(false);
                props.onChange(
                  props.index,
                  ["functionString", "value", "jumpType", "jumpValue"],
                  !prevState
                    ? [undefined, [], "sceneStart", undefined]
                    : [
                        undefined,
                        prevState.value,
                        prevState.jumpType,
                        prevState.jumpValue,
                      ]
                );
              }}
            >
              <ExpressionIcon />
            </Button>
          </Row>
          <JsEditor
            defaultValue={props.change.functionString}
            onChange={(e: any) =>
              props.onChange(props.index, "functionString", e)
            }
            style={{ marginTop: "-1.1rem" }}
            tooltipText="This function will will determine the new timeline when the user inteacts with the layer/s and update the data accordingly."
          />
        </Col>
      ) : (
        <Row>
          <Select
            mode="multiple"
            allowClear
            className="dynamic-scene-select"
            style={{ flexGrow: "1", marginRight: "0.3rem", maxWidth: "85%" }}
            placeholder="Select scenes"
            value={props.change.value}
            onChange={(v) => {
              props.onChange(props.index, ["value"], [v]);
              props.onChange(props.index, "jumpValue", v[0]);
            }}
          >
            {platformStore.projectWorkspaceVersion?.videoParts?.map((vp) => (
              <Option key={vp.name} value={vp.name}>
                {vp.name}
              </Option>
            ))}
          </Select>
          <Button
            className="open-code-icon"
            style={{
              backgroundColor: "var(--blings_that_gray)",
              borderColor: "var(--blings_teal)",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              setUseCode(true);
              setPrevState({
                jumpType: props.change.jumpType,
                jumpValue: props.change.jumpValue,
                value: props.change.value,
              });
              const functionString = functionStringFromState(
                props,
                platformStore.isControlDeprecated
              );
              props.onChange(
                props.index,
                ["functionString", "value", "jumpType", "jumpValue"],
                [functionString, [], undefined, undefined]
              );
            }}
          >
            <ExpressionIcon />
          </Button>
        </Row>
      )}

      {!useCode && props.change.value?.length ? (
        <Space style={{ width: "100%" }}>
          <label>
            Goto:
            <Select
              value={props.change.jumpType}
              onChange={(v) => {
                props.onChange(
                  props.index,
                  ["jumpType", "jumpValue"],
                  [v, defaultJumpsValue[v]]
                );
              }}
            >
              <Option value="sceneStart">Scene Start</Option>
              <Option value="sceneEnd">Scene End</Option>
              <Option value="frame">Specific Frame</Option>
              <Option value="currentFrame">Current Frame</Option>
            </Select>
          </label>
          {jumpValueRender()}
        </Space>
      ) : null}
      {hasVariantsEnabled && (
        <MainCTAButton
          change={props.change}
          onChange={props.onChange}
          index={props.index}
        />
      )}
    </Space>
  );
};

export function AddInteractiveConcatForm(
  props: ModsPanelCompProps<IInteractiveModConcat>
) {
  return (
    <AddInteractiveHOC<IInteractiveModConcat>
      {...props}
      renderSpecificInteractive={InteractiveConcatRender}
    />
  );
}
function functionStringFromState(
  props: ModsPanelCompProps<IInteractiveModConcat>,
  controlDeprecated = false
) {
  const jumpType =
    props.change.jumpType && props.change.jumpType in interactiveConcatJumpTypes
      ? props.change.jumpType
      : "sceneStart";
  return `async ({ data,${
    controlDeprecated ? "" : "liveControl,"
  } inputs, element, event, player, jumpTypes }) => {
  //data.oneOfTheParams = "new value"
  return {
    // required. pre fill with the non-advanced config, when switching to advanced
    // notice - this enables the functionality of selecting next scenes with logic,
    scenes: [${props.change.value?.map((v) => `"${v}"`).join(", ")}],

    // both optional. pre fill with the non-advanced config, when switching to advanced
    jumpType: jumpTypes.${jumpType},
    jumpValue: ${
      typeof props.change.jumpValue === "string"
        ? `"${props.change.jumpValue}"`
        : props.change.jumpValue
    },

    // optional, if provided, update the data accodringly. 
    data
  }
}
  `;
}
