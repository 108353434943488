import { useEffect, useState } from "react";
import { Button } from "antd";
import { observer } from "mobx-react-lite";
import { useMst } from "../../stores/main";
import "./ResetSceneButton.scss";
import { ResetSceneIcon } from "../../icons-and-animations/Icons";

export const ResetSceneButton = observer(
  (props: { sceneName?: string; resetPlayerCallback: () => void }) => {
    const {
      playerStore: { resetScenesButtonEnabled },
    } = useMst();

    const [animationClass, setAnimationClass] = useState("");

    useEffect(() => {
      if (resetScenesButtonEnabled) {
        setAnimationClass("slide-in");
      } else {
        setAnimationClass("");
      }
    }, [resetScenesButtonEnabled]);

    if (!resetScenesButtonEnabled && animationClass === "") {
      return null;
    }

    return (
      <Button
        className={`ResetSceneButton ${animationClass}`}
        onClick={props.resetPlayerCallback}
      >
        <ResetSceneIcon />
        <span className="text">
          Back to&nbsp;
          <strong>{props.sceneName ? `${props.sceneName}` : ""}</strong>
          &nbsp;scene
        </span>
      </Button>
    );
  }
);
