import { tabsOptions } from "../stores/consts";

export type StudioTabs = "layers" | "flow" | "thumb" | "";

export const getTabName = (tab: string | undefined): StudioTabs => {
  return (
    (tabsOptions.find((t) => t.path === tab)?.path as StudioTabs) || "layers"
  );
};

export const tabsPathResolver = (path: {
  projectId: string | undefined;
  tab: string | undefined;
  scene: string | undefined;
}) => {
  const validTabName =
    tabsOptions.find((t) => t.path === path.tab)?.path || null;
  const validSceneName =
    !path.scene && path.tab && !validTabName ? path.tab : path.scene;
  if (!path.projectId) return "";
  if (!validTabName && !validSceneName) return `${path.projectId}`;
  if (validTabName && !validSceneName)
    return `${path.projectId}/${validTabName}`;
  if (!validTabName && validSceneName) {
    const validTabName = getTabName(undefined);
    return `${path.projectId}/${validTabName}/${validSceneName}`;
  } else return `${path.projectId}/${validTabName}/${validSceneName}`;
};

export const parsePathName = (path: string) => {
  const parts = path.split("/");
  return {
    projectId: parts[1],
    tab: parts[2],
    scene: parts[3],
  };
};
