import {
  COMMON_PATHS,
  ICountdownChangeMod,
  TimeUnit,
  findLayersOrLayerAssets,
  getPath,
} from "@blings/blings-player";
import { ModsPanelCompProps } from "../ModsPanel";
import { DynamicValueMod, DynamicValueModTypes } from "../DynamicValueMod";
import { LayerSelector } from "../../LayerSelector";
import { Checkbox, Collapse } from "antd";
import { InfoTooltip } from "../../../BaseComps/InfoTooltip";
const { Panel } = Collapse;

export function CountdownChangeForm(
  props: ModsPanelCompProps<ICountdownChangeMod>
) {
  const config = props.change;
  const json = props.json;
  const timeUnitOptions: TimeUnit[] = ["days", "hours", "minutes", "seconds"];

  const changeTimeFormatAndLayers = (
    assetId,
    layerName,
    layerUid,
    additionals,
    pathTochange
  ) => {
    props.onInnerModChange(
      props.index,
      [
        `${pathTochange}.assetId`,
        `${pathTochange}.layerName`,
        `${pathTochange}.layerUid`,
        `${pathTochange}.additionals`,
      ],
      [assetId, layerName, layerUid, additionals]
    );
  };

  const timeUnitLayerSelector = (timeUnit: TimeUnit) => {
    const timeFormatAndLayers = config.timeFormatAndLayers?.[timeUnit];
    const isDisabled = config.timeFormatAndLayers?.[timeUnit]?.isDisabled;
    const selectedAssetId = timeFormatAndLayers?.assetId;
    const selectedLayerName = timeFormatAndLayers?.layerName || "";
    const selectedLayerUid = timeFormatAndLayers?.layerUid;
    const selectedAdditionals = timeFormatAndLayers?.additionals;
    return (
      <div className={`time-unit-selector ${isDisabled ? "isDisabled" : ""}`}>
        <Checkbox
          checked={!isDisabled}
          onChange={(e) =>
            props.onInnerModChange(
              props.index,
              [`timeFormatAndLayers.${timeUnit}.isDisabled`],
              [!e.target.checked]
            )
          }
        />
        <label>{timeUnit}</label>
        <div style={{ width: "16rem" }}>
          <LayerSelector
            isMulti={true}
            subSelector={true}
            placeholder="Select layer for days"
            assetLayerPairs={props.assetLayerPairs}
            selectedAssetId={selectedAssetId}
            selectedLayerName={selectedLayerName}
            selectedLayerUid={selectedLayerUid}
            jsonVid={json}
            onChange={(indexF, assetId, layerName, layerUid, additionals) => {
              changeTimeFormatAndLayers(
                assetId,
                layerName,
                layerUid,
                additionals,
                `timeFormatAndLayers.${timeUnit}`
              );
            }}
            selectedAdditionals={selectedAdditionals}
            index={props.index}
            mapFilterLayerFunc={(
              p,
              layerName,
              assetId,
              layerUid,
              additionals
            ) => {
              const layers = findLayersOrLayerAssets({
                assetId: assetId === "main" ? undefined : assetId,
                layerName,
                jsonVid: json,
                layerUid,
                additionals,
              });
              let parent;
              let child;
              const text =
                layers?.length &&
                getPath(layers[0], COMMON_PATHS.TEXT_IN_LAYER);
              if (!!text) {
                if (assetId !== "main") {
                  parent = assetId;
                  child = layerName;
                } else {
                  parent = layerName;
                }
                p.push(
                  text === layerName
                    ? {
                        renderText: `${text} - ${assetId}`,
                        layerUid,
                        layerName,
                        parent,
                        child,
                      }
                    : {
                        renderText: `${text} [${layerName} - ${assetId}]`,
                        layerUid,
                        layerName,
                        parent,
                        child,
                      }
                );
              }
              return p;
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column" }}
      className="countdown-connector"
    >
      <label style={{ fontWeight: "600" }}>Select a date</label>
      <DynamicValueMod
        change={props.change}
        onChange={props.onChange}
        index={props.index}
        type={DynamicValueModTypes.date}
        jsonVid={json}
      />
      <div className="column-countdown">
        {timeUnitOptions.map((timeUnit) => (
          <div key={timeUnit}>{timeUnitLayerSelector(timeUnit)}</div>
        ))}
      </div>
      <Collapse ghost className="advanced-collapse">
        <Panel header="Advanced" key="1">
          <div style={{ width: "100%", marginBottom: "1rem" }}>
            <div
              className="sub-advanced-connectors sub-menu"
              style={{ marginBottom: "1rem" }}
            >
              <Checkbox
                checked={true || config.hasPadding}
                disabled={true} // until mask issue is fixed from ae
                onChange={(e) =>
                  props.onChange(props.index, "hasPadding", e.target.checked)
                }
              />
              <label>Format with Leading Zeros</label>
              <InfoTooltip
                info={
                  `Coming soon...
                ` +
                  "Toggle this option to add leading zeros to your countdown. When enabled, single-digit numbers will be prefixed with a zero to maintain consistent digit count. For example, '2' will be displayed as '02'."
                }
              />
            </div>
            <div>
              <div className="sub-menu" style={{ fontWeight: "bold" }}>
                When Time is up
              </div>
              <div
                style={{
                  gap: "0.5rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="sub-advanced-connectors">
                  <label>Show layers: </label>
                  <LayerSelector
                    subSelector={true}
                    isMulti={true}
                    placeholder="Select layer for days"
                    assetLayerPairs={props.assetLayerPairs}
                    selectedAssetId={config.showWhenExpired?.assetId}
                    selectedLayerName={config.showWhenExpired?.layerName || ""}
                    selectedLayerUid={config.showWhenExpired?.layerUid}
                    jsonVid={json}
                    onChange={(
                      index,
                      assetId,
                      layerName,
                      layerUid,
                      additionals
                    ) => {
                      changeTimeFormatAndLayers(
                        assetId,
                        layerName,
                        layerUid,
                        additionals,
                        "showWhenExpired"
                      );
                    }}
                    selectedAdditionals={config.showWhenExpired?.additionals}
                    index={props.index}
                  />
                </div>
                <div className="sub-advanced-connectors">
                  <label>Hide Layers: </label>
                  <LayerSelector
                    isMulti={true}
                    subSelector={true}
                    placeholder="Select layer for days"
                    assetLayerPairs={props.assetLayerPairs}
                    selectedAssetId={config.hideWhenExpired?.assetId}
                    selectedLayerName={config.hideWhenExpired?.layerName || ""}
                    selectedLayerUid={config.hideWhenExpired?.layerUid}
                    jsonVid={json}
                    onChange={(
                      index,
                      assetId,
                      layerName,
                      layerUid,
                      additionals
                    ) => {
                      changeTimeFormatAndLayers(
                        assetId,
                        layerName,
                        layerUid,
                        additionals,
                        "hideWhenExpired"
                      );
                    }}
                    selectedAdditionals={config.hideWhenExpired?.additionals}
                    index={props.index}
                  />
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
}
