import React, {
  useContext,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { observer } from "mobx-react-lite";
import { Instance } from "mobx-state-tree";
import { SceneModel, LineupModel, flowsStore } from "../../stores/FlowStore";
import { SortableScene } from "./SortableScene";
import {
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Button, Dropdown } from "antd";

import classNames from "classnames";
import {
  DndContext,
  closestCenter,
  MeasuringStrategy,
  DragOverlay,
  defaultDropAnimationSideEffects,
  DropAnimation,
} from "@dnd-kit/core";
import styles from "./styles.module.scss";
import type { MenuProps } from "antd";
import { AddOutlinedIcon } from "../../icons-and-animations/Icons";
import { MSTContext } from "../../stores/main";
import {
  restrictToWindowEdges,
  restrictToHorizontalAxis,
} from "@dnd-kit/modifiers";

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.5",
      },
    },
  }),
};

export const Flow = observer(
  ({
    flow,
    flowScenes,
    isActive,
    onBlur,
    scrollContainerRef,
  }: {
    flow: Instance<typeof LineupModel>;
    flowScenes: Instance<typeof SceneModel>[];
    isActive: boolean;
    onBlur: React.FocusEventHandler<HTMLElement>;
    scrollContainerRef?: React.RefObject<HTMLDivElement>;
  }) => {
    const {
      platformStore: { videoScenes },
    } = useContext(MSTContext);
    const containerRef = useRef<HTMLDivElement>(null);
    const dropDownRef = useRef<HTMLDivElement>(null);
    const [activeId, setActiveId] = React.useState<string | number>("");

    const {
      computedCurrentLineupId,
      selectedFlowScenesCount,
      isInEdit,
      selectedFlow,
    } = flowsStore;

    const handleAddScene = useCallback(
      (value) => {
        flow.addScene({
          id: `${Date.now()}`,
          label: value,
          lineupId: null,
        } as Instance<typeof SceneModel>);
      },

      [flow]
    );

    useEffect(() => {
      if (computedCurrentLineupId !== flow.lineupId) {
        return;
      }
      if (scrollContainerRef?.current) {
        let scrollWidth = scrollContainerRef?.current?.scrollWidth;
        if (
          containerRef?.current &&
          scrollWidth > containerRef?.current?.getBoundingClientRect().width
        ) {
          let numOfScenes = selectedFlowScenesCount
            ? selectedFlowScenesCount
            : 1;
          scrollWidth = numOfScenes * 200 + 350;
          if (scrollWidth < window.innerWidth) {
            scrollWidth = 0;
          }
        }
        scrollContainerRef.current.scrollTo({
          left: scrollWidth,
          behavior: "smooth",
        });
      }
    }, [
      computedCurrentLineupId,
      scrollContainerRef,
      flow,
      selectedFlowScenesCount,
    ]);

    const getDropDownMenu = useMemo((): MenuProps => {
      const menuItems: MenuProps["items"] = videoScenes.map((element) => ({
        key: element?.name,
        className: styles["add-scene-menu-item-wrapper"],
        onClick: () => handleAddScene(element?.name),
        label: (
          <div
            id="add-scene-menu-item"
            className={styles["add-scene-menu-item"]}
          >
            {element?.name}
          </div>
        ),
      }));

      return { items: menuItems };
    }, [videoScenes, handleAddScene]);

    const handleDragEnd = (event: any) => {
      const { active, over } = event;
      setActiveId("");
      if (active.id === over.id) {
        return;
      }
      const activeIndex = flowScenes.findIndex(
        (scene) => scene.id === active.id
      );
      const overIndex = flowScenes.findIndex((scene) => scene.id === over.id);
      flow.reorderScenes(activeIndex, overIndex);
      flowsStore.setSomeChange();
    };

    const handleRemoveScene = (id) => {
      flow.removeScene(id);
    };

    return (
      <div
        tabIndex={0}
        onBlur={onBlur}
        className={styles["scenes-list-wrapper"]}
        ref={containerRef}
      >
        <DndContext
          measuring={{
            droppable: {
              strategy: MeasuringStrategy.Always,
            },
          }}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToWindowEdges, restrictToHorizontalAxis]}
          onDragStart={(event) => setActiveId(event.active.id)}
        >
          <div
            className={classNames(
              styles["sortable-wrapper"],
              isInEdit && selectedFlow?.id === flow.id
                ? styles["active-flow"]
                : ""
            )}
          >
            <SortableContext
              items={flowScenes.map((item) => item.id)}
              strategy={horizontalListSortingStrategy}
              id="flow-scenes"
            >
              {flowScenes.map((scene, index) => (
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  key={scene.id}
                >
                  <SortableScene
                    key={scene.id}
                    scene={scene}
                    id={scene.id}
                    isActive={isInEdit && selectedFlow?.id === flow.id}
                    dragging={activeId === scene.id}
                    isLastIndex={
                      index === flowScenes.length - 1 && flowScenes.length > 0
                    }
                    onRemove={handleRemoveScene}
                  />
                </div>
              ))}
              <DragOverlay
                adjustScale={true}
                dropAnimation={dropAnimationConfig}
              >
                {activeId !== "" && (
                  <SortableScene
                    id={
                      flowScenes.find((item) => item.id === activeId)?.id || ""
                    }
                    dragOverlay={true}
                    dragging={true}
                    isLastIndex={false}
                    isActive={true}
                    onRemove={handleRemoveScene}
                    scene={
                      flowScenes.find((item) => item.id === activeId) || {
                        id: "",
                        label: "",
                        lineupId: null,
                        data: "",
                      }
                    }
                  />
                )}
              </DragOverlay>
            </SortableContext>
          </div>
          {isActive ? (
            <>
              <Dropdown
                getPopupContainer={() => dropDownRef.current || document.body}
                overlayClassName="action-flow"
                menu={getDropDownMenu}
                trigger={["click"]}
                overlayStyle={{
                  overflow: "hidden",
                }}
              >
                <div
                  onClick={(e) => e.preventDefault()}
                  className={styles["add-scene-btn"]}
                >
                  <Button type="text">
                    <AddOutlinedIcon />
                    Add a Scene
                  </Button>
                </div>
              </Dropdown>
            </>
          ) : null}
        </DndContext>
      </div>
    );
  }
);
