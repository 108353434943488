import { types, Instance, destroy } from "mobx-state-tree";
import { arrayMove } from "@dnd-kit/sortable";
import { UniqueIdentifier } from "@dnd-kit/core";
import {
  FLOW_DIAGRAM_NODE_TYPE,
  IFlowDiagramNode,
  IFlowDiagramEdge,
  IFlowDiagram,
} from "@blings/blings-player";

export const SceneModel = types.model("Scene", {
  id: types.string,
  label: types.string,
  lineupId: types.maybeNull(types.string),
  data: types.maybeNull(types.string),
});

export const LineupModel = types
  .model("Lineup", {
    id: types.identifier,
    lineupId: types.string,
    scenes: types.array(SceneModel),
    isDefault: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    addScene(scene: Instance<typeof SceneModel>) {
      self.scenes.push(scene);
    },
    removeScene(sceneId: UniqueIdentifier) {
      const index = self.scenes.findIndex((scene) => scene.id === sceneId);
      if (index !== -1) {
        self.scenes.splice(index, 1);
      }
    },
    reorderScenes(oldIndex: number, newIndex: number) {
      self.scenes.replace(arrayMove(self.scenes.slice(), oldIndex, newIndex));
    },
    setDefault(value: boolean) {
      self.isDefault = value;
    },
  }));

export const FlowStore = types
  .model("FlowStore", {
    flows: types.array(LineupModel),
    isInEdit: types.optional(types.boolean, false),
    hasChangesToSave: types.optional(types.boolean, false), // flag to show unsaved changes and alert user or prevent continue
    isDirty: types.optional(types.boolean, false),
    selectedFlow: types.maybeNull(types.reference(LineupModel)),
    someChange: types.optional(types.integer, 0), // used to trigger re-render when flows are updated
  })
  .actions((self) => ({
    setFlows(flows: Instance<typeof LineupModel>[]) {
      self.flows.replace(flows);
    },
    addFlow() {
      const lineupId = getFlowName(self.flows);
      const id = `${Date.now()}`;
      self.flows.unshift(
        LineupModel.create({
          id: id,
          lineupId: lineupId,
          scenes: [],
        })
      );
      self.isDirty = true;
    },
    setSomeChange() {
      self.someChange = self.someChange + 1;
    },
    addFlowAndSelect() {
      const lineupId = getFlowName(self.flows);
      const id = `${Date.now()}`;
      self.flows.unshift(
        LineupModel.create({
          id: id,
          lineupId: lineupId,
          scenes: [],
          isDefault: self.flows.length === 0 ? true : false,
        })
      );
      self.isDirty = true;
      self.selectedFlow = self.flows[0];
      self.isInEdit = true;
    },
    duplicateFlow(flowId: string) {
      const flow = self.flows.find((f) => f.id === flowId);
      if (flow) {
        const scenes = flow.scenes.map((scene, index) => ({
          id: `${Date.now()}-${index}`,
          label: scene.label,
          lineupId: scene.lineupId,
          data: scene.data,
        }));

        const newLineupId = getUniqueFlowLabel(self.flows, flow.lineupId);
        const id = `${Date.now()}`;
        self.flows.unshift(
          LineupModel.create({
            id: id,
            lineupId: newLineupId,
            scenes,
          })
        );
        self.isDirty = true;
      }
    },

    deleteFlow(flowId: string) {
      const index = self.flows.findIndex((flow) => flow.id === flowId);
      if (index > -1) {
        if (self.flows[index].id === self.selectedFlow?.id) {
          if (self.flows.length > 1) {
            if (index === 0) {
              self.selectedFlow = self.flows[1];
            } else {
              self.selectedFlow = self.flows[index - 1];
            }
          } else {
            self.selectedFlow = null;
          }
          self.isInEdit = false;
        }
        destroy(self.flows[index]);
        self.isDirty = true;
      }
    },

    setDefaultFlow(lineupId: string) {
      self.flows.forEach((f) => {
        if (f.isDefault) {
          f.isDefault = false;
        }
      });
      const flow = self.flows.find((f) => f.lineupId === lineupId);
      if (flow) {
        flow.setDefault(true);
        self.isDirty = true;
      }
    },
    markDirty() {
      self.isDirty = true;
    },
    markClean() {
      self.someChange = 0;
      self.hasChangesToSave = false;
      self.isDirty = false;
      self.isInEdit = false;
    },
    setInEdit(value: boolean) {
      self.isInEdit = value;
    },
    setUnsavedChanges(value: boolean) {
      self.hasChangesToSave = value;
    },
    setSelectedFlow(flowId?: string, flow?: Instance<typeof LineupModel>) {
      if (flow) {
        self.selectedFlow = flow;
        return;
      }
      self.selectedFlow = self.flows.find(
        (flow) => flow.id === flowId
      ) as Instance<typeof LineupModel> | null;
    },

    flowsInit(flows?: Array<ILineupModel>, defaultFlow?: string) {
      try {
        flows = flows ?? [];
        self.flows.replace(flows);
        self.isDirty = false;
        self.isInEdit = false;
        self.hasChangesToSave = false;
        self.someChange = 0;
        if (defaultFlow) {
          const selectedIndex = self.flows.findIndex(
            (flow) => flow.lineupId === defaultFlow
          );
          if (selectedIndex > -1) {
            self.selectedFlow = self.flows[selectedIndex];
          }
        } else if (self.flows.length > 0) {
          self.selectedFlow = self.flows[0];
        }
      } catch (e) {}
    },
    changeFlowLabel(flowId: string, label: string) {
      const flow = self.flows.find((f) => f.id === flowId);
      if (flow) {
        flow.lineupId = label;
        self.isDirty = false;
      }
    },
    isFlowValid(flowId: string) {
      const flow = self.flows.find((f) => f.id === flowId);
      if (flow) {
        if (flow.scenes.length === 0) {
          return false;
        }
      }
      return true;
    },
    resetSelectedFlow() {
      self.selectedFlow = null;
    },
  }))
  .views((self) => ({
    get validteFlows() {
      for (let i = 0; i < self.flows.length; i++) {
        if (self.flows[i].scenes.length === 0) {
          return false;
        }
      }
      return true;
    },
    get someChangeValue() {
      return self.someChange;
    },

    get isSelectedFlowValid() {
      return self.selectedFlow && self.selectedFlow.scenes?.length > 0
        ? true
        : false;
    },
    // flowDiagram convert the nodes and edges to the player's format, but also to convert for PlatformStore to save them.
    get flowDiagram() {
      const defaultFlow = self.flows.find((flow) => flow.isDefault);
      const nodesExport: Array<IFlowDiagramNode> = [];
      const edgesExport: Array<IFlowDiagramEdge> = [];
      self.flows.forEach((element) => {
        const { nodes, edges } = getFlowDiagramFormat(element);
        nodesExport.push(...nodes);
        edgesExport.push(...edges);
      });
      // sort the nodes so that the default flow is first. the Lineup object does not have a default property. it chooses the first one as default.
      // This is for the PlatfromStore so it could save the first one as default.
      nodesExport.sort((a, b) => {
        return a.lineupId === defaultFlow?.lineupId ? -1 : 1;
      });
      return { nodes: nodesExport, edges: edgesExport };
    },
    get selectedFlowName() {
      if (self.selectedFlow) {
        return [{ lineup: self.selectedFlow.lineupId }];
      }
      return [];
    },
    get computedCurrentLineupId() {
      return self.selectedFlow?.lineupId ? self.selectedFlow.lineupId : null;
    },

    get selectedFlowScenesCount() {
      return self.selectedFlow?.scenes?.length ?? 0;
    },
  }));

export type IFlowStore = Instance<typeof FlowStore>;

export const flowsStore = FlowStore.create({
  flows: [],
  isDirty: false,
  isInEdit: false,
  hasChangesToSave: false,
  selectedFlow: null,
  someChange: 0,
});

export const getUniqueFlowLabel = (
  flows: Instance<typeof LineupModel>[],
  label: string
) => {
  const namePattern = new RegExp(`^${label}( \\d+)?$`);
  const similarNames = flows.filter((element) =>
    namePattern.test(element.lineupId)
  );

  let maxCounter = 0;
  similarNames.forEach((element) => {
    const match = element.lineupId.match(/(\d+)$/);
    if (match) {
      const counter = parseInt(match[0], 10);
      maxCounter = Math.max(maxCounter, counter);
    }
  });

  return `${label} ${maxCounter + 1}`;
};

const getFlowName = (flows) => {
  let count = 1;
  flows.forEach((flow) => {
    if (flow.lineupId.includes("Untitled")) {
      count++;
    }
  });
  return "Untitled " + count;
};

export const getFlowDiagramFormat = (
  flow: Instance<typeof LineupModel> | null
): IFlowDiagram => {
  const nodes: Array<IFlowDiagramNode> = [];
  const edges: Array<IFlowDiagramEdge> = [];
  if (!flow) {
    return { nodes, edges };
  }
  if (flow.scenes.length === 0) {
    return { nodes, edges };
  }
  if (flow.scenes.length === 1) {
    edges.push({
      source: flow.scenes[0].id,
      target: flow.scenes[0].id,
      sourceHandle: null,
    });
    nodes.push({
      id: flow.scenes[0].id,
      label: flow.scenes[0].label,
      type: FLOW_DIAGRAM_NODE_TYPE.SCENE,
      lineupId: flow.lineupId,
    });
    return { nodes, edges };
  } else if (flow.scenes.length > 1) {
    nodes.push({
      id: flow.scenes[0].id,
      label: flow.scenes[0].label,
      type: FLOW_DIAGRAM_NODE_TYPE.SCENE,
      lineupId: flow.lineupId,
    });
    for (let i = 0; i < flow.scenes.length; i++) {
      if (i === flow.scenes.length - 1) {
        continue;
      }
      edges.push({
        source: flow.scenes[i].id,
        target: flow.scenes[i + 1].id,
        sourceHandle: null,
      });
      nodes.push({
        id: flow.scenes[i + 1].id,
        label: flow.scenes[i + 1].label,
        type: FLOW_DIAGRAM_NODE_TYPE.SCENE,
        lineupId: undefined,
      });
    }
  }
  return { nodes, edges };
};

export type ISceneModel = Instance<typeof SceneModel>;
export type ILineupModel = Instance<typeof LineupModel>;
