import { ErrorBoundary as REB } from "react-error-boundary";
import { Button } from "antd";
import "./ErrorBoundary.scss";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div className="BlingsErrorFallback">
      <p className="Title">Something went wrong:</p>
      <p className="code">{error.message}</p>
      <Button
        className="ResetButton"
        type="primary"
        onClick={resetErrorBoundary}
      >
        Reset
      </Button>
    </div>
  );
}

export function ErrorBoundary(props: {
  resetFunc?: () => void;
  children: React.ReactNode;
}) {
  return (
    <REB
      FallbackComponent={ErrorFallback}
      onReset={() => {
        props.resetFunc && props.resetFunc();
      }}
    >
      {props.children}
    </REB>
  );
}
