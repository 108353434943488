import { DEFAULT_MAX_LAYER_CHARS } from "../stores/consts";

export function shortenTextAEStyle(text, maxChars = DEFAULT_MAX_LAYER_CHARS) {
  const textTrimmed = text.trim();
  const textL = textTrimmed.length;
  if (textL < maxChars) {
    return textTrimmed;
  }
  const mid = " ... ";
  const midL = mid.length;
  const startL = Math.ceil((maxChars - midL) / 2);
  const endL = textL - Math.floor((maxChars - midL) / 2);
  return textTrimmed.slice(0, startL) + mid + textTrimmed.slice(endL);
}
