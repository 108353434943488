import React from "react";
import { useRef } from "react";
import { useMst } from "../../stores/main";
import "./DataSetter.scss";
import { observer } from "mobx-react-lite";
import { DynamicForm } from "@blings/dynamic-form";
import { BlingsBtn } from "../antd-extensions/blings-btn.component";
import { AsyncOpState } from "../../stores/async-op-state";
import { cleanEmptyStringInValue } from "../../helpers/objects";
import { Tooltip } from "antd";
import BlingsTheme from "../../config/antdTheme";

type Props = {};
export const LiveControlSetter = observer((props: Props) => {
  const {
    platformStore,
    dynamicDataStore: {
      setCurrentLiveControlData,
      setCurrentLiveControlSchema,
      /* setInitialLiveControlData, */
      liveControlInitialSchema,
      initialLiveControlData,
      hasUnsavedChangesLiveControl,
    },
  } = useMst();

  /*   useEffect(() => {
      setInitialLiveControlData(platformStore.selectedProjectLiveControl);
    }, [platformStore.selectedProjectId]); */
  const submit = useRef<() => void>(() => {
    return;
  });

  if (!platformStore.projectWorkspaceVersion)
    return <div className={"editor-wrapper"}></div>;

  return (
    <div className={"editor-wrapper"}>
      <DynamicForm
        themeConfig={BlingsTheme}
        key={`${liveControlInitialSchema}-LiveControl`}
        schema={JSON.parse(liveControlInitialSchema || "{}")}
        formData={initialLiveControlData}
        saveData={(data, schema) => {
          const cleanData = cleanEmptyStringInValue(data);
          platformStore.saveLiveControlSchema(schema, cleanData);
        }}
        submit={submit}
        editable={true}
        uploadAssetToProject={platformStore.uploadAssetToProject}
        getAssetsUploadStatus={platformStore.getDynamicFormAssetsUploadStatus}
        onChange={(formData, schema) => {
          setCurrentLiveControlData(formData);
          setCurrentLiveControlSchema(schema);
        }}
        readable={false}
        fieldDescription={{
          key: "A name for the data field",
          value:
            "Data value. Can be updated for all videos from Blings platform",
        }}
      />
      <div className="footer">
        {!hasUnsavedChangesLiveControl ? ( //no changes
          <Tooltip title={`No unsaved changes`}>
            <BlingsBtn
              className={"save-schema-btn secondary"}
              opState={platformStore.saveProjectLiveControlSchemaStatus}
              htmlType={"submit"}
              disabled={true}
              btnTexts={{ [AsyncOpState.Changed]: "Save 'control' schema" }}
              onClick={() => {
                if (!hasUnsavedChangesLiveControl) return;
                submit.current();
              }}
            />
          </Tooltip>
        ) : (
          <BlingsBtn
            className={"save-schema-btn secondary"}
            opState={platformStore.saveProjectLiveControlSchemaStatus}
            htmlType={"submit"}
            btnTexts={{ [AsyncOpState.Changed]: "Save 'control' schema" }}
            onClick={() => {
              submit.current();
            }}
          />
        )}
      </div>
    </div>
  );
});
