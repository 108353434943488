import * as React from "react";
import burgerAnim from "../../icons-and-animations/burger.json";
import { Button, Dropdown } from "antd";
import { DownloadOutlined, ProjectOutlined } from "@ant-design/icons";
import { useMst } from "../../stores/main";
import { observer } from "mobx-react-lite";
import { HoverableLottie } from "../../BaseComps/HoverableLottie";
import { useRef } from "react";
import "./BurgerMenu.scss";
import { BLINGS_API, getENV } from "../../stores/consts";
const demoDownloadUrl =
  "https://assets.blings.io/demo/OnBoardingVideoCollect.zip";
const extensionDownloadUrl = `${
  BLINGS_API[getENV()]
}/extension/latest/download`;
type Props = {};
export const BurgerMenu = observer((props: Props) => {
  const {
    platformStore: { logout, selectedProjectId },
  } = useMst();
  const handleDownload = (url: string) => {
    const link = document.createElement("a");
    link.download = "demo";
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link.remove();
  };
  function BurgerOverlayMenu() {
    const PLATFORM = `https://${
      getENV() === "dev" ? "dev." : ""
    }platform.blings.io/`;
    return (
      <div className="burger-overlay-menu">
        <div className="text-buttons">
          <div
            onClick={() =>
              window.open(
                selectedProjectId
                  ? PLATFORM + "project/" + selectedProjectId
                  : PLATFORM,
                "_blank"
              )
            }
          >
            <p className="menu-option">
              <ProjectOutlined /> Platform
            </p>
          </div>
          <div onClick={() => handleDownload(demoDownloadUrl)}>
            <p className="menu-option">
              <DownloadOutlined /> Demo Files
            </p>
          </div>
          <div onClick={() => handleDownload(extensionDownloadUrl)}>
            <p className="menu-option">
              <DownloadOutlined /> Extension
            </p>
          </div>
          <div
            onClick={() =>
              window.open(
                "https://portal.blings.io/",
                "_blank"
              )
            }
          >
            <div
              className="menu-option"
              style={{ display: "flex", alignItems: "center", gap: "0.3rem" }}
            >
              <div
                style={{ width: "0.86rem", height: "0.86rem", display: "flex" }}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 3H16C14.9391 3 13.9217 3.42143 13.1716 4.17157C12.4214 4.92172 12 5.93913 12 7V21C12 20.2044 12.3161 19.4413 12.8787 18.8787C13.4413 18.3161 14.2044 18 15 18H22V3Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2 3H8C9.06087 3 10.0783 3.42143 10.8284 4.17157C11.5786 4.92172 12 5.93913 12 7V21C12 20.2044 11.6839 19.4413 11.1213 18.8787C10.5587 18.3161 9.79565 18 9 18H2V3Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              Documentation
            </div>
          </div>
        </div>
        <div className="log-out">
          <Button
            onClick={() => {
              logout();
            }}
          >
            Log Out
          </Button>
        </div>
      </div>
    );
  }
  const ddRef = useRef<HTMLDivElement>(null);

  return (
    <div id="top-burger-menu">
      <Dropdown
        className={"burger-dropdown"}
        overlay={BurgerOverlayMenu}
        placement="bottomLeft"
      >
        <span ref={ddRef}>
          <HoverableLottie
            hoverableElement={ddRef}
            animationData={burgerAnim}
            size={40}
          />
        </span>
      </Dropdown>
    </div>
  );
});
