import { IHideLayerChangeMod } from "@blings/blings-player";
import { ModsPanelCompProps } from "../ModsPanel";
import { DynamicValueMod, DynamicValueModTypes } from "../DynamicValueMod";
import { LayerSelector } from "../../LayerSelector";

export function HideLayerForm(props: ModsPanelCompProps<IHideLayerChangeMod>) {
  const config = props.change;
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>
        <LayerSelector
          isMulti={true}
          assetLayerPairs={props.assetLayerPairs}
          selectedAssetId={config.assetId}
          selectedLayerName={config.layerName}
          selectedLayerUid={config.layerUid}
          onChange={props.onLayerChange}
          selectedAdditionals={config.additionals}
          index={props.index}
          jsonVid={props.json}
        />
      </div>

      <DynamicValueMod
        change={props.change}
        onChange={props.onChange}
        index={props.index}
        type={DynamicValueModTypes.boolean}
        booleanText={["Hide", "Do not hide"]}
      />
    </div>
  );
}
