import { Def } from "./mod-definitions";
import interactiveAnimationData from "../../../icons-and-animations/ConnectorBtns/interactive_mode.json";
import advancedAnimationData from "../../../icons-and-animations/ConnectorBtns/Advance_mode.json";
import inputAnimationData from "../../../icons-and-animations/ConnectorBtns/Input_connector.json";
import { theme } from "../../../theme";

export const dropDownGroups: {
  [key: string]: { dropDownText: string; color: string; animation: any };
} = {
  interaction: {
    dropDownText: "Interactive",
    color: theme.connectors.interactive,
    animation: interactiveAnimationData,
  },
  utils: {
    dropDownText: "Utils",
    color: theme.connectors.utils,
    animation: inputAnimationData,
  },
  advanced: {
    dropDownText: "Advanced",
    color: theme.connectors.advanced,
    animation: advancedAnimationData,
  },
};

export type dropDownGroupsKeys = keyof typeof dropDownGroups;

export type IGroupsDict = {
  type: "dropDownGroup";
  name: dropDownGroupsKeys;
  defs: Def<any>[];
};
