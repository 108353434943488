/**
 * Altered version
 * - original in blings-player
 */
export function findPathsToKey(
  obj: any,
  predicate: (v: any, pathToKey: string) => boolean
) {
  const results: [string, any][] = [];

  const options: {
    obj: any;
    predicate: (v: any, pathToKey: string) => boolean;
    pathToKey?: string;
  } = { obj, predicate };

  // tslint:disable-next-line:no-shadowed-variable
  (function findKey({ predicate, obj, pathToKey }) {
    const oldPath = `${pathToKey ? pathToKey + "." : ""}`;

    if (predicate(obj, oldPath)) {
      return;
    }

    for (const k in obj) {
      if (predicate(obj[k], `${oldPath}${k}`)) {
        results.push([`${oldPath}${k}`, obj[k]]);
      }
    }

    if (obj !== null && typeof obj === "object" && !Array.isArray(obj)) {
      for (const k in obj) {
        if (obj.hasOwnProperty(k)) {
          if (Array.isArray(obj[k])) {
            for (let j = 0; j < obj[k].length; j++) {
              findKey({
                obj: obj[k][j],
                predicate,
                pathToKey: `${oldPath}${k}.${j}`,
              });
            }
          }

          if (obj[k] !== null && typeof obj[k] === "object") {
            findKey({
              obj: obj[k],
              predicate,
              pathToKey: `${oldPath}${k}`,
            });
          }
        }
      }
    }
  })(options);

  return results;
}

export function updateObjectValues(obj, keys: string[], values: string[]) {
  if (keys.length !== values.length) {
    throw new Error("The lengths of keys and values arrays must be the same.");
  }

  keys.forEach((keyPath, index) => {
    const pathParts = keyPath.split(".");
    let currentPart = obj;

    // Iterate over the path parts except for the last part
    for (let i = 0; i < pathParts.length - 1; i++) {
      let part = pathParts[i];

      // If the part of the path does not exist, create an empty object
      if (!currentPart[part]) {
        currentPart[part] = {};
      }

      currentPart = currentPart[part];
    }

    // Set the new value at the final part of the path
    currentPart[pathParts[pathParts.length - 1]] = values[index];
  });

  return obj;
}
