import {
  COMMON_PATHS,
  findLayersOrLayerAssets,
  getPath,
  IInteractiveModOpen,
  modTypes,
} from "@blings/blings-player";
import { ModsPanelCompProps } from "../../ModsPanel";
import { DynamicValueMod, DynamicValueModTypes } from "../../DynamicValueMod";
import { AddInteractiveHOC } from "./InteractiveFormHOC";
import { Col, Input, Row } from "antd";
import MainCTAButton from "../../../MainCTA/MainCTAButton";
import { rootStore } from "../../../../stores/main";
import { ExperimentOptimizationTechnique } from "../../../../API";
import { InfoTooltip } from "../../../../BaseComps/InfoTooltip";
import { useEffect } from "react";

function renderActionForm(props: ModsPanelCompProps<IInteractiveModOpen>) {
  const hasVariantsEnabled =
    rootStore.experimentStore.selectedExperimentOptimizationTechnique &&
    rootStore.experimentStore.selectedExperimentOptimizationTechnique !==
      ExperimentOptimizationTechnique.DISABLED;
  return (
    <>
      <Row style={{ width: "100%" }}>
        <Col style={{ width: "100%" }}>
          <span>Name this button (Required for analytics)</span>
          <InfoTooltip
            style={{ marginLeft: "5px" }}
            info="This name is used to track how many viewers clicked on the button."
          />
          <div style={{ display: "flex", alignItems: "center", gap: "25px" }}>
            <Input
              size={"large"}
              width={"100%"}
              id="connector-cta-name"
              value={props.change.ctaName}
              addonBefore={"Name"}
              // eslint-disable-next-line no-template-curly-in-string
              placeholder="Use a clear & unique name"
              onChange={(e) =>
                props.onChange(props.index, ["ctaName"], [e.target.value])
              }
              allowClear={true}
            />
          </div>
        </Col>
      </Row>
      <DynamicValueMod
        change={props.change}
        onChange={(index, keys, values) => {
          return props.onChange(
            index,
            (keys as Array<keyof IInteractiveModOpen>).concat("type"),
            values.concat(modTypes.interactiveOpen)
          );
        }}
        index={props.index}
        type={DynamicValueModTypes.url}
      />
      {hasVariantsEnabled && (
        <MainCTAButton
          change={props.change}
          onChange={props.onChange}
          index={props.index}
        />
      )}
    </>
  );
}

export function AddInteractiveOpenForm(
  props: ModsPanelCompProps<IInteractiveModOpen>
) {
  const { change } = props;

  useEffect(() => {
    if (!change.ctaName) {
      const layers = findLayersOrLayerAssets({
        assetId: change.assetId,
        layerName: change.layerName,
        layerUid: change.layerUid,
        jsonVid: props.json,
        additionals: change.additionals,
      });
      const layer =
        layers?.length &&
        layers.find((layer) => getPath(layer, COMMON_PATHS.TEXT_IN_LAYER));
      if (layer) {
        const text = getPath(layer, COMMON_PATHS.TEXT_IN_LAYER);
        text && props.onChange(props.index, "ctaName", text);
      }
    }
  }, [props.change.layerName, props.change.additionals?.length]);
  return (
    <AddInteractiveHOC<IInteractiveModOpen>
      {...props}
      renderSpecificInteractive={renderActionForm}
    />
  );
}
