/**
 * Copy-pasted from dynamic-form.
 * Todo: share code in a proper way.
 */

import * as React from "react";
import reactCSS from "reactcss";
import { Popover } from "antd";
import { ChromePicker, ColorChangeHandler } from "react-color";
import { theme } from "../theme";

const defaultStyles: any = reactCSS({
  default: {
    color: {
      width: "0.8rem",
      height: "0.8rem",
      borderRadius: "0.1rem",
    },
    swatch: {
      padding: "0.5rem",
      background: "#fff",
      borderRadius: "50%",
      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
      display: "inline-block",
      cursor: "pointer",
      position: "relative",
      bottom: "0rem",
    },
  },
});

export function ColorPicker({
  color,
  onChange,
  size,
  clickeable = true,
}: {
  color: string;
  onChange: ColorChangeHandler | undefined;
  size?: number;
  clickeable?: boolean;
}) {
  const styles =
    size == null
      ? defaultStyles
      : reactCSS({
          default: {
            color: {
              width: 0.8 * size + "rem",
              height: 0.8 * size + "rem",
              borderRadius: "50%",
              border: "1px dashed #fff",
            },
            swatch: {
              padding: 0.1 * size + "rem",
              background: theme.secondary,
              borderRadius: 0.1 * size + "rem",
              boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
              display: "inline-block",
              cursor: "pointer",
              position: "relative",
              bottom: "0rem",
            },
          },
        });
  return (
    <div style={{ display: "flex", alignItems: "flex-start", gap: "1rem" }}>
      {clickeable ? (
        <Popover
          id="color-picker-popup"
          placement="bottom"
          content={
            <ChromePicker
              disableAlpha={true}
              color={color}
              onChange={onChange}
            />
          }
          trigger="click"
        >
          <div style={styles.swatch}>
            <div style={{ ...styles.color, background: color }}></div>
          </div>
        </Popover>
      ) : (
        <div style={styles.swatch}>
          <div style={{ ...styles.color, background: color }}></div>
        </div>
      )}
    </div>
  );
}
