import { Space, Typography } from "antd";
import { EazyLoader } from "./EazyLoader";
import ProjectsGallery from "./ProjectsGallery/ProjectsGallery";
type Props = {
  hasProjects: boolean;
  projectExists?: boolean;
};

export function NoProjectSelectedScreen({ hasProjects, projectExists }: Props) {
  const isOnHomeURI = window.location.pathname === "/";
  if (isOnHomeURI) {
    return (
      <Space
        style={{
          padding: "1rem",
          justifyContent: hasProjects ? "" : "center",
        }}
        align={hasProjects ? undefined : "center"}
        className={"NoProjectSelectedScreen"}
        direction="vertical"
      >
        {hasProjects ? (
          <ProjectsGallery />
        ) : (
          <>
            <Typography.Title level={3}>
              Start by creating a project in the platform
            </Typography.Title>
            <EazyLoader size={400} isLoop={false} />
          </>
        )}
      </Space>
    );
  } else {
    return (
      <Space
        style={{
          padding: "1rem",
          justifyContent: "center",
        }}
        align="center"
        className={"NoProjectSelectedScreen"}
        direction="vertical"
      >
        <div className="mt-8-center">
          {projectExists ? (
            <Typography.Title level={3}>
              Loading project data...
            </Typography.Title>
          ) : (
            <>
              <Typography.Title level={3}>
                Oops, we can't find what you are looking for
              </Typography.Title>
              <Typography.Title>
                <Typography.Link
                  href="mailto:support@blings.io"
                  style={{
                    color: "white",
                    borderBottom: "2px solid #fff",
                    paddingBottom: "2px",
                    fontSize: "30px",
                  }}
                >
                  Contact us
                </Typography.Link>
              </Typography.Title>
            </>
          )}
          <EazyLoader size={400} isLoop={false} />
        </div>
      </Space>
    );
  }
}
