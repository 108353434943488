import { findPathsToKey } from "../helpers/JsonSearch";

export const getAllColors = (json) => {
  const allColors = findPathsToKey(
    json,
    (v) => Array.isArray(v) && v.length === 4 && !isNaN(v[0])
  );
  const allFontColors = findPathsToKey(
    json,
    (v, p) =>
      Array.isArray(v) && v.length === 3 && !isNaN(v[0]) && p.endsWith("fc")
  );
  const vals = new Set(
    [...allColors, ...allFontColors].map((pair) =>
      JSON.stringify(
        pair[1]
        // .map((n: number) => parseFloat(n.toFixed(sensitivity)))
      )
    )
  ); // .map(v => JSON.parse(v));
  const uniqueColors: ColorArr[] = Array.from(vals).map((v) => JSON.parse(v));
  return uniqueColors.sort(([r1, g1, b1], [r2, g2, b2]) => {
    const rs = r1 - r2;
    const gs = g1 - g2;
    const bs = b1 - b2;
    // if(r1 > g1 ) return rs || gs || bs
    // else
    return gs || rs || bs;
  });
};
