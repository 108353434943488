import "./SimpleLoader.scss";
export const SimpleLoader = () => {
  return (
    <div className="before-init" id="demo-loader">
      <div className="spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
      </div>
    </div>
  );
};
