import {
  IFlowDiagram,
  IJSONObject,
  ILineupEntryPointReference,
  LineupManager,
  Player,
} from "@blings/blings-player";
import { getENV } from "../../stores/consts";
import PlayerManager from "../../helpers/playerManager";
import { ISdkParams } from "@blings/blings-player/lib/src/SDK/sdk.api";
import { fillProjectVideoParts } from "../DemoWindow/DemoWindowHelpers";
import { ProjectVersion } from "../../API";
import { IVideoPart } from "@blings/blings-player/lib/src/fetchData/getVideoParts";

let activeRequests: any[] = [];
let counter = 0;
let mutex = Promise.resolve();

export const flowPlayerWrapper = async (
  setLoaderDisplay: (display: boolean) => void,
  scenes: Array<ILineupEntryPointReference>,
  flowDiagram: IFlowDiagram,
  project: ProjectVersion,
  liveControlData: IJSONObject,
  data: IJSONObject,
  autoplay: boolean,
  posterFrame: number,
  containerElement: HTMLElement | null
) => {
  await mutex;

  setLoaderDisplay(true);
  if (!containerElement) {
    return;
  }
  let currentCounter = 0;
  mutex = (async () => {
    counter++;
    currentCounter = counter;
    activeRequests.push({ id: currentCounter, player: null });

    if (scenes.length) {
      try {
        if (activeRequests.length > 0) {
          const tempCounter = counter;
          for (let i = activeRequests.length - 1; i >= 0; i--) {
            if (activeRequests[i].id !== tempCounter) {
              if (activeRequests[i]?.player) {
                await activeRequests[i]?.player?.destroy();
                if (activeRequests[i]?.player) {
                  activeRequests[i].player = null;
                }
              }
            }
          }
        }
        activeRequests = activeRequests.filter((r) => r.id === currentCounter);
        // if counter is NOT the same as currentCounter, it means that a new request has been made, so we should not create the player.
        if (currentCounter === counter) {
          const playerParams: Partial<ISdkParams> = {
            data: data,
            settings: {
              container: containerElement,
              autoplay: autoplay,
              posterFrame,
              maxLoadingTime: 10000,
              avoidErrorTracking: true,
            },
            scenes,
            flowDiagram,
          };
          const uniqueScenes = new Set(
            LineupManager.getUniqueScenes(scenes, flowDiagram)
          );
          const filledVideoParts = project?.videoParts
            ? await fillProjectVideoParts(
                project.videoParts as IVideoPart[],
                Array.from(uniqueScenes)
              )
            : [];

          // We need to check for the already existing player version in use.
          let playerVersion: any = PlayerManager.get().GetCurrentMajorVersion();
          if (playerVersion === "3") {
            playerParams.project = {
              videoParts: filledVideoParts,
              liveControlData,
            };
          } else {
            playerParams.project = {
              env: getENV(),
              id: project.id,
              videoParts: filledVideoParts,
              liveControlData,
            };
            // If the player version is setted to latest, we should use the latest player version.
            playerVersion = -1;
          }

          let player: Player = await PlayerManager.get().createPlayer(
            playerParams as ISdkParams,
            { playerMajorVersion: playerVersion, newPlayer: true }
          );

          const currentRequest = activeRequests.find((r) => r.id === counter);
          if (currentRequest) {
            currentRequest.player = player;
            (window as any).dp = player;
          } else {
            activeRequests.push({ id: currentCounter, player });
            (window as any).dp = player;
          }
        }

        setLoaderDisplay(false);
        mutex = Promise.resolve();
      } catch (e) {
        console.error("err", e);
      } finally {
        mutex = Promise.resolve();
      }
    }
    mutex = Promise.resolve();
  })();
};
