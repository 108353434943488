import { CopyFilled } from "@ant-design/icons";
import { notification, Space, Tooltip } from "antd";
import { theme } from "../../theme";
import "./DemoWindow.scss";
import { EXAMPLE_PLAYER_LINK, getENV } from "../../stores/consts";
import { ReactComponent as exportIcon } from "../../icons-and-animations/demo-icons/export.svg";
import Icon from "@ant-design/icons";
import PlayerManager from "../../helpers/playerManager";

export function DemoSdkExternalLink({ project, scenes, data }) {
  let url = `${EXAMPLE_PLAYER_LINK}?env=${getENV()}&p=${
    project.id
  }&draftVersion=latest`;
  if (scenes) {
    url += `&scenes=${encodeURIComponent(scenes.join())}`;
  }
  if (data) {
    url += `&data=${encodeURIComponent(JSON.stringify(data))}`;
  }
  const version = PlayerManager.get().GetCurrentPlayerVersion();
  if (version && version !== "WARN_NO_PLAYER_VERSION") {
    url += `&v=${version}`;
  }
  return (
    <div>
      <Space>
        <Tooltip
          title={"go to DemoPlayer"}
          arrow={{ pointAtCenter: true }}
          color={theme.blings_purple}
        >
          <Icon
            component={exportIcon}
            className="icons"
            style={{ color: "#A9B6C3" }}
            onClick={() => window.open(url)}
          />
        </Tooltip>
        <Tooltip
          title={"Copy to clipboard"}
          arrow={{ pointAtCenter: true }}
          color={theme.blings_purple}
        >
          <CopyFilled
            className="icons"
            style={{ color: "#A9B6C3" }}
            onClick={() => {
              navigator.clipboard.writeText(url);
              notification.open({
                message: "Copied to clipboard",
                duration: 2,
              });
            }}
          />
        </Tooltip>
      </Space>
    </div>
  );
}
