import * as React from "react";
import { observer } from "mobx-react-lite";
import { useMst } from "../../stores/main";
import { modsDefinitions } from "./mod-definitions";
import { Collapse, Space } from "antd";
import { useEffect, useRef } from "react";
import { PanelHeader } from "./ModsListPanelHeader";
import "./ModsList.scss";
import "./modForms/ModForm.scss";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { IModModel } from "../../stores/ModsStore";

const { Panel } = Collapse;

const SortableModItem = observer(
  ({
    mod,
    index,
    handleCollapse,
    ...props
  }: {
    mod: IModModel;
    index: number;
    handleCollapse: (key: any) => void;
  }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id: mod.id.toString() });
    const {
      modsStore: {
        updateValue,
        removeMod,
        copyMod,
        modsWithMultipleDynamicLength,
        modsWithDataErrors,
        modsWithMissingProperties,
        modsWithBrokenLayers,
        activeModId,
        updateInnerModValue,
        hasMultiConnectedLayers,
        mods,
      },
      experimentStore: { deleteExperimentById },
      playerStore: { allColors, assetNames, assetLayerPairs, jsonFile },
    } = useMst();

    const { id, moddata, name } = mod;
    const selectedMod = activeModId;
    const hasMultiConnectedLayer = hasMultiConnectedLayers(id);
    const hasMissingProperty = modsWithMissingProperties[id] != null;
    const hasDataErrors = modsWithDataErrors[id] != null;
    const hasMultipleDynamicLength = modsWithMultipleDynamicLength[id] != null;
    const hasBrokenLayers = modsWithBrokenLayers[id] != null;
    if (!modsDefinitions[moddata.type]) {
      return null;
    }
    const styleTransform = {
      transform: `translate3d(0, ${transform?.y ?? 0}px, 0)`,
      transition,
      zIndex: isDragging ? 999 : 0,
    };
    const { ChangeFormComp, formTitle, formTitle2, color, text } =
      modsDefinitions[moddata.type] || {};

    if (!ChangeFormComp) return null;

    return (
      <Panel
        {...props}
        ref={setNodeRef}
        {...attributes}
        id={`connector-${index.toString()}`}
        className={`ModForm ${moddata.isDisabled ? "disabled" : ""} ${
          moddata.type
        }`}
        showArrow={false}
        key={id.toString()}
        style={{
          position: "relative",
          ...styleTransform,
        }}
        header={
          <PanelHeader
            listeners={listeners}
            onChange={(i, k, v) => {
              updateValue(id, k, v);
            }}
            color={color}
            handleRemove={() => {
              if (mod.moddata.experimentId)
                deleteExperimentById(mod.moddata.experimentId);
              removeMod(mod);
            }}
            handleCopy={() => {
              copyMod(mod);
            }}
            formTitleText={formTitle(moddata)}
            formTitleText2={formTitle2 && formTitle2(moddata)}
            modTitle={text.toUpperCase()}
            modName={name}
            hasMultiConnectedLayer={hasMultiConnectedLayer}
            hasMissingProperties={hasMissingProperty}
            hasDataErrors={hasDataErrors}
            modId={id.toString()}
            hasBrokenLayers={hasBrokenLayers}
            type={moddata.type}
            isDisabled={moddata.isDisabled}
            setConnectorDisabledState={(state) => {
              updateValue(id, "isDisabled", state);
            }}
            setActiveMod={handleCollapse}
            isActiveMod={selectedMod === id.toString()}
            hasMultipleDynamicLength={hasMultipleDynamicLength}
          />
        }
      >
        <ChangeFormComp
          json={jsonFile}
          index={id}
          change={moddata}
          config={mods}
          assetNames={assetNames}
          allColors={allColors}
          assetLayerPairs={assetLayerPairs}
          onInnerModChange={(i, k, v) => {
            updateInnerModValue(id, k, v);
          }}
          onChange={(i, k, v) => {
            updateValue(id, k, v);
          }}
          onLayerChange={(i, assetId, layerName, layerUid, additionals) => {
            updateValue(
              id,
              ["assetId", "layerName", "layerUid", "additionals"],
              [assetId, layerName, layerUid, additionals]
            );
          }}
          handleChange={() => console.log("this.props.handleChange")}
        />
      </Panel>
    );
  }
);

export const ModsList = observer(() => {
  const {
    modsStore: {
      mods,
      activeModId,
      setActiveMod,
      addCbAfterAddingMod,
      snapshotMods,
      reorderMods,
    },
    experimentStore: { deleteExperimentById },
    playerStore: { allColors, assetNames, assetLayerPairs, jsonFile },
  } = useMst();
  const reset = () => {
    snapshotMods();
  };

  const collapseScrollContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    addCbAfterAddingMod((id) => {
      setTimeout(() => {
        try {
          collapseScrollContainerRef?.current?.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } catch (e) {}
      }, 80);
    });
  }, [addCbAfterAddingMod]);

  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const modsArray = [...mods];
      const activeIndex = modsArray.findIndex(
        (mod) => mod.id.toString() === active.id
      );
      const overIndex = modsArray.findIndex(
        (mod) => mod.id.toString() === over.id
      );
      const [reorderedMod] = modsArray.splice(activeIndex, 1);
      modsArray.splice(overIndex, 0, reorderedMod);
      reorderMods(modsArray);
    }
  };
  const handleCollapseChange = (key: string | string[]) => {
    if (typeof key === "string" || key === undefined) {
      setActiveMod(key);
    }
  };

  return (
    <ErrorBoundary resetFunc={reset}>
      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <SortableContext
          items={mods.map((mod) => mod.id.toString())}
          strategy={verticalListSortingStrategy}
        >
          <Space
            direction="vertical"
            style={{ overflow: "hidden" }}
            className="ModsList-container"
          >
            <div
              id="connectors-list"
              className="modsList-collapse-container"
              ref={collapseScrollContainerRef}
            >
              <Collapse
                className="ModsList"
                accordion={true}
                activeKey={activeModId}
                onChange={handleCollapseChange}
              >
                {mods.map((mod, index) => (
                  <SortableModItem
                    handleCollapse={handleCollapseChange}
                    key={mod.id.toString()}
                    mod={mod}
                    index={index}
                  />
                ))}
              </Collapse>
            </div>
          </Space>
        </SortableContext>
      </DndContext>
    </ErrorBoundary>
  );
});
