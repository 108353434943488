/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "BlingsAPI",
            "endpoint": "https://0drw0iff9c.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        },
        {
            "name": "addPubSubPermission",
            "endpoint": "https://pcm7xrt478.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        },
        {
            "name": "extension",
            "endpoint": "https://58hoje6tse.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        },
        {
            "name": "platformRest",
            "endpoint": "https://pu7lb6jn52.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        },
        {
            "name": "projectsRestaApi",
            "endpoint": "https://8nm0xcfgh9.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        },
        {
            "name": "uploadCsvFile",
            "endpoint": "https://0x4jyzc64j.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://vgfoio36bnh4pkqfp64jqobkj4.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-l5iow5nivra4fhj3yfkkivrnsu",
    "aws_cognito_identity_pool_id": "eu-west-1:8686c507-ef8e-43b2-8b6f-b22c3e0e0b64",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_qSR71Db8a",
    "aws_user_pools_web_client_id": "9495i5eg9td3kjkop4rkvn119",
    "oauth": {
        "domain": "platform480c79fd7-80c79fd7-master.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://platform.blings.io/auth/sign-in/,https://studio.blings.io/auth/sign-in/",
        "redirectSignOut": "https://platform.blings.io/auth/sign-out/,https://studio.blings.io/auth/sign-out/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "platform4-hostingbucket-dev-master",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://platform4-hostingbucket-dev-master.s3-website-eu-west-1.amazonaws.com",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Config-master",
            "region": "eu-west-1"
        },
        {
            "tableName": "SourceDataRecords-master",
            "region": "eu-west-1"
        },
        {
            "tableName": "Versions-master",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "platform-storage-bucket182631-master",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
