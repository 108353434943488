import { Popconfirm, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { shortenTextAEStyle } from "../../helpers/text";
import { useMst } from "../../stores/main";
import { modTypes } from "@blings/blings-player";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import {
  ConnectorIcon,
  DeleteIcon,
  DuplicateIcon,
  EditPenIcon,
  EyeIcon,
  EyeIconInvisible,
  ThreeDotsDraggable,
  WarningIcon,
} from "../../icons-and-animations/Icons";
export function PanelHeader({
  type,
  handleRemove,
  formTitleText,
  formTitleText2,
  color,
  handleCopy,
  modTitle,
  modName,
  hasMultiConnectedLayer,
  hasDataErrors,
  hasBrokenLayers,
  hasMissingProperties,
  modId,
  hasMultipleDynamicLength,
  isDisabled,
  setConnectorDisabledState,
  setActiveMod,
  isActiveMod,
  onChange,
  listeners,
}: {
  color: string;
  handleRemove;
  type: modTypes;
  onChange: (i: any, k: any, v: any) => void;
  handleCopy;
  formTitleText: string;
  formTitleText2?: [string, (a: any) => JSX.Element] | undefined;
  modTitle: string;
  modName: string | null;
  hasMultiConnectedLayer: boolean;
  hasDataErrors: boolean;
  hasBrokenLayers: boolean;
  hasMissingProperties: boolean;
  modId: string;
  hasMultipleDynamicLength: boolean;
  isDisabled: boolean;
  setConnectorDisabledState: (state: boolean) => void;
  setActiveMod: (modId: string | number | undefined) => void;
  isActiveMod: boolean;
  listeners?: SyntheticListenerMap | undefined;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [clickTimeoutId, setClickTimeoutId] = useState<any>(null);
  const currentModName = modName || "";
  const { modsStore } = useMst();

  function isInteractiveMod(m: modTypes) {
    return (
      m === "interactiveOpen" ||
      m === "interactiveInput" ||
      m === "interactiveGoto" ||
      m === "interactiveConcat" ||
      m === "interactiveJSExpression" ||
      m === "onPlayerEvent" ||
      m === "interactiveForm"
    );
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      changeModName(modId, event.target.value);
      setIsEditing(false);
    }
  };

  useEffect(() => {
    return () => {
      if (clickTimeoutId) clearTimeout(clickTimeoutId);
    };
  }, [clickTimeoutId]);

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (clickTimeoutId !== null) {
      clearTimeout(clickTimeoutId);
      setClickTimeoutId(null);
      handleDoubleClick(event);
    } else {
      const timeoutId = setTimeout(() => {
        setClickTimeoutId(null);
        if (isActiveMod) setActiveMod(undefined);
        else setActiveMod(modId);
      }, 200);
      setClickTimeoutId(timeoutId);
    }
  };

  const handleDoubleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsEditing(true);
  };

  const handleBlur = (e) => {
    setIsEditing(false);
    if (e.target.value === modName) return;
    if (e.target.value === "") changeModName(modId, undefined);
    else changeModName(modId, e.target.value);
  };

  const changeModName = (modId, modName) => {
    if (isInteractiveMod(type)) {
      onChange(modId, ["ctaName"], [modName]);
    } else {
      modsStore.updateModName(modId, modName);
    }
  };

  const hasErrors =
    hasMultiConnectedLayer ||
    hasDataErrors ||
    hasBrokenLayers ||
    hasMultipleDynamicLength ||
    hasMissingProperties;
  const divider = (
    <div style={{ display: "flex", marginLeft: "0.5rem" }}>
      <svg
        width="1"
        height="17"
        viewBox="0 0 1 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.75"
          y="0.25"
          width="16.5"
          height="0.5"
          transform="rotate(90 0.75 0.25)"
          stroke="#424242"
          strokeWidth="0.5"
        />
      </svg>
    </div>
  );

  const TitleTexts = () => {
    if (formTitleText2) {
      return (
        <>
          {isEditing ? (
            <div style={{ marginLeft: "0.15rem", width: "80%" }}>
              <input
                type="text"
                defaultValue={currentModName}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                autoFocus
                className={"modEditConnectorName"}
                placeholder="Add Connector Name"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              />
              <div
                className={"modPanelText mr5"}
                style={{ flex: 0.8, fontSize: "8px" }}
                title={formTitleText}
              >
                {shortenTextAEStyle(formTitleText, 20)}
              </div>
            </div>
          ) : modName ? (
            <div
              style={{
                marginLeft: "0.3rem",
                display: "flex",
                alignItems: "center",
              }}
              className="pencilEditName"
            >
              <div style={{ maxWidth: "80%", marginRight: "0.5rem" }}>
                <div
                  className={"modPanelText"}
                  style={{
                    flex: 0.8,
                    fontSize: "12px",
                    display: "flex",
                    gap: "0.5rem",
                  }}
                  title={currentModName}
                >
                  {currentModName}
                </div>
                <div
                  className={"modPanelText"}
                  style={{ flex: 0.8, fontSize: "8px" }}
                  title={formTitleText}
                >
                  {shortenTextAEStyle(formTitleText, 20)}
                </div>
              </div>
              <div
                style={{ display: isHovered ? "block" : "none", width: "20%" }}
                onClick={(e) => handleDoubleClick(e)}
                className="modIcon editNameIcon"
              >
                <EditPenIcon />
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", marginLeft: "0.3rem" }}>
              <div
                className={"modPanelText mr5"}
                style={{
                  display: "flex",
                  maxWidth: "fit-content",
                  gap: "0.5rem",
                }}
                title={formTitleText}
              >
                {shortenTextAEStyle(formTitleText, 20)}
              </div>
              <div
                className="pencilEditName"
                style={{ width: "1rem", height: "1rem" }}
              >
                <div
                  style={{ display: isHovered ? "block" : "none" }}
                  onClick={(e) => handleDoubleClick(e)}
                  className="modIcon editNameIcon"
                >
                  <EditPenIcon />
                </div>
              </div>
            </div>
          )}
        </>
      );
    } else
      return (
        <div style={{ display: "flex", marginLeft: "0.3rem" }}>
          <div
            className={"modPanelText mr5"}
            style={{
              display: "flex",
              maxWidth: "fit-content",
              gap: "0.5rem",
            }}
            title={formTitleText}
          >
            {shortenTextAEStyle(formTitleText, 20)}
          </div>
        </div>
      );
  };

  return (
    <div
      className={"ModsListPanelHeader"}
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
      }}
      onClick={handleClick}
      onMouseEnter={() => {
        !isEditing && setIsHovered(true);
      }}
      onMouseLeave={() => {
        !isEditing && setIsHovered(false);
      }}
    >
      <div
        className={[
          "draggable-superset-icon",
          isActiveMod ? "isActive" : "",
        ].join(" ")}
        {...listeners}
      >
        <ThreeDotsDraggable className="draggable-icon" />
      </div>
      <div className="modIconTitleWrapper">
        <Tooltip
          overlay={
            modTitle +
            " CONNECTOR" +
            (hasMultiConnectedLayer ? " (DUPLICATED LAYER)" : "") +
            (hasDataErrors ? " (DATA ERROR)" : "") +
            (hasBrokenLayers ? " (BROKEN LAYER)" : "") +
            (hasMultipleDynamicLength ? " (MULTIPLE DYNAMIC LENGTH)" : "") +
            (hasMissingProperties ? " (MISSING PROPERTIES)" : "")
          }
          placement={"leftTop"}
          overlayStyle={{ fontSize: "65%" }}
        >
          {hasErrors ? (
            <div className="panel-icon warning-icon">
              <WarningIcon />
            </div>
          ) : (
            <div
              className="mod-icon-new"
              title={modTitle}
              // style={{ backgroundColor: color }}
            >
              <ConnectorIcon type={type} />
              {/*<Icon style={{ color: "#fff" }} />*/}
            </div>
          )}
        </Tooltip>
      </div>
      <div className="title-texts">
        <TitleTexts />
      </div>

      <div className={"modPanelBtns"}>
        {
          <div
            className={"modPanelText2 mod-main-content"}
            title={
              typeof formTitleText2?.[0] === "string"
                ? formTitleText2[0]
                : undefined
            }
          >
            <span id="header-text-color">{formTitleText2?.[0]}</span>
            {formTitleText2?.[1]}
          </div>
        }
        <div className="extra-buttons">
          {divider}
          <div
            className={"modPanelBtn modPanelBtn_disable"}
            onClick={(event) => {
              event.stopPropagation();
              setConnectorDisabledState(!isDisabled);
            }}
            title={"Disable Connector"}
          >
            {isDisabled ? <EyeIconInvisible /> : <EyeIcon />}
          </div>

          <div
            className={"modPanelBtn modPanelBtn_copy"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleCopy();
            }}
            title={"Copy Connector"}
          >
            <DuplicateIcon />
          </div>
          <Popconfirm
            title='Delete Connector? (nothing is saved till you press "Save Connectors")'
            onConfirm={(event) => {
              event?.stopPropagation();
              event?.preventDefault();
              handleRemove();
            }}
            onCancel={(event) => {
              event?.stopPropagation();
              event?.preventDefault();
            }}
            okText="Yes"
            cancelText="No"
          >
            <div
              className={"modPanelBtn modPanelBtn_remove"}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
              title={"Delete Connector"}
            >
              <DeleteIcon />
            </div>
          </Popconfirm>
        </div>
      </div>
    </div>
  );
}
