import { useContext, useCallback } from "react";
import "./TopBar.scss";
import { Layout } from "antd";
import logo from "../../assets/logo.svg";
import { ConnectToProject } from "./ConnectToProject";
import { BurgerMenu } from "./BurgerMenu";
import { Link } from "react-router-dom";
import { PublishStatus } from "./PublishStatus";
import { PublishButtons } from "./PublishButtons";
import { MSTContext } from "../../stores/main";
import { observer } from "mobx-react-lite";
import GroupButtons from "../GroupButtons/GroupButtons";
import { tabsOptions } from "../../stores/consts";
import { useNavigationPrompt } from "../../utils/navigateWithPrompt";

const { Header } = Layout;

type Props = {};

export const TopBar = observer((props: Props) => {
  const {
    platformStore: { selectedProjectId, allProjects },
    currentTab,
    setCurrentTab,
    accountStore,
  } = useContext(MSTContext);
  const selectedProjectExists = allProjects.find(
    (project) => project.id === selectedProjectId
  );

  // handle initial tab selection when project is selected
  // will remove this when we have a proper routing solution
  if (selectedProjectId && !currentTab) {
    setCurrentTab("layers");
  }

  const hasTabs = accountStore.isBlingsAccount || false;

  const navigateWithPrompt = useNavigationPrompt();
  const onTabChange = useCallback(
    async (tab: any) => {
      const route = `/${selectedProjectId}/${tab}/`;
      await navigateWithPrompt(route, () => setCurrentTab(tab));
      return false;
    },
    [setCurrentTab, selectedProjectId, navigateWithPrompt]
  );

  return (
    <Header className="playground-top-bar">
      <div className="left">
        <Link
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          to={"/"}
        >
          <div style={{ width: "10rem", display: "flex" }}>
            <img
              style={{ width: "94px", height: "28px", marginLeft: "2px" }}
              src={logo}
              alt="blings-playground-logo"
            />
          </div>
        </Link>
      </div>
      <div className="center">
        <ConnectToProject />
      </div>
      {selectedProjectId && hasTabs ? (
        <div className="tabs">
          <GroupButtons
            isLoading={false}
            items={tabsOptions}
            initialActive={currentTab}
            onChange={onTabChange}
          />
        </div>
      ) : null}
      <div className="right">
        {selectedProjectExists ? <PublishStatus /> : null}
        {selectedProjectExists ? <PublishButtons /> : null}
        <BurgerMenu />
      </div>
    </Header>
  );
});
