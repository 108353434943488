import config from "../aws-exports";

export const info_data = {
  contactMail: "contact@blings.io",
  support: "support@blings.io",
};

export const getENV = () => {
  return config.aws_cloud_logic_custom[0].endpoint.split("/").slice(-1)[0] as
    | "dev"
    | "staging"
    | "master";
};

export const CDN = {
  dev: "https://dh2jw0564x0cd.cloudfront.net",
  staging: "https://d25az89v0z0rdg.cloudfront.net",
  master: "https://storage.blings.io",
};
export const BLINGS_API = {
  dev: "https://n5i659t7zj.execute-api.eu-west-1.amazonaws.com/dev/v2/public",
  staging: "https://o1d26b9xt3.execute-api.eu-west-1.amazonaws.com/staging",
  master: "https://api.blings.io/v2/public",
};
export const GET_PLAYER_SETTINGS_URL = {
  dev:
    "https://n5i659t7zj.execute-api.eu-west-1.amazonaws.com/dev/v2/public/project",
  master: "https://api.blings.io/v2/public/project",
  staging:
    "https://csjxoutuob.execute-api.eu-west-1.amazonaws.com/staging/projects",
}[getENV()];
export const DEFAULT_MAX_LAYER_CHARS = 20;

export const EXAMPLE_PLAYER_LINK = "https://assets.blings.io/player/index.html";

export const MICROSERVICE_API_URLS = {
  font: {
    dev:
      "https://5ptkfkesj2.execute-api.eu-west-1.amazonaws.com/dev/v1/public/convert/font",
    master:
      "https://2bzp4hwfqj.execute-api.eu-west-1.amazonaws.com/master/v1/public/convert/font",
  }[getENV()] as string,
  image: {
    dev:
      "https://tee1st7f3d.execute-api.eu-west-1.amazonaws.com/dev/v1/private/convert/image",
    master:
      "https://xtt82dfuj8.execute-api.eu-west-1.amazonaws.com/master/v1/private/convert/image",
  }[getENV()] as string,
};

export const TABS = {
  LAYERS: "layers",
  FLOW: "flow",
  THUMB: "thumb",
};

export const tabsOptions: {
  title: string;
  value: string;
  icon: string;
  path: string;
}[] = [
  {
    title: "Layer Modifier",
    value: TABS.LAYERS,
    icon: "LayersIcon",
    path: "layers",
  },
  { title: "Flow builder", value: TABS.FLOW, icon: "FlowIcon", path: "flow" },
  // {
  //   title: "Thumb editor",
  //   value: TABS.THUMB,
  //   icon: "ImageIcon",
  //   path: "thumb",
  // },
];
