import { NoProjectSelectedScreen } from "./NoProjectSelectedScreen";
import { Layout } from "antd";
import { SideBar } from "./playground-left-sidebar/SideBar";
import { VidContainer } from "./MainVidPlayer/VidContainer";
import { SchemaEditors } from "./RightPanel/SchemaEditors";
import { useContext } from "react";
import { MSTContext } from "../stores/main";
import { observer } from "mobx-react-lite";
import FlowPage from "../pages/flow/FlowPage";

type Props = {};
const { Content } = Layout;

export const MainLayout = observer((props: Props) => {
  const {
    platformStore: {
      selectedProjectId,
      allProjects,
      initialProjectLoadSuccess,
    },
    currentTab,
  } = useContext(MSTContext);
  const selectedProjectExists = allProjects.find(
    (project) => project.id === selectedProjectId
  );

  const getCurrentView = () => {
    switch (currentTab) {
      case "layers":
        return (
          <>
            <SideBar />
            <Content>
              <VidContainer />
              <SchemaEditors />
            </Content>
          </>
        );
      case "flow":
        return (
          <Content>
            <FlowPage />
          </Content>
        );
      case "thumb":
        return <SchemaEditors />;
      default:
        return (
          <>
            <SideBar />
            <Content>
              <VidContainer />
              <SchemaEditors />
            </Content>
          </>
        );
    }
  };

  return !selectedProjectId ? (
    <NoProjectSelectedScreen
      hasProjects={!initialProjectLoadSuccess || !!allProjects.length}
      projectExists={true}
    />
  ) : selectedProjectExists !== undefined ? (
    <Layout className={"main"}>{getCurrentView()}</Layout>
  ) : (
    <NoProjectSelectedScreen
      hasProjects={!initialProjectLoadSuccess || !!allProjects.length}
      projectExists={selectedProjectExists !== undefined}
    />
  );
});
