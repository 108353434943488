import GenerateSchema from "generate-schema";

export function jsonSchemaGetExamples(jsonSchema) {
  if (jsonSchema.examples && jsonSchema.examples.length) {
    return jsonSchema.examples[0] || {};
  }

  if (jsonSchema.properties) {
    const examples = {};
    for (const key in jsonSchema.properties) {
      if (!jsonSchema.properties.hasOwnProperty(key)) {
        return;
      }
      if (
        jsonSchema.properties[key].examples &&
        jsonSchema.properties[key].examples.length
      ) {
        examples[key] = jsonSchema.properties[key].examples[0] || {};
      } else if (jsonSchema.properties[key].default) {
        examples[key] = jsonSchema.properties[key].default;
      } else if (jsonSchema.properties[key].type) {
        switch (jsonSchema.properties[key].type) {
          case "string": {
            examples[key] = "string";
            break;
          }
          case "boolean": {
            examples[key] = true;
            break;
          }
          case "number": {
            examples[key] = 1;
            break;
          }
          case "array": {
            examples[key] = 1;
            break;
          }
          default: {
            examples[key] = "";
            break;
          }
        }
      } else {
        examples[key] = "";
      }
    }
    return examples;
  }

  return {};
}

export function createSchemaFromData(data: any) {
  const schema = GenerateSchema.json(data);
  schema.examples = [data];
  schema.$schema = "http://json-schema.org/draft-07/schema";
  return schema;
}
export const emptySchema = {
  $schema: "http://json-schema.org/draft-07/schema",
  $id: "http://example.com/example.json",
  type: "object",
  title: "",
  description: "",
  default: {},
  examples: [],
  required: [],
  properties: {},
  additionalProperties: true,
};
