import "./MainCTAButton.scss";
import { observer } from "mobx-react-lite";
import { Switch } from "antd";
import {
  IInteractiveMod,
  IInteractiveModJSExpression,
} from "@blings/blings-player";

interface MainCTAButtonProps {
  index: number;
  change: IInteractiveModJSExpression | IInteractiveMod<any, any>;
  onChange: any;
}

function MainCTAButton(props: MainCTAButtonProps) {
  const { change, onChange } = props;
  return (
    <div className={`main-cta-button ${change.isMainCta ? "is-main-cta" : ""}`}>
      <Switch
        className="main-cta-switch"
        checked={change.isMainCta}
        onChange={() => {
          onChange(
            props.index,
            ["isMainCta"],
            [change.isMainCta ? false : true]
          );
        }}
      />
      <div className="cta-text">
        <span>Set as CTA</span>
      </div>
    </div>
  );
}

export default observer(MainCTAButton);
