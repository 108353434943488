import {
  findLayersOrLayerAssets,
  findAsset,
  getPath,
  LayerType,
} from "@blings/blings-player";
import { findPathsToKey } from "../../../../helpers/JsonSearch";
import { HEX_START, PRECOMP, SHAPE, SOLID, TEXT, TYPE } from "./consts";
import tinycolor from "tinycolor2";
import {
  ImageLayer,
  PrecompositionLayer,
  VideoLayer,
} from "@blings/blings-player/lib/src/libs/l/types/layers";

export function darkTextColorIfBGisBright(color: any) {
  const colorBrightness = tinycolor(color).getBrightness();
  return colorBrightness > 130 ? "#000" : "";
}

function hasColor(type: number) {
  return type === SOLID || type === SHAPE || type === TEXT || type === PRECOMP;
}

export function getLayersWithColorArray(
  p,
  layerName,
  assetId,
  layerUid,
  additionals,
  json
) {
  const layers = findLayersOrLayerAssets({
    assetId: assetId === "main" ? undefined : assetId,
    layerUid,
    layerName,
    jsonVid: json,
    additionals,
  });
  const type = layers?.length && getPath(layers[0], TYPE);
  if ((!!type || type === PRECOMP) && hasColor(type)) {
    p.push({
      renderText: `${layerName} - ${assetId}`,
      layerName,
      layerUid,
      parent: assetId === "main" ? layerName : assetId,
      child: assetId === "main" ? undefined : layerName,
    });
  }
  return p;
}
function getRelevantLayers(json, change) {
  let layers =
    findLayersOrLayerAssets({
      jsonVid: json,
      assetId: change.assetId === "main" ? undefined : change.assetId,
      layerName: change.layerName,
      layerUid: change.layerUid,
    }) || [];
  const additionals = change.additionals;
  if (additionals)
    additionals.forEach((additional) => {
      layers = [
        ...layers,
        ...(findLayersOrLayerAssets({
          jsonVid: json,
          assetId:
            additional.assetId === "main" ? undefined : additional.assetId,
          layerName: additional.layerName,
          layerUid: additional.layerUid,
        }) || []),
      ];
    });
  return layers;
}

function getGradientPartsColors(relevantGardientArrays: [string, number[]][]) {
  const allPartOfGardient: any = [];
  relevantGardientArrays.forEach((gradient) => {
    const gradientValues = gradient[1];
    for (let p = 1; p < gradientValues.length && p < 12; p += 4) {
      const base = [...gradientValues];
      base.push(p);
      allPartOfGardient.push(base);
    }
  });
  return allPartOfGardient;
}
export function getRelevantLayerColors(json: any) {
  const relevantColors = findPathsToKey(
    json,
    (v) => Array.isArray(v) && v.length === 4 && !isNaN(v[0])
  );
  const relevantGardientColors = getGradientPartsColors(
    findPathsToKey(
      json,
      (v, p) =>
        Array.isArray(v) &&
        v.length >= 12 &&
        !isNaN(v[0]) &&
        v.length % 4 === 0 &&
        (p.endsWith("k") || p.endsWith("s"))
    )
  );
  const relevantFontColors = findPathsToKey(
    json,
    (v, p) =>
      Array.isArray(v) && v.length === 3 && !isNaN(v[0]) && p.endsWith("fc")
  );
  const relevantSolidColors = findPathsToKey(
    json,
    (v) => typeof v === "string" && isValidHex(v) && v.startsWith(HEX_START)
  );
  const vals = new Set(
    [
      ...relevantColors,
      ...relevantFontColors,
      ...relevantSolidColors,
    ].map((pair) => JSON.stringify(pair[1]))
  );
  const uniqueColors: ColorArr[] = Array.from(vals)
    .map((v) => JSON.parse(v))
    .concat(relevantGardientColors);

  return uniqueColors.sort(([r1, g1, b1], [r2, g2, b2]) => {
    const rs = r1 - r2;
    const gs = g1 - g2;
    const bs = b1 - b2;
    return gs || rs || bs;
  });
}

export function getRelevantLayersColors(json, change) {
  let relevantLayersColors: ColorArr[] = [];
  getRelevantLayers(json, change).forEach((layer) => {
    if (
      layer &&
      (layer.ty === LayerType.video ||
        layer.ty === LayerType.still ||
        layer.ty === LayerType.precomp)
    ) {
      const layerWithRef = layer as
        | VideoLayer
        | PrecompositionLayer
        | ImageLayer;
      relevantLayersColors = [
        ...relevantLayersColors,
        ...getRelevantLayerColors(findAsset(json, layerWithRef.refId)),
      ];
    }
    relevantLayersColors = [
      ...relevantLayersColors,
      ...getRelevantLayerColors(layer),
    ];
  });
  return relevantLayersColors;
}

function isValidHex(hex: string) {
  return tinycolor(hex).isValid();
}
