import React from "react";
import { InputNumber, Select, Space, Switch } from "antd";
import { ModsPanelCompProps } from "../../ModsPanel";
import { IBGVideoMod } from "@blings/blings-player";
import { DynamicValueMod, DynamicValueModTypes } from "../../DynamicValueMod";

export function BGVideoForm(props: ModsPanelCompProps<IBGVideoMod>) {
  const { change, json } = props;

  return (
    <Space style={{ width: "100%" }} direction={"vertical"}>
      <span>
        Start Animation (Frame){" "}
        <InputNumber
          value={change.startAnimationFrame}
          onChange={(value) =>
            props.onChange(props.index, "startAnimationFrame", value)
          }
        />
      </span>
      <span>
        Stop Animation (Frame){" "}
        <InputNumber
          value={change.stopAnimationFrame}
          onChange={(value) =>
            props.onChange(props.index, "stopAnimationFrame", value)
          }
        />
      </span>
      <span>
        Offset (seconds){" "}
        <InputNumber
          value={change.offsetInSeconds}
          onChange={(value) =>
            props.onChange(props.index, "offsetInSeconds", value)
          }
        />
      </span>
      <span>
        Start on marker:{" "}
        <Select
          showArrow
          style={{ width: "100%" }}
          allowClear
          onChange={(value) =>
            props.onChange(props.index, "startOnMarker", value)
          }
          options={json?.markers?.map((m) => ({ label: m.cm, value: m.cm }))}
        />
      </span>

      {/*<label>*/}
      {/*    <Input*/}
      {/*        value={change.url}*/}
      {/*        addonBefore="URL"*/}
      {/*        onChange={(e) => props.onChange(props.index, "url", e.target.value)}*/}
      {/*        allowClear={true}*/}
      {/*    />*/}
      {/*</label>*/}
      <DynamicValueMod
        uploaderProps={{ accept: "video/*", s3Folder: "video" }}
        change={props.change}
        onChange={props.onChange}
        index={props.index}
        type={DynamicValueModTypes.url}
      />

      <label>
        unmute{" "}
        <Switch
          onChange={(checked) => props.onChange(props.index, "unmute", checked)}
          checked={change.unmute}
        />
      </label>
    </Space>
  );
}
