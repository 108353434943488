import { Button } from "antd";
import { ButtonProps, ButtonType } from "antd/lib/button";
import { AsyncOpState } from "../../stores/async-op-state";
import "./blings-btn.component.scss";

export type BlingsBtnProps = {
  opState: AsyncOpState;
  btnTexts?: IBtnTexts;
  disabled?: boolean;
} & Pick<ButtonProps, "htmlType"> &
  Pick<ButtonProps, "onClick"> &
  Pick<ButtonProps, "shape" | "ghost" | "className">;

export const BlingsBtn = ({
  opState,
  htmlType,
  btnTexts = {},
  onClick,
  shape,
  ghost,
  className,
  disabled,
}: BlingsBtnProps) => {
  const _disabled = disabled === undefined ? isDisabled(opState) : disabled;
  const btnType = getBtnType(opState);
  const className1 =
    (className ? className : "") +
    " " +
    getClassName(opState) +
    " " +
    (_disabled ? "" : "");
  return (
    <Button
      type={"primary"}
      htmlType={htmlType}
      className={className1}
      disabled={_disabled}
      onClick={onClick}
      shape={shape}
      ghost={ghost}
      loading={opState === AsyncOpState.Saving}
    >
      {getBtnTxt(opState, btnTexts)}
    </Button>
  );
};

const getClassName = (opState: AsyncOpState) => {
  return (
    "BlingsBtn " +
    (opState === AsyncOpState.Success ? "blings-btn-success" : "") +
    (opState === AsyncOpState.Saving ? "look-like-enable" : "") +
    (isDisabled(opState) ? "secondary" : "")
  );
};

const isDisabled = (opState: AsyncOpState) => {
  const disableBtnState = [
    AsyncOpState.Untouched,
    AsyncOpState.Saving,
    AsyncOpState.Success,
    AsyncOpState.Error,
  ];

  return disableBtnState.includes(opState);
};

const getBtnType = (opState: AsyncOpState): ButtonType | undefined => {
  switch (opState) {
    case AsyncOpState.Untouched:
    case AsyncOpState.Saving:
      return "dashed";
    case AsyncOpState.Success:
      return undefined;
    default:
      return "primary";
  }
};

export type IBtnTexts = {
  [key in AsyncOpState]?: string;
};

const getBtnTxt = (opState: AsyncOpState, btnTexts: IBtnTexts) => {
  switch (opState) {
    case AsyncOpState.Error:
      return btnTexts[AsyncOpState.Error] || "Error";
    case AsyncOpState.Saving:
      return btnTexts[AsyncOpState.Saving] || "Saving";
    case AsyncOpState.Success:
      return btnTexts[AsyncOpState.Success] || "Saved";
    case AsyncOpState.Untouched:
      return btnTexts[AsyncOpState.Untouched] || "Save";
    case AsyncOpState.Changed:
      return btnTexts[AsyncOpState.Changed] || "Save";

    default:
      return "Save";
  }
};
