import {
  COMMON_PATHS,
  findLayersOrLayerAssets,
  getPath,
  IInteractiveModJSExpression,
} from "@blings/blings-player";
import { ModsPanelCompProps } from "../../ModsPanel";
import { AddInteractiveHOC } from "./InteractiveFormHOC";
import { JsEditor } from "../../../../components/Editor/JsEditor";
import { Col, Input, Row } from "antd";
import { useMst } from "../../../../stores/main";
import MainCTAButton from "../../../MainCTA/MainCTAButton";
import { ExperimentOptimizationTechnique } from "../../../../API";
import { InfoTooltip } from "../../../../BaseComps/InfoTooltip";
import { useEffect } from "react";

export const useDefaultFunctionForInteractionExpression = (
  isControlDeprecated: boolean = false
) => `(data, ${
  isControlDeprecated ? "_" : "liveData"
}, event, element, player) => {
  console.log("clicked") 
}`;

function RenderActionForm(
  props: ModsPanelCompProps<IInteractiveModJSExpression>
) {
  const {
    platformStore,
    experimentStore: { selectedExperimentOptimizationTechnique },
  } = useMst();
  const { change } = props;
  const defaultString = useDefaultFunctionForInteractionExpression(
    platformStore.isControlDeprecated
  );
  const hasVariantsEnabled =
    selectedExperimentOptimizationTechnique &&
    selectedExperimentOptimizationTechnique !==
      ExperimentOptimizationTechnique.DISABLED;

  useEffect(() => {
    if (!change.ctaName) {
      const layers = findLayersOrLayerAssets({
        assetId: change.assetId,
        layerName: change.layerName,
        layerUid: change.layerUid,
        jsonVid: props.json,
        additionals: change.additionals,
      });
      const layer =
        layers?.length &&
        layers.find((layer) => getPath(layer, COMMON_PATHS.TEXT_IN_LAYER));
      if (layer) {
        const text = getPath(layer, COMMON_PATHS.TEXT_IN_LAYER);
        text && props.onChange(props.index, "ctaName", text);
      }
    }
  }, [props.change.layerName, props.change.additionals?.length]);

  return (
    <>
      <Row>
        <Col style={{ width: "100%" }}>
          <span>Name this button (Required for analytics)</span>
          <InfoTooltip
            style={{ marginLeft: "5px" }}
            info="This name is used to track how many viewers clicked on the button."
          />
          <div style={{ display: "flex", alignItems: "center", gap: "25px" }}>
            <Input
              size={"large"}
              id="connector-cta-name"
              value={props.change.ctaName}
              addonBefore={"Name"}
              // eslint-disable-next-line no-template-curly-in-string
              placeholder="Use a clear & unique name"
              onChange={(e) =>
                props.onChange(props.index, ["ctaName"], [e.target.value])
              }
              allowClear={true}
            />
          </div>
        </Col>
      </Row>
      <JsEditor
        defaultValue={
          props.change.functionString === ""
            ? defaultString
            : props.change.functionString
        }
        onChange={(e: any) => props.onChange(props.index, "functionString", e)}
        tooltipText="This function will run every time the user interacts with the layer\s"
      />
      {hasVariantsEnabled && (
        <MainCTAButton
          change={props.change}
          onChange={props.onChange}
          index={props.index}
        />
      )}
    </>
  );
}

export function AddInteractionJsExpressionForm(
  props: ModsPanelCompProps<IInteractiveModJSExpression>
) {
  return (
    <AddInteractiveHOC<IInteractiveModJSExpression>
      {...props}
      renderSpecificInteractive={RenderActionForm}
    />
  );
}
