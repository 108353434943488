export const TYPE = "ty";
// Layer types:
export const SOLID = 1;
export const SHAPE = 4;
export const TEXT = 5;
export const PRECOMP = 0;

export const HEX_START = "#";
export const GRADIENT_LENGTH = 13;
export const GRADIENT_PART_JUMP = 3;
