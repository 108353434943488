import { ModsPanelCompProps } from "../ModsPanel";
import { IOnPlayerEvent } from "@blings/blings-player";
import { Select, Space, Typography } from "antd";
import { PlayerEvents } from "@blings/blings-player/lib/src/player.api";
import { publicPlayerEvents } from "../../../stores/ModsStore";
import { JsEditor } from "../../Editor/JsEditor";
import { useMst } from "../../../stores/main";
const { Option } = Select;

export const defaultFunctionForOnPlayerEventMod = (
  isControlDeprecated: boolean = false
) => {
  return `(data, ${isControlDeprecated ? "_" : "liveData"}, eventData) => {
    console.log("event happened", eventData) 
}`;
};

export function PlayerEventForm(props: ModsPanelCompProps<IOnPlayerEvent>) {
  const { change, onChange, index } = props;
  const { platformStore } = useMst();

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <Space>
        <Typography.Text strong>Player Event: </Typography.Text>
        <Select<PlayerEvents>
          style={{ width: 200 }}
          onChange={(value) => {
            onChange(index, "playerEvent", value);
          }}
          onClick={(event) => event.preventDefault()}
          size={"middle"}
          value={change.playerEvent}
        >
          {publicPlayerEvents.map((pe) => (
            <Option key={pe} value={pe}>
              {pe}
            </Option>
          ))}
        </Select>
      </Space>
      <JsEditor
        defaultValue={
          props.change.functionString === ""
            ? defaultFunctionForOnPlayerEventMod(
                platformStore.isControlDeprecated
              )
            : props.change.functionString
        }
        onChange={(e: any) => props.onChange(props.index, "functionString", e)}
        tooltipText={`This function will run whenever an ${change.playerEvent} event occurs.`}
      />
    </Space>
  );
}
