export const hexToRgba = (hex: string): ColorArr => {
  const arr = hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g);

  if (arr) {
    const a = arr.map((x) => parseInt(x, 16) / 255);

    if (a.length < 4) {
      a.push(1);
    }

    return a as [number, number, number, number];
  }

  return [1, 1, 1, 1];
};

export const rgbaArrToHexStr = (rgba: ColorArr, withAlpha = false): string => {
  let hex = "#";
  for (let i = 0; i < rgba.length; i++) {
    const currentColor = rgba[i];
    const color = Math.round(currentColor * 255)
      .toString(16)
      .padStart(2, "0");
    hex += color;
  }
  if (!withAlpha && hex.length === 9) {
    hex = hex.slice(0, 7);
  }
  return hex;
};

export const gradientToMultipleHex = (
  gradient: number[],
  withAlpha = false
): string[] => {
  const hexes: string[] = [];
  // Gradient without alpha
  if (gradient.length === 12) {
    for (let i = 0; i < gradient.length; i += 4) {
      const data = gradient.slice(i, i + 4);
      // const gradientStop = data[0];
      const rgb = data.slice(1, 4) as ColorArr;
      hexes.push(rgbaArrToHexStr(rgb, withAlpha));
    }
  }
  // Gradient with alpha
  if (gradient.length === 18) {
    for (let i = 0, j = 13; i < 12; i += 4, j += 2) {
      const data = gradient.slice(i, i + 4);
      const alpha = gradient[j];
      // const gradientStop = data[0];
      const rgba = [...data.slice(1, 4), alpha] as ColorArr;
      const hex = rgbaArrToHexStr(rgba, withAlpha);
      hexes.push(hex);
    }
  }
  return hexes;
};
