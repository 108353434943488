import { Instance, types } from "mobx-state-tree";

export enum AsyncOpState {
  Untouched = "Untouched",
  Changed = "Changed", // before
  Saving = "Saving",
  Success = "Success",
  Error = "Error",
}

const asyncOpStateEnumType = types.enumeration([
  AsyncOpState.Untouched,
  AsyncOpState.Changed,
  AsyncOpState.Saving,
  AsyncOpState.Success,
  AsyncOpState.Error,
]);

export const INITIAL_ASYNC_OP_STATE = AsyncOpState.Changed; // todo: changes to untouched after fixing settings form UI behaviour
export const asyncOpStateType = types.optional(
  asyncOpStateEnumType,
  INITIAL_ASYNC_OP_STATE
);

export function updateAsyncStatus(self: Instance<any>, key) {
  return (newStatus: AsyncOpState) => {
    self[key] = newStatus;
  };
}
