import {
  destroy,
  getRoot,
  Instance,
  types,
  getSnapshot,
  applySnapshot,
} from "mobx-state-tree";
import {
  interactiveGotoTypes,
  modTypes,
  interactiveConcatJumpTypes,
  findLayersOrLayerAssets,
  transformChangeTypes,
} from "@blings/blings-player";
import { autorun, toJS } from "mobx";
import { throttle } from "lodash";
import _ from "lodash";
import { RootInstance } from "./main";
import { PlayerEvents } from "@blings/blings-player/lib/src/player.api";
import { snapshotsConsts } from "./snapshotConsts";
import { Mod } from "../API";
import { checkModDataConnectionErrors } from "../components/mods/checkModErrors";
import { updateObjectValues } from "../helpers/JsonSearch";
export const PLAYGROUND_CONTROL = "PLAYGROUND_CONTROL";

export const publicPlayerEvents: PlayerEvents[] = [
  "onAllReady",
  "onFirstPlay",
  "onPlay",
  "onFrame",
  "onReplay",
  "onPause",
  "onMute",
  "onUnmute",
  "onComplete",
  "onReplaceAnimation",
];
const LayerAndAdditionals = types.model({
  assetId: types.maybe(types.string),
  layerName: types.maybe(types.string),
  layerUid: types.maybe(types.number),
  isDisabled: types.optional(types.boolean, false),
  additionals: types.maybe(
    types.array(
      types.model({
        assetId: types.maybe(types.string),
        layerName: types.string,
        layerUid: types.maybe(types.number),
      })
    )
  ),
});

const ModDataModel = types.model({
  type: types.enumeration<modTypes>("modType", Object.values(modTypes)),
  isDisabled: types.optional(types.boolean, false),
  // IChangeInPathMod
  assetId: types.maybe(types.string),
  layerName: types.maybe(types.string),
  layerUid: types.maybe(types.number),
  additionals: types.maybe(
    types.array(
      types.model({
        assetId: types.maybe(types.string),
        layerName: types.string,
        layerUid: types.maybe(types.number),
      })
    )
  ),

  // IDynamicChangeMod
  dataKey: types.maybe(types.string),
  liveControlKey: types.maybe(types.string),
  value: types.maybe(types.frozen()),
  placeholder: types.maybe(types.frozen()), // Add the placeholder property to the mods storage
  inputName: types.maybe(types.string),
  expression: types.maybe(types.string),
  defaultValue: types.maybe(types.frozen()),

  // IInteractiveMod
  event: types.maybe(types.string),
  gotoType: types.maybe(
    types.enumeration<interactiveGotoTypes>([
      ...Object.values(interactiveGotoTypes),
    ])
  ),
  jumpType: types.maybe(
    types.enumeration<interactiveConcatJumpTypes>([
      ...Object.values(interactiveConcatJumpTypes),
    ])
  ),
  transformChangeType: types.maybe(
    types.enumeration<transformChangeTypes>([
      ...Object.values(transformChangeTypes),
    ])
  ),
  jumpValue: types.maybe(
    types.union(types.string, types.number, types.undefined)
  ),

  // ITextChangeMod
  maxChars: types.maybe(types.number),
  verticalAlign: types.maybe(types.string),
  fontSize: types.maybe(types.number),
  caseAdjustment: types.maybe(types.string),
  center: types.maybe(types.boolean),
  dontTrim: types.maybe(types.boolean),
  autoRTL: types.maybe(types.boolean),
  fixArabic: types.maybe(types.boolean),
  isAbsolute: types.maybe(types.boolean),

  // IColorChangeMod + ICustomChangeMod
  path: types.maybe(types.string),
  shapeName: types.maybe(types.string),

  //IThemeColorChangeMod
  froms: types.maybe(
    types.array(types.union(types.array(types.number), types.string))
  ),
  isLayers: types.maybe(types.boolean),
  //IAssetChangeMod
  changeSceneDurationToMediaDuration: types.optional(types.boolean, false),
  imageName: types.maybe(types.string),
  align: types.maybe(types.string),
  slice: types.maybe(types.boolean),

  // MediaMods
  startOnMarker: types.maybe(types.string),
  startAnimationFrame: types.maybe(types.number),
  stopAnimationFrame: types.maybe(types.number),
  offsetInSeconds: types.maybe(types.number),
  shouldLoop: types.maybe(types.boolean),

  //IBGVideoMod
  style: types.maybe(types.string),
  fullScreenStyle: types.maybe(types.string),
  unmute: types.maybe(types.boolean),

  //IBGAudioMod
  volume: types.maybe(types.number),

  // IFontMod
  fontConfig: types.maybe(types.frozen()),

  // IFunctionParam (IInteractiveModJSExpression + IOnPlayerEvent)
  functionString: types.maybe(types.string),
  /*
   *  following two are currently not in studio (mods stored online) just in manual player invocation
   *  functionKey: types.maybe(types.string)
   *  jsFunction:
   */

  playerEvent: types.maybe(
    types.enumeration<PlayerEvents>("PlayerEvents", publicPlayerEvents)
  ),

  //transition ITransitionTimingMod
  ip: types.maybe(types.number),
  op: types.maybe(types.number),

  exposeToPlatform: types.optional(
    types.maybe(types.maybeNull(types.boolean)),
    null
  ),

  //name for goto connectors
  ctaName: types.maybe(types.string),
  inputKeys: types.maybe(types.array(types.string)),
  sendToThirdParty: types.maybe(types.boolean),
  isMultiline: types.maybe(types.boolean),

  // ICountdownChangeMod
  timeFormatAndLayers: types.maybe(
    types.model({
      days: types.maybeNull(LayerAndAdditionals),
      hours: types.maybeNull(LayerAndAdditionals),
      minutes: types.maybeNull(LayerAndAdditionals),
      seconds: types.maybeNull(LayerAndAdditionals),
    })
  ),
  showWhenExpired: types.maybe(
    types.model({
      assetId: types.maybe(types.string),
      layerName: types.maybe(types.string),
      layerUid: types.maybe(types.number),
      additionals: types.maybe(
        types.array(
          types.model({
            assetId: types.maybe(types.string),
            layerName: types.string,
            layerUid: types.maybe(types.number),
          })
        )
      ),
    })
  ),
  hideWhenExpired: types.maybe(
    types.model({
      assetId: types.maybe(types.string),
      layerName: types.maybe(types.string),
      layerUid: types.maybe(types.number),
      additionals: types.maybe(
        types.array(
          types.model({
            assetId: types.maybe(types.string),
            layerName: types.string,
            layerUid: types.maybe(types.number),
          })
        )
      ),
    })
  ),
  hasPadding: types.maybe(types.boolean),

  // Variant optimization
  experimentId: types.maybe(types.string),
  isMainCta: types.maybe(types.boolean),
});
const ModModel = types
  .model({
    id: types.identifierNumber,
    name: types.maybe(types.union(types.maybeNull(types.string))),
    origin: types.optional(types.maybe(types.maybeNull(types.string)), null),
    // moddata: types.frozen<IMods>(),
    // disabled: types.optional(types.boolean, false),
    moddata: ModDataModel,
  })
  .actions((self) => ({
    updateName(name: string) {
      self.name = name;
    },
  }));

export type IModModel = Instance<typeof ModModel>;
type MultiConnectedLayers = {
  [layer: string]: {
    [key in "text" | "themeColor" | "asset"]: Array<IModModel>;
  };
};
export const ModsModel = types
  .model({
    activeModId: types.optional(types.string, ""),
    // mods: types.map(types.frozen<IMods>()), [])
    mods: types.array(ModModel),
    unchangedMods: types.maybeNull(types.string),
    trigger: types.optional(types.boolean, false),
    multiConnectedLayers: types.optional(
      types.frozen<MultiConnectedLayers>(),
      {}
    ),
    // object. mod id as key, value is an array of errors
    modsWithDataErrors: types.optional(
      types.frozen<Record<number, string[]>>(),
      {}
    ),
    modsWithBrokenLayers: types.optional(
      types.frozen<Record<number, string[]>>(),
      {}
    ),
    modsWithMultipleDynamicLength: types.optional(
      types.frozen<Record<number, string[]>>(),
      {}
    ),
    modsWithMissingProperties: types.optional(
      types.frozen<Record<number, string[]>>(),
      {}
    ),
  })
  .views((self) => ({
    get JsonConfig() {
      return self.mods.map((mod) => ({
        id: mod.id,
        dataStr: JSON.stringify(mod.moddata),
        origin: mod.origin,
        name: mod.name,
      }));
    },
    get hasUnsavedChanges() {
      const currentMods = self.mods.map((mod) => ({
        id: mod.id,
        dataStr: JSON.stringify(mod.moddata),
        name: mod.name,
        origin: mod.origin,
      }));
      const {
        experimentStore: { hasUnsavedChanges: hasUnsavedChangesExperiments },
      } = getRoot<any>(self);
      return (
        !_.isEqual(JSON.parse(self.unchangedMods || "[]"), currentMods) ||
        hasUnsavedChangesExperiments
      );
    },
    get modIdsWithMultiConnectedLayers() {
      const mods: Array<IModModel> = [];
      Object.keys(self.multiConnectedLayers).forEach((layer) => {
        Object.keys(self.multiConnectedLayers[layer]).forEach((type) => {
          self.multiConnectedLayers[layer][type].forEach((mod) => {
            mods.push(mod);
          });
        });
      });
      return new Set(mods.map((mod) => mod.id));
    },
    getThrottleJsonConfig: throttle(
      () => {
        return JSON.parse(JSON.stringify(self.mods.map((mod) => mod.moddata)));
      },
      1000,
      { trailing: true, leading: false }
    ),
  }))
  .views((self) => ({
    hasMultiConnectedLayers: (modId: number) => {
      return self.modIdsWithMultiConnectedLayers.has(modId);
    },
    /* hasDataErrors: (modId: number) => {
      return !!self.modsWithDataErrors[modId];
    }, */
  }))
  .actions((self) => ({
    checkMultiConnectedLayers() {
      const modsGroupedByLayer = self.mods
        .filter((mod) => !mod.moddata.isDisabled)
        .reduce((acc, mod) => {
          if (
            mod.moddata.type !== "text" &&
            mod.moddata.type !== "themeColor" &&
            mod.moddata.type !== "asset"
          )
            return acc;
          if (mod.moddata.type === "text" && !mod.moddata.layerName) return acc;
          if (mod.moddata.type === "asset" && !mod.moddata.imageName)
            return acc;
          if (
            mod.moddata.type === "themeColor" &&
            mod.moddata.isLayers &&
            !mod.moddata.layerName
          )
            return acc;
          const layers =
            mod.moddata.type === "text"
              ? [
                  mod.moddata.layerUid ||
                    `${mod.moddata.layerName}_${mod.moddata.assetId}`,
                  ...(mod.moddata.additionals?.map(
                    (additional) =>
                      additional.layerUid ||
                      `${additional.layerName}_${additional.assetId}`
                  ) || []),
                ]
              : mod.moddata.type === "asset"
              ? [mod.moddata.imageName]
              : mod.moddata.type === "themeColor"
              ? [
                  ...(mod.moddata.isLayers && mod.moddata.froms
                    ? [
                        ...mod.moddata.froms.map((colorArray) => [
                          (mod.moddata.layerName || "") +
                            (mod.moddata.layerUid || "") +
                            mod.moddata.assetId +
                            colorArray?.toString(),
                          ...(mod.moddata.additionals?.map(
                            (add) =>
                              (add.layerName || "") +
                              (add.layerUid || "") +
                              add.assetId +
                              colorArray?.toString()
                          ) || []),
                        ]),
                      ]
                    : mod.moddata.froms?.map((colorArray) => {
                        return colorArray;
                      }) || []),
                ]
              : [];
          layers.forEach((layer) => {
            acc[layer] = acc[layer] || {};
            acc[layer][mod.moddata.type] = acc[layer][mod.moddata.type] || [];
            acc[layer][mod.moddata.type].push(mod);
          });
          return acc;
        }, {} as MultiConnectedLayers);
      Object.keys(modsGroupedByLayer).forEach((layer) => {
        const layerMods = modsGroupedByLayer[layer];
        Object.keys(layerMods).forEach((type) => {
          if (layerMods[type].length <= 1) delete layerMods[type];
        });
        if (Object.keys(layerMods).length === 0)
          delete modsGroupedByLayer[layer];
      });
      self.multiConnectedLayers = modsGroupedByLayer;
    },
    checkAllModsForBrokenLayers() {
      self.modsWithBrokenLayers = {};
      const { jsonFile } = getRoot<RootInstance>(self).playerStore;
      if (!jsonFile) return;
      self.mods.forEach((mod) => {
        this.checkModForBrokenLayers(mod);
      });
    },
    checkAllAssetModsForMultipleDynamicLength() {
      const assetMods = self.mods.filter(
        (mod) => !mod.moddata.isDisabled && mod.moddata.type === modTypes.asset
      );

      // Check if more than one asset mod has dynamic length (changeSceneDurationToMediaDuration)
      const dynamicLengthAssetMods = assetMods.filter((mod) =>
        mod.moddata.isDisabled
          ? false
          : mod.moddata.changeSceneDurationToMediaDuration
      );

      if (dynamicLengthAssetMods.length > 1) {
        const modsWithErrors: any = {};
        for (let i = 0; i < dynamicLengthAssetMods.length; i++) {
          const mod = dynamicLengthAssetMods[i];
          modsWithErrors[mod.id] = ["changeSceneDurationToMediaDuration"];
        }
        this.setModsForMultipleDynamicLength(modsWithErrors);
      } else {
        this.setModsForMultipleDynamicLength({});
      }
    },
    checkForModErrors() {
      this.checkAllModsForBrokenLayers();
      this.checkAllModsForDataErrors();
      this.checkMultiConnectedLayers();
      this.checkAllAssetModsForMultipleDynamicLength();
    },
    setModsForMultipleDynamicLength(modsWithErrors: Record<number, string[]>) {
      self.modsWithMultipleDynamicLength = modsWithErrors;
    },
    checkModForBrokenLayers(mod: IModModel) {
      const { jsonFile } = getRoot<RootInstance>(self).playerStore;
      if (!jsonFile) return;
      const { type } = mod.moddata;
      if (
        type === modTypes.advancedConfig ||
        type === modTypes.transitionTiming ||
        type === modTypes.onPlayerEvent ||
        type === modTypes.asset
      )
        return;
      const modsWithErrors = { ...self.modsWithBrokenLayers };
      const { moddata } = mod;
      const errors: Array<string> = [];

      if (moddata.layerName) {
        findLayersOrLayerAssets(
          {
            jsonVid: jsonFile,
            assetId: moddata.assetId,
            layerName: moddata.layerName,
            additionals: moddata.additionals,
            layerUid: moddata.layerUid,
          },
          (error) => {
            errors.push(
              `${error.message}. Asset id: ${error.payload.assetId} Layer name: ${error.payload.layerName}`
            );
          }
        );
      }
      if (errors.length) modsWithErrors[mod.id] = errors;
      else delete modsWithErrors[mod.id];
      self.modsWithBrokenLayers = modsWithErrors;
    },

    checkAllModsForDataErrors() {
      // Prevents mobx state tree reading errors
      _.debounce(this.debouncedCheckAllModsForDataErrors, 100)();
    },

    debouncedCheckAllModsForDataErrors() {
      const { dynamicDataStore, platformStore } = getRoot<RootInstance>(self);
      const modsWithErrors = {};
      Promise.all(
        self.mods.map((mod) => {
          return checkModDataConnectionErrors(
            mod,
            dynamicDataStore,
            platformStore
          );
        })
      ).then((errors) => {
        errors.forEach((error, index) => {
          if (error) modsWithErrors[self.mods[index].id] = error;
        });
        this.setModsWithDataErrors(modsWithErrors);
      });
    },
    async checkModForDataErrors(mod: IModModel) {
      const { dynamicDataStore, platformStore } = getRoot<RootInstance>(self);
      const errors = await checkModDataConnectionErrors(
        mod,
        dynamicDataStore,
        platformStore
      );
      const modsWithErrors = { ...self.modsWithDataErrors };
      if (!errors) {
        delete modsWithErrors[mod.id];
      } else modsWithErrors[mod.id] = errors;
      this.setModsWithDataErrors(modsWithErrors);
    },
    async checkInputModNoName(mod: IModModel) {
      if (mod.moddata.type === modTypes.interactiveInput) {
        const wasMissingName = mod.id in self.modsWithMissingProperties;
        const isMissingName = !mod.moddata.value;
        if (wasMissingName !== isMissingName) {
          const modsWithErrors = { ...self.modsWithMissingProperties };
          if (isMissingName) modsWithErrors[mod.id] = ["Missing name"];
          else delete modsWithErrors[mod.id];
          this.setModsWithMissingProperties(modsWithErrors);
        }
      }
    },
    setModsWithDataErrors(modsWithErrors: Record<number, string[]>) {
      self.modsWithDataErrors = modsWithErrors;
    },
    setModsWithMissingProperties(modsWithErrors: Record<number, string[]>) {
      self.modsWithMissingProperties = modsWithErrors;
    },
    setTrigger() {
      self.trigger = !self.trigger;
    },
    afterAttach() {
      autorun((a) => {
        if (self.JsonConfig) {
          getRoot<RootInstance>(self).playerStore.runPlayer();
        }
      });
    },
    setActiveMod(id?: string | number) {
      if (typeof id !== "undefined") {
        self.activeModId = id.toString();
      } else {
        self.activeModId = "";
      }
    },
    // addCbAfterAddingMod(cb){
    //
    // }
  }))
  .actions((self) => {
    const afterAddCB: ((id?: number) => void)[] = [];
    function addCbAfterAddingMod(cb) {
      afterAddCB.push(cb);
    }
    function addMod(moddata: Partial<IModModel["moddata"]>, afterIdx?: number) {
      localStorage.setItem(
        snapshotsConsts.mods,
        JSON.stringify(getSnapshot(self.mods))
      );
      const id = generateRandomId();
      const origin = PLAYGROUND_CONTROL;
      const mod = { id, moddata, origin };
      if (typeof afterIdx !== "undefined") {
        const afterIndex =
          self.mods.findIndex((mod, index) => index === afterIdx) + 1;
        // @ts-ignore
        self.mods.splice(afterIndex, 0, mod);
      } else {
        // @ts-ignore
        self.mods.unshift(mod);
      }
      setTimeout(() => {
        self.setActiveMod(id);
      }, 1);

      if (typeof id !== "undefined" && typeof afterIdx === "undefined") {
        afterAddCB.forEach((cb) => cb(id));
      }
      // self.checkModForBrokenLayers(mod as IModModel);
      self.checkForModErrors();
      self.checkInputModNoName(mod as IModModel);
    }
    function generateRandomId() {
      const usedIds = new Set(self.mods.map((mod) => mod.id));
      let rnd: number;
      do {
        rnd = Math.floor(Math.random() * 100000);
      } while (usedIds.has(rnd));
      return rnd;
    }
    return { addMod, addCbAfterAddingMod };
  })
  .actions((self) => ({
    copyMod(mod: IModModel) {
      self.addMod(
        toJS(mod.moddata),
        self.mods.findIndex((m) => m.id === mod.id)
      );
    },
    removeMod(mod) {
      destroy(mod);
      self.checkMultiConnectedLayers();
    },
    snapshotMods() {
      const modsSnapshot = localStorage.getItem(snapshotsConsts.mods);
      if (modsSnapshot) {
        applySnapshot(self.mods, JSON.parse(modsSnapshot));
      }
    },
    reorderMods(mods: Array<IModModel>) {
      self.mods.replace(mods);
      self.checkForModErrors();
    },
    replaceMods(mods?: Array<Mod> | null | IModModel[]) {
      // Check if the mods are different from the current mods
      self.mods.replace([]);
      if (mods) {
        self.mods.replace(
          mods.map((md) =>
            ModModel.create({
              id: md.id,
              moddata: JSON.parse(md.dataStr || {}),
              origin: md.origin,
              name: md.name,
            })
          )
        );
      } else {
        self.mods.replace([]);
      }
      self.unchangedMods = JSON.stringify(mods || []);
      self.checkForModErrors();
    },
    mergeWithModsFromSubscription(mods?: Array<Mod> | null) {
      if (!mods) {
        mods = [];
      }
      this.setUnchangedMods(mods);
      const parsedMods = mods.map((mod) =>
        ModModel.create({
          id: mod.id,
          moddata: JSON.parse(mod.dataStr),
          origin: mod.origin,
          name: mod.name,
        })
      );

      const activeMod = self.mods.find(
        (mod) => mod.id.toString() === self.activeModId
      );
      if (!activeMod) {
        self.mods.replace(parsedMods);
        return;
      }
      let replacedActiveInIncoming = false;
      for (let i = 0; i < parsedMods.length; i++) {
        const mod = parsedMods[i];
        if (mod.id === activeMod.id) {
          parsedMods[i] = activeMod;
          replacedActiveInIncoming = true;
          break;
        }
      }
      if (!replacedActiveInIncoming) {
        parsedMods.push(activeMod);
      }
      self.mods.replace(parsedMods);
      self.checkMultiConnectedLayers();
    },
    async updateInnerModValue(modId: number, keys: string[], values: any) {
      const m = self.mods.find((mod) => mod.id === modId);
      if (!m) return;
      m.origin = PLAYGROUND_CONTROL;
      updateObjectValues(m.moddata, keys, values);
    },
    async updateValue(modId: number, key: string | string[], value: any) {
      const m = self.mods.find((mod) => mod.id === modId);
      if (!m) return;
      const keys = Array.isArray(key) ? key : [key];
      const values = Array.isArray(key) ? value : [value];
      m.origin = PLAYGROUND_CONTROL;
      keys.forEach((k, i) => {
        m.moddata[k] = values[i];
        if (k === "ctaName") {
          this.updateModName(modId, values[i]);
        }
        if (Array.isArray(m.moddata[k])) {
          self.setTrigger(); //Mobx workaround
        }
      });
      if (keys.includes("isDisabled")) {
        self.checkMultiConnectedLayers();
        self.checkAllAssetModsForMultipleDynamicLength();
      }
      if (keys.includes("assetId") || key === "imageName" || key === "froms") {
        self.checkMultiConnectedLayers();
      }
      if (
        keys.includes("value") ||
        key === "inputKeys" ||
        key === "jumpValue"
      ) {
        await self.checkModForDataErrors(m);
      }
      if (m.moddata.type === modTypes.interactiveInput && key === "value") {
        self.checkAllModsForDataErrors();
        self.checkInputModNoName(m);
      }
      if (keys.includes("assetId") || key === "isLayers") {
        self.checkModForBrokenLayers(m);
      }
      if (key.includes("changeSceneDurationToMediaDuration")) {
        self.checkAllAssetModsForMultipleDynamicLength();
      }
      if (key.includes("isDisabled")) {
        self.checkAllAssetModsForMultipleDynamicLength();
      }
    },
    updateModName(modId: string | number, name: string) {
      const m = self.mods.find((mod) => mod.id.toString() === modId.toString());
      if (!m) return;
      m.updateName(name);
    },
    setUnchangedMods(mods: Array<Mod>) {
      self.unchangedMods = JSON.stringify(mods);
    },
  }));

export type IModsModel = Instance<typeof ModsModel>;

export const modsStore = ModsModel.create({
  // mods: [{type: modTypes.text}]
});
