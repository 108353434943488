import React from "react";
import { TreeSelect } from "antd";
import { TreeSelectProps } from "antd/lib/tree-select";
import { IJSONObject } from "./json.typings";

interface ITreeSelectProps extends TreeSelectProps<any> {
  json: IJSONObject;
}

function getNodes(object = {}, path = "") {
  return Object.entries(object).map(([key, value]) => {
    const nkey = [path, key].filter((e) => e).join(".");
    return value && typeof value === "object"
      ? {
          title: key,
          value: nkey,
          children: getNodes(value, nkey),
          isLeaf: false,
          selectable: false,
        }
      : { title: key, value: nkey, isLeaf: true };
  });
}

export const JSONTreeSelect = (props: ITreeSelectProps) => {
  const treeData = getNodes(props.json);
  return (
    <TreeSelect
      size={"large"}
      showSearch
      className={props.className}
      popupClassName={props.className + "-dropdown"}
      style={{ width: "100%" }}
      value={props.value}
      // dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeData={treeData}
      // showCheckedStrategy={TreeSelect.SHOW_PARENT}
      placeholder="Please select"
      treeDefaultExpandAll
      onChange={props.onChange}
      treeNodeLabelProp={"value"}
    />
  );
};
