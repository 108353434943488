import React from "react";
import { useRef } from "react";
import { observer } from "mobx-react-lite";
import { DynamicForm } from "@blings/dynamic-form";
import "./DataSetter.scss";
import { BlingsBtn } from "../antd-extensions/blings-btn.component";
import { useMst } from "../../stores/main";
import { AsyncOpState } from "../../stores/async-op-state";
import { Tooltip } from "antd";
import BlingsTheme from "../../config/antdTheme";

type Props = {};

export const DataSetter = observer((props: Props) => {
  const {
    platformStore,
    dynamicDataStore: {
      setCurrentPerVideoData,
      setCurrentPerVideoSchema,
      perVideoInitialSchema,
      initialPerVideoData,
      hasUnsavedChangesPerVideo,
    },
  } = useMst();

  const submit = useRef<() => void>(() => {
    return;
  });

  if (!platformStore.projectWorkspaceVersion)
    return <div className={"editor-wrapper"}></div>;
  return (
    <div className={"editor-wrapper"}>
      <DynamicForm
        themeConfig={BlingsTheme}
        key={`${perVideoInitialSchema}-Data`}
        schema={JSON.parse(perVideoInitialSchema || "{}")}
        formData={initialPerVideoData}
        saveData={(data, schema) => {
          schema.examples = [data];
          platformStore.savePerVideoSchema(schema);
        }}
        submit={submit}
        editable={true}
        uploadAssetToProject={platformStore.uploadAssetToProject}
        getAssetsUploadStatus={platformStore.getDynamicFormAssetsUploadStatus}
        onChange={(formData, schema) => {
          setCurrentPerVideoData(formData);
          schema.examples = [formData];
          setCurrentPerVideoSchema(schema);
        }}
        readable={false}
        fieldDescription={{
          key:
            "A name for this data field. Each video might get different value at run-time",
          value:
            'Values defined in "per-video" serve just as an example. The real value can be different for every video',
        }}
      />
      {!hasUnsavedChangesPerVideo ? ( //no changes
        <Tooltip title={`No unsaved changes`}>
          <div className="footer">
            <BlingsBtn
              className={"save-schema-btn secondary"}
              opState={platformStore.saveProjectSchemaStatus}
              htmlType={"submit"}
              disabled={true}
              btnTexts={{ [AsyncOpState.Changed]: "Save Dynamic Data" }}
              onClick={() => {
                if (!hasUnsavedChangesPerVideo) return;
                submit.current();
              }}
            />
          </div>
        </Tooltip>
      ) : (
        <div className="footer">
          <BlingsBtn
            className={"save-schema-btn secondary"}
            opState={platformStore.saveProjectSchemaStatus}
            htmlType={"submit"}
            btnTexts={{ [AsyncOpState.Changed]: "Save Dynamic Data" }}
            onClick={() => {
              if (!hasUnsavedChangesPerVideo) return;
              submit.current();
            }}
          />
        </div>
      )}
    </div>
  );
});
