import { Button, ButtonProps } from "antd";
import React, { MutableRefObject } from "react";

interface Props extends ButtonProps {
  children: React.ReactNode;
  customColor: string;
  disable: boolean;
  reactRef?: MutableRefObject<HTMLDivElement | null>;
}

// same styling for button and dropdown
export const NewModButtonsListButtonParent = ({
  children,
  customColor: color,
  disable,
  reactRef,
  ...buttonProps
}: Props) => {
  return (
    <div className={"NewModButtonsListButtonParent"} ref={reactRef}>
      <Button
        className={"NewModButtonsListButtonParent-btn"}
        {...buttonProps}
        style={{ borderColor: color }}
        disabled={disable}
      >
        {children}
      </Button>
    </div>
  );
};
