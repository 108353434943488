import { Input, Space } from "antd";
import { ModsPanelCompProps } from "../ModsPanel";
import { ICustomChangeMod } from "@blings/blings-player";
import { DynamicValueMod } from "../DynamicValueMod";
import { LayerSelector } from "../../LayerSelector";

export function CustomChangeForm(props: ModsPanelCompProps<ICustomChangeMod>) {
  const { change, json } = props;
  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <div>
        <LayerSelector
          isMulti={true}
          assetLayerPairs={props.assetLayerPairs}
          selectedAssetId={change.assetId}
          selectedLayerName={change.layerName}
          selectedLayerUid={change.layerUid}
          selectedAdditionals={change.additionals}
          onChange={props.onLayerChange}
          index={props.index}
          jsonVid={json}
        />
      </div>
      <Input
        size="large"
        value={change.path}
        addonBefore="Path"
        onChange={(e) => props.onChange(props.index, "path", e.target.value)}
        allowClear={true}
      />
      <DynamicValueMod
        change={props.change}
        onChange={props.onChange}
        index={props.index}
      />
    </Space>
  );
}
