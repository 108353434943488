import { useEffect, useState, useContext, useMemo, useRef } from "react";
import styles from "./styles.module.scss";
import { flowPlayerWrapper } from "../../components/MainVidPlayer/FlowPlayerWrapper";
import { MSTContext } from "../../stores/main";
import { Button, Space } from "antd";
import { AddOutlinedIcon } from "../../icons-and-animations/Icons";
import { FlowDiagram } from "../../components/Flows/FlowDiagram";
import { flowsStore, IFlowStore, ILineupModel } from "../../stores/FlowStore";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { SoundOutlined } from "@ant-design/icons";
import MuterSingleton from "../../utils/mute";
import { AsyncOpState } from "../../stores/async-op-state";

const VIDEO_CONTAINER_ID = "flow-vid-conatiner";
const checkForUnsavedChanges = (
  flowsStore: IFlowStore,
  platformFlowDiagramAsFlows?: Array<ILineupModel>
) => {
  if (!platformFlowDiagramAsFlows) return false;
  try {
    if (platformFlowDiagramAsFlows.length !== flowsStore.flows.length) {
      return true;
    }
    for (let i = 0; i < platformFlowDiagramAsFlows.length; i++) {
      if (
        platformFlowDiagramAsFlows[i].lineupId !== flowsStore.flows[i].lineupId
      ) {
        return true;
      }
      if (
        platformFlowDiagramAsFlows[i].scenes.length !==
        flowsStore.flows[i].scenes.length
      ) {
        return true;
      }
      for (let j = 0; j < platformFlowDiagramAsFlows[i].scenes.length; j++) {
        if (
          platformFlowDiagramAsFlows[i].scenes[j].id !==
          flowsStore.flows[i].scenes[j].id
        ) {
          return true;
        }
      }
    }
  } catch (error) {
    console.error("Failed to detect changes:", error);
    return false;
  }
  return false;
};
const FlowPage = observer(() => {
  const {
    platformStore: {
      projectWorkspaceFlowDiagram,
      projectWorkspaceVersion,
      updateSaveFlowDiagram,
      saveFlowStatus,
    },
    dynamicDataStore: { liveControlCurrentData },
    playerStore,
  } = useContext(MSTContext);
  const [isCreateDisabled, setIsCreateDisabled] = useState(true);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [currentFlowMessage, setCurrentFlowMessage] = useState("");
  const [playerLoaderDisplay, setPlayerLoaderDisplay] = useState(true);
  const [isSavingDiagram, setIsSavingDiagram] = useState(false);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const [muteState, setMuteState] = useState(
    MuterSingleton.instance.isMuting()
  );

  const {
    computedCurrentLineupId,
    selectedFlowScenesCount,
    isDirty,
    someChangeValue,
    selectedFlow,
    validteFlows,
    hasChangesToSave,
    selectedFlowName,
    flows,
    flowsInit,
    setDefaultFlow,
  } = flowsStore;

  useEffect(() => {
    // Initialize the flows store with the project flow
    // after save, set selected flow to last flow before the save or the default lineup
    const hasChange = checkForUnsavedChanges(
      flowsStore,
      projectWorkspaceFlowDiagram?.asFlows
    );
    if (!projectWorkspaceFlowDiagram?.isDirty && hasChange) {
      const selectedLineup =
        flowsStore.selectedFlow?.lineupId ??
        projectWorkspaceFlowDiagram?.defaultLineup;
      flowsStore.resetSelectedFlow();
      flowsInit(projectWorkspaceFlowDiagram?.asFlows, selectedLineup);
      if (projectWorkspaceFlowDiagram?.defaultLineup) {
        setDefaultFlow(projectWorkspaceFlowDiagram.defaultLineup);
      }
    }
    setIsSavingDiagram(false);
  }, [
    flowsInit,
    setDefaultFlow,
    projectWorkspaceFlowDiagram?.isDirty,
    projectWorkspaceFlowDiagram?.asFlows,
    projectWorkspaceFlowDiagram?.defaultLineup,
    projectWorkspaceFlowDiagram,
  ]);

  const data = useMemo(() => {
    return projectWorkspaceVersion?.stateJsonSchemaStr
      ? JSON.parse(projectWorkspaceVersion.stateJsonSchemaStr).examples[0]
      : {};
  }, [projectWorkspaceVersion?.stateJsonSchemaStr]);

  const handleSave = async () => {
    const hasChange = checkForUnsavedChanges(
      flowsStore,
      projectWorkspaceFlowDiagram?.asFlows
    );
    if (hasChange) {
      setIsSavingDiagram(true);
      const { nodes, edges } = flowsStore.flowDiagram;
      projectWorkspaceFlowDiagram?.setNodesAndEdges(nodes, edges);
      await updateSaveFlowDiagram();
    }
  };

  useEffect(() => {
    if (!projectWorkspaceFlowDiagram) return;
    const hasChange = checkForUnsavedChanges(
      flowsStore,
      projectWorkspaceFlowDiagram?.asFlows
    );
    projectWorkspaceFlowDiagram?.setIsDirty(hasChange);
  }, [isDirty, projectWorkspaceFlowDiagram, someChangeValue]);

  useEffect(() => {
    setIsCreateDisabled(
      !validteFlows || saveFlowStatus === AsyncOpState.Saving
    );
  }, [validteFlows, saveFlowStatus]);

  useEffect(() => {
    const defaultMessage = "";
    if (computedCurrentLineupId) {
      setCurrentFlowMessage(`Watching: ${computedCurrentLineupId}`);
      setPlayerLoaderDisplay(true);
    } else {
      setCurrentFlowMessage(defaultMessage);
      setPlayerLoaderDisplay(false);
    }
  }, [computedCurrentLineupId, isDirty, selectedFlow?.scenes]);

  useEffect(() => {
    if (
      saveFlowStatus === AsyncOpState.Saving ||
      !projectWorkspaceVersion ||
      !selectedFlow ||
      !computedCurrentLineupId ||
      !selectedFlowScenesCount ||
      hasChangesToSave ||
      isSavingDiagram
    ) {
      setIsSaveDisabled(true);
      setPlayerLoaderDisplay(true);
      return;
    }

    flowPlayerWrapper(
      setPlayerLoaderDisplay,
      selectedFlowName,
      flowsStore.flowDiagram,
      projectWorkspaceVersion,
      liveControlCurrentData,
      data,
      false,
      playerStore.player?.posterFrame || 0,
      videoContainerRef.current
    );
    setIsSaveDisabled(
      !checkForUnsavedChanges(flowsStore, projectWorkspaceFlowDiagram?.asFlows)
    );
  }, [
    saveFlowStatus,
    computedCurrentLineupId,
    isDirty,
    flows.length,
    isSavingDiagram,
    selectedFlow,
    selectedFlowName,
    hasChangesToSave,
    selectedFlowScenesCount,
    data,
    someChangeValue,
    liveControlCurrentData,
    playerStore.player?.posterFrame,
    projectWorkspaceVersion,
    projectWorkspaceFlowDiagram?.asFlows,
  ]);

  const loaderMessage = useMemo(() => {
    let message = "loading...";
    if (!computedCurrentLineupId) {
      message = "Please select a flow to start";
    } else if (computedCurrentLineupId && selectedFlowScenesCount === 0) {
      message = "Please select scenes to start the flow";
    }
    if (isSavingDiagram) {
      message = "Saving flow diagram...";
    }
    return message;
  }, [selectedFlowScenesCount, computedCurrentLineupId, isSavingDiagram]);

  return (
    <div className={styles["flows-container"]}>
      <div style={{ height: "100%", overflow: "hidden" }}>
        <div className={styles["player-container"]}>
          <div className={classNames(styles["flow-vid-wrapper"])}>
            {computedCurrentLineupId &&
            selectedFlowScenesCount &&
            !isSavingDiagram ? (
              <>
                <div id={VIDEO_CONTAINER_ID} ref={videoContainerRef} />
                {!playerLoaderDisplay && selectedFlowScenesCount ? (
                  <Space className={styles["vid-bottom-ctrls"]}>
                    <div className={styles["mute-container"]}>
                      <div className={styles["mute-wrapper"]}>
                        <SoundOutlined
                          className={styles["mute-btn"]}
                          onClick={() => {
                            MuterSingleton.instance.toggleMute();
                            setMuteState(MuterSingleton.instance.isMuting());
                          }}
                        />
                        {muteState && (
                          <div className={styles["mute-line"]}></div>
                        )}
                      </div>
                    </div>
                  </Space>
                ) : null}
              </>
            ) : null}
          </div>
          {isSavingDiagram ||
          playerLoaderDisplay ||
          !selectedFlowScenesCount ? (
            <div className={styles["empty-player-container"]}>
              {loaderMessage}
            </div>
          ) : null}
        </div>
      </div>

      <div
        className={styles["scenses-container"]}
        style={{ height: flows.length > 1 ? "100%" : "auto" }}
      >
        <div className={styles["bar-container"]}>
          <Button
            type="text"
            className={styles["add-flow"]}
            style={{ color: "#BEBEBE" }}
            onClick={flowsStore.addFlowAndSelect}
            disabled={isCreateDisabled}
          >
            <AddOutlinedIcon />
            create new flow
          </Button>

          <div>{currentFlowMessage}</div>
          <div style={{ display: "flex", gap: "20px" }}>
            <Button
              className={styles["save-flow-btn"]}
              disabled={isSaveDisabled}
              onClick={handleSave}
            >
              {isSaveDisabled ? "No changes to save" : "Save Draft"}
            </Button>
          </div>
        </div>
        <div className={styles["scenses-list"]}>
          <FlowDiagram />
        </div>
      </div>
    </div>
  );
});
export default FlowPage;
