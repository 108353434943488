import { ThemeConfig } from "antd";

export const BlingsTheme = {
  token: {
    fontSizeSM: 10,
    fontSize: 12,
    fontSizeLG: 12,
    colorPrimary: "#ff154d",
    colorTextPlaceholder: "#BEBEBE",
    colorInfo: "#ff154d",
    colorError: "#ff000f",
    colorSuccess: "#98d7c2",
    colorWarning: "#ffd046",
    colorLink: "#363ef1",
    colorTextBase: "#000000a6",
    colorBgLayout: "#1e1e1e",
    colorTextDescription: "#8c8c8c",
    colorTextDisabled: "rgba(255, 255, 255, 0.4)",
    activeBackground: "#ff154d",
    colorText: "#BEBEBE",
    fontFamily: `Inter, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`,
    headerBg: "white",
  },
  components: {
    Layout: {
      headerBg: "#262626",
      siderBg: "#262626",
    },
    Button: {
      defaultHoverBg: "none",
      boxShadow: "none",
      primaryShadow: "none",
    },
    TreeSelect: {
      nodeSelectedBg: "#363636",
    },
    Spin: {
      colorPrimary: "rgb(255, 255, 255)",
    },
    Input: {
      colorTextPlaceholder: "rgba(255, 255, 255, 0.5)",
      activeBg: "#262626",
      hoverBg: "#262626",
    },
    Switch: {
      color: "#ff154d",
      colorTextTertiary: "#ff154d",
      colorPrimaryHover: "#E2063B",
      handleBg: "#1e1e1e",
    },
    InputNumber: {
      color: "white",
      colorTextBase: "white",
      colorText: "white",
      colorTextPlaceholder: "rgba(255, 255, 255, 0.5)",
      fontWeightStrong: 400,
    },
    Radio: {
      buttonCheckedBg: "#BEBEBE",
      buttonSolidCheckedBg: "#BEBEBE",
      buttonSolidCheckedHoverBg: "#BEBEBE",
      buttonBg: "transparent",
      buttonColor: "#BEBEBE",
    },
    Cascader: {
      optionSelectedBg: "#363636",
      controlItemBgHover: "#262626",
    },
    Tag: {
      defaultColor: "#BEBEBE",
    },
  },
  cssVar: true,
} as ThemeConfig;

export default BlingsTheme;
