import { useCallback, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Button } from "antd";
import { AddOutlinedIcon } from "../../icons-and-animations/Icons";
import { flowsStore } from "../../stores/FlowStore";
import { FlowHeader } from "./FlowHeader";
import { Flow as SortableScenesList } from "./Flow";
import styles from "./styles.module.scss";
import classNames from "classnames";
import confirmationModal from "../BlingsModal/CustomConfirm";

export const FlowDiagram = observer(() => {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const {
    selectedFlow,
    computedCurrentLineupId,
    flows,
    isInEdit,
    hasChangesToSave,
  } = flowsStore;

  const handleBlur = async (event: React.FocusEvent<HTMLDivElement>) => {
    if (
      hasChangesToSave &&
      event.relatedTarget &&
      (event.relatedTarget?.id === "modal-cancel" ||
        event.relatedTarget?.id === "modal-ok")
    ) {
      return;
    }

    if (
      containerRef.current &&
      !containerRef.current.contains(event.relatedTarget as Node) &&
      event.relatedTarget?.role !== "menuitem" &&
      event.target?.role !== "menuitem"
    ) {
      if (!flowsStore.isSelectedFlowValid) {
        return;
      }
    }
    if (
      containerRef.current &&
      (containerRef.current.contains(event.relatedTarget as Node) ||
        event.relatedTarget?.role === "menuitem" ||
        event.target?.role === "menuitem")
    ) {
      if (
        event.relatedTarget &&
        (event.target.tagName === "INPUT" ||
          event.target.tagName === "BUTTON" ||
          event.relatedTarget.tagName === "BUTTON" ||
          event.relatedTarget.tagName === "INPUT")
      ) {
        return;
      } else {
        containerRef.current.focus();
      }
      return; // Ignore onBlur if focus is still within the container
    }

    flowsStore.setInEdit(false);
  };

  const handleSelectFlow = useCallback(
    async (id?: string) => {
      if (flows.findIndex((flow) => flow.id === id) < 0) {
        return;
      }
      if (!flows) {
        return;
      }
      if (!flowsStore.validteFlows && selectedFlow?.id !== id) {
        const confirmed = await confirmationModal.confirm(
          'To create or edit another flow, either add scenes to "' +
            computedCurrentLineupId +
            '" Flow or delete it.',
          "Delete the current flow"
        );
        if (confirmed && selectedFlow) {
          flowsStore.deleteFlow(selectedFlow?.id);
        }
        return;
      }

      flowsStore.setSelectedFlow(id);
      flowsStore.setInEdit(true);
    },
    [selectedFlow, flows, computedCurrentLineupId]
  );

  return (
    <div
      className={styles["flow-diagram-container"]}
      ref={containerRef}
      onBlur={handleBlur}
      tabIndex={0}
    >
      {flows.length === 0 ? (
        <div className={styles["no-flows"]}>
          <Button
            type="text"
            style={{ color: "#BEBEBE" }}
            onClick={flowsStore.addFlowAndSelect}
          >
            <AddOutlinedIcon />
            Get started by building your first Flow right here
          </Button>
        </div>
      ) : (
        <div className={styles.flowsTable} ref={scrollContainerRef}>
          {flows.map((flow) => (
            <div
              className={classNames(
                styles.flowRow,
                flow.id === selectedFlow?.id && isInEdit
                  ? styles.activeFlow
                  : ""
              )}
              key={flow.id}
              onClick={() => {
                handleSelectFlow(flow?.id);
              }}
            >
              <div className={styles.stickyColumn}>
                <FlowHeader
                  flow={flow}
                  isActive={flow.id === selectedFlow?.id}
                />
              </div>
              <div
                className={styles.scenesList}
                style={{
                  background:
                    flow.id === selectedFlow?.id && isInEdit
                      ? "#1e1e1e"
                      : "#262626",
                }}
              >
                <SortableScenesList
                  flow={flow}
                  flowScenes={flow.scenes}
                  isActive={flow.id === selectedFlow?.id && isInEdit}
                  scrollContainerRef={scrollContainerRef}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
});
