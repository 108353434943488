import * as React from "react";
import { Button, Spin } from "antd";
import { AsyncOpState } from "../stores/async-op-state";
import { ButtonProps } from "antd/lib/button/button";

interface Props extends ButtonProps {
  asyncState: AsyncOpState;
  btnText: string | React.ReactNode;
  btnTextError?: string | React.ReactNode;
  btnTextSuccess?: string | React.ReactNode;
}

export const AsyncStateBtn = ({
  asyncState,
  btnText,
  btnTextError,
  btnTextSuccess,
  ...btnProps
}: Props) => {
  return (
    <Button {...btnProps}>
      {(() => {
        switch (asyncState) {
          case AsyncOpState.Changed:
            return btnText;
          case AsyncOpState.Error:
            return btnTextError || "Error";
          case AsyncOpState.Success:
            return btnTextSuccess || "Done";
          case AsyncOpState.Saving:
            return <Spin size="small" />;
        }
      })()}
    </Button>
  );
};
