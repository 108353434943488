import { Col, Row, Input } from "antd";
import { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import "./GalleryList.scss";
import { ProjectCard } from "../ProjectCard/ProjectCard";
import { IProjectInfo } from "../ProjectsGallery";
import { fetchUserAttributes } from "aws-amplify/auth";
import portalScreenshot from "../../../assets/portal-screenshot.png";
import { SearchIcon } from "../../../icons-and-animations/Icons";

export interface GalleryListProps {
  recentProjects: IProjectInfo[];
}

export default function GalleryList(props: GalleryListProps) {
  const [recentProjects, setRecentProjects] = useState<IProjectInfo[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<IProjectInfo[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [email, setEmail] = useState<string | undefined>("");

  useEffect(() => {
    setRecentProjects(props.recentProjects);
    setFilteredProjects(props.recentProjects);
  }, [props.recentProjects]);

  const filterProjects = useCallback(
    debounce(() => {
      if (searchTerm) {
        const filtered = recentProjects.filter((project) =>
          project.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredProjects(filtered);
      } else {
        setFilteredProjects(recentProjects);
      }
    }, 300),
    [recentProjects, searchTerm]
  );

  useEffect(() => {
    filterProjects();
    return filterProjects.cancel;
  }, [filterProjects]);

  useEffect(() => {
    fetchUserAttributes().then((attributes) => {
      setEmail(attributes?.email);
    });
  }, []);
  return (
    <div className={"gallery-list-container"}>
      <div className="top-bar">
        <div className="top-bar-info-item">
          <div className="level-1">Welcome to the studio</div>
          <div className="level-2">{email}</div>
        </div>
        <div className="extra-info">
          <div
            className="custom-card"
            onClick={() => window.open("https://portal.blings.io", "_blank")}
          >
            <div className="card-body">
              <div className="card-meta-title">Blings.io Portal is live</div>
              <div className="card-meta-description">Learn more</div>
            </div>
            <div className="image-container">
              <img
                src={portalScreenshot}
                alt={"Portal Screenshot"}
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={"gallery-list-header"}>
        <div className="gallery-list-header-text">
          <div className="level-1">Recent Projects</div>
          <div className="level-2">Click on a project to edit</div>
        </div>
        <Input
          size="large"
          className="project-search-input"
          placeholder="Search projects"
          suffix={<SearchIcon />}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            filterProjects();
          }}
        />
      </div>
      <Row gutter={16}>
        {filteredProjects.map((project) => (
          <Col span={6} key={project.key}>
            <ProjectCard
              title={project.title}
              thumbnail={project.thumbnail}
              updatedAt={project.updatedAt}
              id={project.id}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
}
