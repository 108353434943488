import { Input } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useMst } from "../../stores/main";
import "./FrameIndicator.scss";

export const FrameIndicator = observer(() => {
  const {
    playerStore: { currentFrame, totalFrames, player },
  } = useMst();
  const [value, setValue] = useState("");
  const [hovering, setHovering] = useState(false);
  if (!player) {
    return null;
  }
  function out() {
    setHovering(false);
  }
  function enter() {
    setHovering(true);
    setValue(currentFrame.toString());
  }
  function change(e) {
    setValue((e.target as HTMLInputElement).value);
    player?.seekTo(parseInt((e.target as HTMLInputElement).value));
  }
  return (
    <div className="FrameIndicator">
      <Input
        style={{ width: "80px" }}
        type="number"
        min="0"
        max={totalFrames}
        onMouseOut={out}
        onMouseEnter={enter}
        onChange={change}
        value={hovering ? value : currentFrame.toString()}
        className="FrameIndicatorInput"
      ></Input>
      / {totalFrames}
    </div>
  );
});
