import { modTypes } from "@blings/blings-player";
import { IModModel } from "../../stores/ModsStore";
import { IDynamicDataModel } from "../../stores/DynamicDataStore";
import { get } from "lodash";
import { IPlatformModel } from "../../stores/PlatformStore";

const alreadyTestedURLs = new Map();

export const checkModDataConnectionErrors = async (
  mod: IModModel,
  dynamicDataStore: IDynamicDataModel,
  platformStore: IPlatformModel
) => {
  switch (mod.moddata.type) {
    case modTypes.text:
      return checkTextModErrors(mod, dynamicDataStore, platformStore);
    case modTypes.asset:
      return await checkAssetModErrors(mod, dynamicDataStore, platformStore);
    case modTypes.themeColor:
      return checkColorModErrors(mod, dynamicDataStore, platformStore);
    case modTypes.interactiveConcat:
      return checkInteractiveConcatModErrors(mod, platformStore);
    case modTypes.interactiveForm:
      return checkInteractiveFormModErrors(mod, platformStore);
  }
  return null;
};
const checkDynamicValueModErrors = (
  mod: IModModel,
  dynamicDataStore: IDynamicDataModel,
  platformStore: IPlatformModel
) => {
  const errors: Array<string> = [];
  const { data, type } = getValueFromDynamicData(
    dynamicDataStore,
    platformStore,
    mod
  );
  if (type === "err") {
    errors.push(...data);
  } else if (data === null) errors.push("Value is not valid");
  return errors;
};
const checkTextModErrors = (
  mod: IModModel,
  dynamicDataStore: IDynamicDataModel,
  platformStore: IPlatformModel
) => {
  const errors = checkDynamicValueModErrors(
    mod,
    dynamicDataStore,
    platformStore
  );
  return errors.length ? errors : null;
};
const checkAssetModErrors = async (
  mod: IModModel,
  dynamicDataStore: IDynamicDataModel,
  platformStore: IPlatformModel
) => {
  const errors = checkDynamicValueModErrors(
    mod,
    dynamicDataStore,
    platformStore
  );
  const { value } = mod.moddata;
  const { data, type } = getValueFromDynamicData(
    dynamicDataStore,
    platformStore,
    mod
  );
  if (data) {
    let fileExist = 0;
    // If a return exists in the dynamic value, it means it is a function
    if (type === "expression") {
      try {
        const result = await getEvalFunc(
          data,
          JSON.stringify(dynamicDataStore.perVideoCurrentData),
          "''",
          JSON.stringify(dynamicDataStore.liveControlCurrentData)
        );
        // If the result exists in the already tested URLs, it means it has already been tested
        if (alreadyTestedURLs.has(result))
          fileExist = alreadyTestedURLs.get(result);
        else fileExist = await checkFileExists(result);
      } catch (e) {
        fileExist = -255;
        errors.push("Error in expression");
      }
    } else {
      if (alreadyTestedURLs.has(data)) fileExist = alreadyTestedURLs.get(data);
      else fileExist = await checkFileExists(data);
    }
    switch (fileExist) {
      case 0:
        alreadyTestedURLs.set(data, 0);
        break;
      case -1:
        alreadyTestedURLs.set(data, -1);
        errors.push("Asset URL is not valid");
        break;
      case -255:
        alreadyTestedURLs.set(data, -255);
        errors.push("Error in expression");
        break;
    }
  } else if (value === "") errors.push("Asset URL is empty");
  return errors.length ? errors : null;
};

const checkColorModErrors = (
  mod: IModModel,
  dynamicDataStore: IDynamicDataModel,
  platformStore: IPlatformModel
) => {
  const errors = checkDynamicValueModErrors(
    mod,
    dynamicDataStore,
    platformStore
  );
  const { data } = getValueFromDynamicData(
    dynamicDataStore,
    platformStore,
    mod
  );
  if (data) {
    const validColor = isValidHexColorCode(data);
    if (!validColor) errors.push("Color code is not valid");
  }
  return errors.length ? errors : null;
};

const checkInteractiveConcatModErrors = (
  mod: IModModel,
  platformStore: IPlatformModel
) => {
  const errors: Array<string> = [];
  const { value, functionString } = mod.moddata;
  const scenes = new Set(
    platformStore.projectWorkspaceVersion?.videoParts?.map(
      (videoPart) => videoPart.name
    )
  );
  if (functionString) {
    return null;
  }
  value?.forEach((scene) => {
    if (!scenes.has(scene)) {
      errors.push(`Scene ${scene} does not exist`);
    }
  });
  if (!value.length) errors.push("No scenes selected");
  return errors.length ? errors : null;
};
const checkInteractiveFormModErrors = (
  mod: IModModel,
  platformStore: IPlatformModel
) => {
  const errors: Array<string> = [];
  const { inputKeys } = mod.moddata;
  const { inputNames } = platformStore;
  if (!inputKeys) return ["No inputs selected"];
  inputKeys.forEach((inputName) => {
    if (!inputNames.includes(inputName)) {
      errors.push(`Input ${inputName} does not exist`);
    }
  });
  if (!inputKeys.length) errors.push("No inputs selected");
  return errors.length ? errors : null;
};
function checkFileExists(url: string) {
  return fetch(`https://cors.blings.io/${url.trim()}`, {
    method: "HEAD",
  })
    .then((response) => {
      if (
        response.ok &&
        (response.headers.get("content-type")?.includes("image") ||
          response.headers.get("content-type")?.includes("video") ||
          response.headers.get("content-type")?.includes("audio"))
      ) {
        return 0; // File exists
      } else if (response.ok) {
        return -1; // File exists but is not an image
      } else {
        return -255; // File does not exist
      }
    })
    .catch(() => {
      return -255; // Error occurred, file does not exist
    });
}

function isValidHexColorCode(colorCode: string) {
  const hexColorRegex = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;
  return hexColorRegex.test(colorCode);
}

function getValueFromDynamicData(
  dynamicDataStore: IDynamicDataModel,
  platformStore: IPlatformModel,
  mod: IModModel
) {
  const { moddata } = mod;
  if (moddata.dataKey) {
    const { dataKey } = moddata;
    const currentData = dynamicDataStore.perVideoCurrentData;
    const data = get(currentData, dataKey);
    if (data === undefined) {
      return {
        data: "Invalid data key",
        type: "err",
      };
    }
    return {
      data,
      type: "dataKey",
    };
  } else if (moddata.liveControlKey) {
    const { liveControlKey } = moddata;
    const currentData = dynamicDataStore.liveControlCurrentData;
    const data = get(currentData, liveControlKey);
    return {
      data,
      type: "liveControlKey",
    };
  } else if (moddata.value != null) {
    const { value } = moddata;
    return {
      data: value,
      type: "value",
    };
  } else if (moddata.expression != null) {
    const { expression } = moddata;
    return {
      data: expression,
      type: "expression",
    };
  } else if (moddata.inputName != null) {
    const { inputName } = moddata;
    const { inputNames } = platformStore;
    if (!inputNames.includes(inputName)) {
      return {
        data: "Invalid input name",
        type: "err",
      };
    }
    return {
      data: "",
      type: "inputName",
    };
  }
  return {
    data: null,
    type: null,
  };
}

function getEvalFunc(
  expression: string,
  data: string,
  inputs: string,
  control: string
) {
  // todo: DB stored value should be the full expression, not only the internals of that, similar to interactive connector.
  // make sure to have backward compatibility

  const toEvalStrFunc = `
  var d = ${data};
  var inp = ${inputs};
  var c = ${control};
  
  (async function x(data, inputs, control) {
    ${expression}
  })(d,inp,c);
`;
  return eval(toEvalStrFunc);
}
