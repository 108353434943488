import { NotificationStatus } from "../../API";

export type NotificationSendType = "Normal" | "Instant" | "All";

/**
 * The notification type determines the main type that the notification is (system, user, render, etc...)
 */
export enum NotificationType {
  /**
   * System notifications are only sent when there's updates or errors in the Blings system
   * These notifications shall only be sent as normal notifications
   */
  System = "System",
  /**
   * Render notifications are sent when there's a new render that is being processed or finished
   * When the render finishes, the notification shall be send as a normal notification, otherwise it shall be sent as an instant notification
   */
  Render = "Render",
  /**
   * Notification about analytics
   */
  Analitycs = "Analitycs",
  /**
   * Notifications about extensions
   */
  Extension = "Extension",
  /**
   * Other types of notifications
   */
  Other = "Other",
}

export interface NormalNotification {
  id: string;
  type: NotificationType;
  subType?: string;
  status: NotificationStatus;
  title: string;
  message: string;
  read: boolean;
  owner?: string;
  projectId?: string;
  extra?: any;
  createdAt: string;
  updatedAt?: string;
}

export interface InstantNotification {
  type: NotificationType;
  subType?: string;
  status: NotificationStatus;
  title: string;
  message: string;
  owner: string;
  projectId?: string;
  extra?: any;
}
