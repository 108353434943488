import { IGroupsDict, dropDownGroups } from "./mod-groups-definitions";
import { Def, modsDefinitions, modDefinitionsArr } from "./mod-definitions";

// combine mods to groups by ux logic
const modDefsOrGroups = modDefinitionsArr.reduce<Array<Def<any> | IGroupsDict>>(
  (modButtonsDefs, modButtonDefinition) => {
    if (modButtonDefinition.hide) {
      return modButtonsDefs;
    }

    if (modButtonDefinition.dropDownGroup) {
      const group = modButtonsDefs.find(
        (defOrGroup) =>
          (defOrGroup as IGroupsDict).name === modButtonDefinition.dropDownGroup
      );
      if (group) {
        (group as IGroupsDict).defs.push(modButtonDefinition);
      } else {
        modButtonsDefs.push({
          type: "dropDownGroup",
          name: modButtonDefinition.dropDownGroup,
          defs: [modButtonDefinition],
        });
      }
      return modButtonsDefs;
    }

    modButtonsDefs.push(modButtonDefinition);

    return modButtonsDefs;
  },
  []
);

export { modDefsOrGroups, modsDefinitions, dropDownGroups };

export type { Def, IGroupsDict };
