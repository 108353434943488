import * as React from "react";
import { Divider } from "antd";
import { theme } from "../theme";

type Props = {};

export function HorizontalDivider(props: Props) {
  return (
    <Divider
      style={{
        borderTopColor: theme.blings_teal,
        margin: "0.2rem 0",
      }}
    />
  );
}
