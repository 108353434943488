import { useContext, useEffect, useState } from "react";
import { Typography } from "antd";
import { observer } from "mobx-react-lite";
import { MSTContext } from "../../stores/main";
import { EazyLoader } from "../EazyLoader";
import GalleryList from "./GalleryList/GalleryList";

import "./ProjectsGallery.scss";

export type IProjectInfo = {
  thumbnail: string;
  title: string;
  updatedAt: string;
  id: string;
  key?: string;
};

function ProjectsGallery() {
  const {
    platformStore: { allProjects },
    setCurrentTab,
  } = useContext(MSTContext);
  // State declaration
  const [showGallery, setShowGallery] = useState(false);

  // callback declaration

  // useEffect declaration
  useEffect(() => {
    if (allProjects.length > 0) {
      setShowGallery(true);
    }
  }, [allProjects.length]);

  useEffect(() => {
    setCurrentTab("");
  }, []);

  // other logic
  const userHasMultipleAccounts = allProjects.some(
    (p) => p.projectAccountId !== allProjects[0].projectAccountId
  );
  const pjOptions: IProjectInfo[] = allProjects.map((p) => ({
    title:
      p.title + (userHasMultipleAccounts ? ` | ${p.projectAccountId}` : ""),
    thumbnail: p.thumbS3Url,
    updatedAt: p.updatedAt,
    key: p.id,
    id: p.id,
  }));

  const _allprojects: IProjectInfo[] = JSON.parse(
    JSON.stringify(pjOptions)
  ).sort(function (a, b) {
    // sort projects alphabeticlly
    const aName = a.title ? a.title : a.thumbnail;
    const bName = b.title ? b.title : b.thumbnail;
    if (aName.toLocaleLowerCase() < bName.toLocaleLowerCase()) {
      return -1;
    } else {
      return 1;
    }
  });
  const projectsSortedByUpdateTime = JSON.parse(
    JSON.stringify(_allprojects)
  ).sort(function (a, b) {
    // sort projects by most recent
    const aDate = new Date(a.updatedAt);
    const bDate = new Date(b.updatedAt);
    if (aDate < bDate) {
      return 1;
    } else {
      return -1;
    }
  });

  return (
    <div className="project-galery-container">
      {!showGallery ? (
        <div className="project-gallery-spinner mt-8-center">
          <EazyLoader size={400} isLoop={true} />
          <Typography.Title level={3}>Loading Projects</Typography.Title>
        </div>
      ) : (
        <GalleryList recentProjects={projectsSortedByUpdateTime} />
      )}
    </div>
  );
}

export default observer(ProjectsGallery);
