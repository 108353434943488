export function getFonts(jsonFile): string[] {
  let googleFams: string[] = [];
  if (jsonFile["fonts"]) {
    const fontObj = jsonFile["fonts"]["list"];
    for (let f of fontObj) {
      const fontFam: string = f["fFamily"].replace(/([a-z])([A-Z])/g, "$1 $2");
      if (googleFams.indexOf(fontFam) === -1) {
        googleFams.push(fontFam);
      }
    }
  }

  return googleFams;
}
