import { IThemeColorChangeMod } from "@blings/blings-player";
import { Space } from "antd";
import { ModsPanelCompProps } from "../../ModsPanel";
import { DynamicValueMod, DynamicValueModTypes } from "../../DynamicValueMod";
import { LayerSelector } from "../../../LayerSelector";
import { InfoTooltip } from "../../../../BaseComps/InfoTooltip";
import {
  getLayersWithColorArray,
  getRelevantLayersColors,
} from "./ColorModsHelpers";
import { ByLayersSwitch } from "./ByLayersSwitch";
import { HEXColorSelector } from "./HEXColorSelector";

export function byLayerThemeColorChangeForm(
  props: ModsPanelCompProps<IThemeColorChangeMod>
) {
  const { change, json } = props;
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Space direction={"vertical"} style={{}} size={"middle"}>
        <div>
          <ByLayersSwitch
            change={change}
            json={json}
            index={props.index}
            onLayerChange={props.onLayerChange}
            onChange={props.onChange}
          />
          <InfoTooltip
            info={
              "Choose whether the color change will affect the selected layers or globally."
            }
          />
        </div>
        {"isLayers" in change && change.isLayers ? (
          <LayerSelector
            isMulti={true}
            assetLayerPairs={props.assetLayerPairs}
            selectedAssetId={change.assetId}
            selectedLayerName={change.layerName}
            selectedLayerUid={change.layerUid}
            selectedAdditionals={change.additionals}
            jsonVid={json}
            onChange={(index, assetId, layerName, layerUid, additionals) => {
              props.onLayerChange(
                index,
                assetId,
                layerName,
                layerUid,
                additionals
              );
              props.onChange(
                props.index,
                "froms",
                change.froms.filter((s) => {
                  return getRelevantLayersColors(json, change).some((color) => {
                    return JSON.stringify(color) === JSON.stringify(s);
                  });
                })
              );
            }}
            index={props.index}
            mapFilterLayerFunc={(
              p,
              layerName,
              assetId,
              layerUid,
              additionals
            ) =>
              getLayersWithColorArray(
                p,
                layerName,
                assetId,
                layerUid,
                additionals,
                json
              )
            }
          />
        ) : null}

        <HEXColorSelector
          change={change}
          json={json}
          onChange={props.onChange}
          index={props.index}
          mode={
            // @ts-ignore
            change.isLayers ? "LAYER" : "GLOBAL"
          }
        />
      </Space>
      <DynamicValueMod
        change={props.change}
        onChange={props.onChange}
        index={props.index}
        type={DynamicValueModTypes.hex}
      />
    </div>
  );
}
