import React, { useState } from "react";
import { Upload, Progress, message, Input } from "antd";
import {
  InboxOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { RcFile } from "antd/es/upload/interface";
import "./Upload.scss"; // Import the SCSS file
import { UploadIcon } from "../icons-and-animations/Icons";

interface PropsUpload {
  uploadAssetsToProject: (
    file: RcFile,
    fileType: string,
    callback: (key: string) => void,
    pcb?: any
  ) => Promise<void>;
  onChange: (key: string) => void;
  value: string;
  placeholder: string;
  mimeType?: string;
}

const UploadWithTextarea: React.FC<PropsUpload> = ({
  uploadAssetsToProject,
  onChange,
  mimeType,
  value,
  placeholder,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const pcb = (progress: {
    loaded: number;
    total: number;
    errorMessage?: string;
    errorTimeOut?: number;
  }) => {
    if (progress.errorMessage) {
      setProgress(0);
    } else {
      const num = (progress.loaded / progress.total) * 100;
      setProgress(parseFloat(num.toFixed(0)));
    }
  };

  const customUpload = async ({ file, onError, onSuccess }: any) => {
    const f = file as RcFile;
    const { type } = f;
    setIsUploading(true);

    await uploadAssetsToProject(
      f,
      (type || "unknown").split("/")[0],
      (key) => {
        setIsUploading(false);
        setProgress(100); // Simulating the completion of progress
        onChange(key);
        onSuccess && onSuccess(file);
        setTimeout(() => setProgress(0), 1000); // Reset progress after a short delay
      },
      pcb
    ).catch((e) => {
      setIsUploading(false);
      onError && onError(e);
    });
  };

  const mimeTypeDefault =
    mimeType ||
    "audio/mp4, audio/mp3, image/jpeg, image/png, image/webp, application/x-mpegURL";

  return (
    <div className="upload-textarea-container">
      <div style={{ position: "relative" }}>
        <Input
          size="large"
          className="custom-textarea"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
        <Upload
          accept={mimeTypeDefault}
          className="upload-button"
          showUploadList={false}
          customRequest={customUpload}
        >
          <div className="upload-icon-container">
            {isUploading ? (
              <LoadingOutlined className="upload-icon" />
            ) : (
              <UploadIcon />
            )}
          </div>
        </Upload>
      </div>
      {progress > 0 && (
        <Progress
          percent={progress}
          size="small"
          status="active"
          style={{ marginTop: "20px" }}
        />
      )}
    </div>
  );
};

export default UploadWithTextarea;
