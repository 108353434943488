import { TextChangeForm } from "../modForms/TextChangeForm";
import { CustomChangeForm } from "../modForms/CustomChangeForm";
import { byLayerThemeColorChangeForm } from "../modForms/ColorModForms/ByLayerThemeColorChangeForm";
import { AssetChangeForm } from "../modForms/AssetChangeForm";
import { HideLayerForm } from "../modForms/HideLayerForm";
import { AddInteractiveOpenForm } from "../modForms/interactiveForms/AddInteractiveOpenForm";
import { BGVideoForm } from "../modForms/MediaModForms/bgVideoForm";
import { BGAudioForm } from "../modForms/MediaModForms/bgAudioForm";
import colorAnimationData from "../../../icons-and-animations/ConnectorBtns/Color_mode.json";
import mediaAssetAnimationData from "../../../icons-and-animations/ConnectorBtns/Image_mode.json";
import textAnimationData from "../../../icons-and-animations/ConnectorBtns/text_mode.json";
import { InfoTooltip } from "../../../BaseComps/InfoTooltip";
import {
  ITextChangeMod,
  IBGVideoMod,
  IBGAudioMod,
  ICustomChangeMod,
  IHideLayerChangeMod,
  IThemeColorChangeMod,
  IAssetChangeMod,
  ImageAlign,
  IMods,
  IInteractiveModGoto,
  IInteractiveModOpen,
  modTypes,
  interactiveGotoTypes,
  IDynamicChangeMod,
  IInteractiveModConcat,
  IAdvancedConfigMod,
  IInteractiveModJSExpression,
  IOnPlayerEvent,
  ITransitionTimingMod,
  IInteractiveModInput,
  IInteractiveModForm,
  ICountdownChangeMod,
  ITransformChangeMod,
  transformChangeTypes,
} from "@blings/blings-player";
import React from "react";
import { observer } from "mobx-react-lite";
import { AddInteractiveGotoForm } from "../modForms/interactiveForms/AddInteractiveGoToForm";
import { AddInteractiveConcatForm } from "../modForms/interactiveForms/AddInteractiveConcatForm";
import { ConfigModForm } from "../modForms/ConfigModForm";
import { dropDownGroups, dropDownGroupsKeys } from "./mod-groups-definitions";
import { AddInteractionJsExpressionForm } from "../modForms/interactiveForms/AddInteractionJSExpressionForm";
import { PlayerEventForm } from "../modForms/PlayerEventForm";
import { TransitionModForm } from "../modForms/TransitionModForm";
import { theme } from "../../../theme";
import { getDynamicValueTypeIcon } from "../dynamicValueModsIcon";
import { rootStore } from "../../../stores/main";
import { AddInteractiveInputForm } from "../modForms/interactiveForms/AddInteractiveInputForm";
import { getColorTitles } from "./colorTitles";
import { InputFormForm } from "../modForms/InputFormForm";
import { CountdownChangeForm } from "../modForms/CountdownChangeForm";
import { TransformChangeForm } from "../modForms/TransformChangeForm";
import { isAbsolute } from "path";
export interface Def<T extends IMods> {
  text: string;
  ChangeFormComp: React.ElementType;
  formTitle: (c) => string;
  formTitle2?: (c) => [string | JSX.Element, ((a: any) => JSX.Element)?]; // text + icon
  change: T;
  color: string;
  Icon?: any;
  disabled?: boolean | ((selectedProject) => boolean);
  dropDownGroup?: dropDownGroupsKeys;
  hide?: boolean;
  animation?: any;
  fromVersion?: string;
}

function getValueTitle(
  change: IDynamicChangeMod<any, any>,
  textFunc: (changeText: string) => JSX.Element | string = (t) => t
): [string | JSX.Element, (a: any) => JSX.Element] {
  let typeIcon;
  if (change.value && change.exposeToPlatform) typeIcon = "exposeToPlatform";
  else if (change.value) typeIcon = "value";
  else if (change.dataKey) typeIcon = "dataKey";
  else if (change.expression) typeIcon = "expression";
  else if (change.liveControlKey) typeIcon = "liveControlKey";
  else if (change.inputName) typeIcon = "inputName";

  if (change.experimentId !== undefined) {
    const option = rootStore.experimentStore.getVariantByIndex(
      change.experimentId,
      0
    );
    return [
      option?.value ? textFunc(option?.value.toString()) : "",
      typeIcon && getDynamicValueTypeIcon(typeIcon),
    ];
  } else {
    // if(typeof change.value !== 'undefined') { return 'EditIcon' }
    const changeText =
      change.value ||
      change.dataKey ||
      change.liveControlKey ||
      change.inputName;

    return [
      changeText ? textFunc(changeText.toString()) : "",
      typeIcon && getDynamicValueTypeIcon(typeIcon),
    ];
  }
}
function getLayersTitle(change) {
  if (change.additionals && change.additionals.length) {
    return `${change.layerName} (+${change.additionals.length})`;
    // return change.layerName + " + " + change.additionals.length + "Layers";
  }
  return change.layerName;
}

export const modsDefinitions = {
  [modTypes.text]: {
    text: "Text",
    ChangeFormComp: observer(TextChangeForm),
    formTitle: (change) => getLayersTitle(change) || "Text",
    formTitle2: getValueTitle,
    color: theme.connectors.text,
    animation: textAnimationData,
    Icon: textAnimationData,
    change: {
      type: modTypes.text,
      assetId: undefined,
      layerName: "",
      layerUid: undefined,
      maxChars: undefined,
      verticalAlign: undefined,
      fontSize: undefined,
      center: false,
      dontTrim: false,
      caseAdjustment: "",
    },
  } as Def<ITextChangeMod>,

  [modTypes.asset]: {
    text: "Media",
    color: theme.connectors.mediaAsset,
    animation: mediaAssetAnimationData,
    Icon: mediaAssetAnimationData,
    ChangeFormComp: observer(AssetChangeForm),
    formTitle: (change: IAssetChangeMod) =>
      rootStore.platformStore.getOriginalNameOfAsset(change.imageName) ||
      "Media",
    formTitle2: getValueTitle,
    change: {
      type: modTypes.asset,
      imageName: "",
      changeSceneDurationToMediaDuration: false,
      align: ImageAlign.XMidYMid,
      slice: true,
      layerName: "",
    },
  } as Def<IAssetChangeMod>,

  [modTypes.themeColor]: {
    text: "Color",
    ChangeFormComp: observer(byLayerThemeColorChangeForm),
    animation: colorAnimationData,
    Icon: colorAnimationData,
    color: theme.connectors.color,
    formTitle: (change) => getLayersTitle(change) || "Color",
    formTitle2: (c) => getValueTitle(c, (t) => getColorTitles(c, t)),
    change: {
      isLayers: false,
      type: modTypes.themeColor,
      froms: [],
      to: "",
      layerName: "",
    },
  } as Def<IThemeColorChangeMod>,

  [modTypes.video]: {
    text: "Video",
    color: theme.connectors.vidAndAudio,
    ChangeFormComp: observer(BGVideoForm),
    formTitle: (change: IBGVideoMod) => `Video`,
    formTitle2: getValueTitle,
    change: {
      type: modTypes.video,
      startOnMarker: undefined,
      startAnimationFrame: undefined,
      stopAnimationFrame: undefined,
      offsetInSeconds: 0,
      shouldLoop: false,
      url: "",
      style: undefined,
      fullScreenStyle: undefined,
      layerName: "",
    },
    hide: true,
  } as Def<IBGVideoMod>,

  [modTypes.audio]: {
    text: "Audio",
    color: theme.connectors.vidAndAudio,
    ChangeFormComp: observer(BGAudioForm),
    formTitle: (change: IBGVideoMod) => `Audio`,
    formTitle2: getValueTitle,
    change: {
      type: modTypes.audio,
      startOnMarker: undefined,
      startAnimationFrame: undefined,
      stopAnimationFrame: undefined,
      offsetInSeconds: 0,
      shouldLoop: false,
      url: "",
      volume: 1,
      style: undefined,
      fullScreenStyle: undefined,
      layerName: "",
    },
    hide: true,
  } as Def<IBGAudioMod>,

  [modTypes.interactiveGoto]: {
    text: "Jump to frame",
    color: dropDownGroups.interaction.color, //"#1f6714",
    ChangeFormComp: observer(AddInteractiveGotoForm),
    formTitle: (change) => getLayersTitle(change) || "Jump Frame",
    formTitle2: (change) => [
      `${getValueTitle(change)[0]} (${change.gotoType})`,
      getValueTitle(change)[1],
    ],

    change: {
      event: "onclick",
      type: modTypes.interactiveGoto,
      gotoType: interactiveGotoTypes.frame,
      value: 100,
      assetId: "",
      layerName: "",
      layerUid: undefined,
    },
    dropDownGroup: "interaction",
  } as Def<IInteractiveModGoto>,

  [modTypes.interactiveOpen]: {
    text: "Open Link",
    color: dropDownGroups.interaction.color, //"#1f6714",
    ChangeFormComp: observer(AddInteractiveOpenForm),
    formTitle: (change) => getLayersTitle(change) || "Open Link",
    formTitle2: getValueTitle,

    change: {
      event: "onclick",
      type: modTypes.interactiveOpen,
      assetId: "",
      layerName: "",
      ctaName: "",
      layerUid: undefined,
    },
    dropDownGroup: "interaction",
  } as Def<IInteractiveModOpen>,

  [modTypes.interactiveConcat]: {
    text: "Dynamic Scenes",
    color: dropDownGroups.interaction.color, //"#1f6714",
    ChangeFormComp: observer(AddInteractiveConcatForm),

    formTitle: (change) => getLayersTitle(change) || "Dynamic Scenes",
    formTitle2: getValueTitle,
    change: {
      event: "onclick",
      type: modTypes.interactiveConcat,
      value: [],
      assetId: "",
      layerName: "",
      layerUid: undefined,
      jumpType: "sceneStart",
    },
    disabled: (selectedProject) => !selectedProject,
    dropDownGroup: "interaction",
  } as Def<IInteractiveModConcat>,

  [modTypes.interactiveJSExpression]: {
    text: "Expression",
    color: dropDownGroups.interaction.color, //"#1f6714",
    ChangeFormComp: observer(AddInteractionJsExpressionForm),
    formTitle: (change: IInteractiveModJSExpression) =>
      getLayersTitle(change) || "EXPRESSION",
    formTitle2: () => ["JS code"],
    change: {
      event: "onclick",
      type: modTypes.interactiveJSExpression,
      assetId: "",
      layerName: "",
      layerUid: undefined,
      functionString: "",
      ctaName: "",
    },
    disabled: (selectedProject) => !selectedProject,
    dropDownGroup: "interaction",
  } as Def<IInteractiveModJSExpression>,

  [modTypes.interactiveInput]: {
    text: "Input Field",
    color: theme.connectors.input,
    ChangeFormComp: observer(AddInteractiveInputForm),
    formTitle: (change) => getLayersTitle(change) || "Input",
    formTitle2: (change) => [change.value],

    change: {
      event: "onclick",
      type: modTypes.interactiveInput,
      value: "",
      placeholder: "",
      assetId: "",
      layerName: "",
      layerUid: undefined,
      isMultiline: false,
    },
    dropDownGroup: "interaction",
  } as Def<IInteractiveModInput>,

  [modTypes.interactiveForm]: {
    text: "Submit Form",
    color: theme.connectors.input,
    ChangeFormComp: observer(InputFormForm),
    formTitle: (change) => "Submit",
    formTitle2: (change) => [
      <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
        Submit
        <InfoTooltip info="Select the fields to be saved to the database." />
      </div>,
    ],

    change: {
      event: "onclick",
      type: modTypes.interactiveForm,
      value: "",
      placeholder: "",
      assetId: "",
      layerName: "",
      layerUid: undefined,
      inputKeys: [],
      sendToThirdParty: false,
    },
    dropDownGroup: "interaction",
    fromVersion: "4.8.0",
  } as Def<IInteractiveModForm>,

  [modTypes.hideLayer]: {
    text: "Layer Hide",
    ChangeFormComp: observer(HideLayerForm),
    // Icon: DeleteRowOutlined,
    color: theme.connectors.utils,
    // formTitle: (change: IHideLayerChangeMod) =>
    // (change.layerName || "N/A"),
    // `${change.layerName} => ${getValueTitle(change)}`,
    formTitle: (change) => getLayersTitle(change) || "Hide Layer",
    formTitle2: getValueTitle,

    change: {
      type: modTypes.hideLayer,
      assetId: "",
      layerName: "",
      layerUid: undefined,
      value: true,
    },
    dropDownGroup: "utils",
  } as Def<IHideLayerChangeMod>,

  [modTypes.countdown]: {
    text: "Countdown",
    ChangeFormComp: observer(CountdownChangeForm),
    // Icon: ClockCircleOutlined,
    color: theme.connectors.utils,
    formTitle: (change) => "Countdown",
    formTitle2: getValueTitle,
    change: {
      type: modTypes.countdown,
      assetId: "",
      layerName: "",
      timeFormatAndLayers: {
        days: {
          assetId: undefined,
          layerName: "",
          layerUid: undefined,
          additionals: [],
        },
        hours: {
          assetId: undefined,
          layerName: "",
          layerUid: undefined,
          additionals: [],
        },
        minutes: {
          assetId: undefined,
          layerName: "",
          layerUid: undefined,
          additionals: [],
        },
        seconds: {
          assetId: undefined,
          layerName: "",
          layerUid: undefined,
          additionals: [],
        },
      },
      showWhenExpired: {
        layerName: "",
        additionals: [],
      },
      hideWhenExpired: {
        layerName: "",
        additionals: [],
      },
      hasPadding: true,
    },
    dropDownGroup: "utils",
  } as Def<ICountdownChangeMod>,

  [modTypes.custom]: {
    text: "Custom Change",
    ChangeFormComp: observer(CustomChangeForm),
    color: theme.connectors.advanced,
    formTitle: (change) => getLayersTitle(change) || "Custom Change",
    formTitle2: getValueTitle,
    change: {
      type: modTypes.custom,
      assetId: undefined,
      layerName: "",
      layerUid: undefined,
      path: "",
      nvType: "string",
    } as ICustomChangeMod,
    dropDownGroup: "advanced",
  } as Def<ICustomChangeMod>,
  [modTypes.transform]: {
    text: "Transform",
    ChangeFormComp: observer(TransformChangeForm),
    color: theme.connectors.advanced,
    formTitle: (change) => getLayersTitle(change) || "Transform",
    formTitle2: (change) => {
      if (change.value == null || change.value === "")
        return getValueTitle(change);
      if (change.isAbsolute) {
        return [
          `${change.value >= 0 ? "+" + change.value : change.value}${
            change.transformChangeType === transformChangeTypes.rotation
              ? "°"
              : ""
          }`,
        ];
      } else {
        return [change.value + "%"];
      }
    },
    change: {
      type: modTypes.transform,
      assetId: undefined,
      layerName: "",
      layerUid: undefined,
      isAbsolute: false,
      transformChangeType: transformChangeTypes.positionX,
    } as ITransformChangeMod,
    dropDownGroup: "advanced",
  } as Def<ITransformChangeMod>,

  [modTypes.onPlayerEvent]: {
    color: theme.connectors.advanced,
    text: "Player-Event",
    ChangeFormComp: observer(PlayerEventForm),
    formTitle: (change: IOnPlayerEvent) => `Run JS code`,
    formTitle2: (change: IOnPlayerEvent) => [change.playerEvent],
    change: {
      type: modTypes.onPlayerEvent,
      playerEvent: "onAllReady",
      functionString: "",
      layerName: "",
    },
    dropDownGroup: "advanced",
  } as Def<IOnPlayerEvent>,

  [modTypes.transitionTiming]: {
    color: theme.connectors.utils,
    // Icon: ColumnWidthOutlined,
    text: "Scene Transition",
    ChangeFormComp: observer(TransitionModForm),
    formTitle: (change: ITransitionTimingMod) =>
      `In: ${change.ip || 0} frames Out: ${change.op || 0} frames`,
    change: {
      type: modTypes.transitionTiming,
      ip: 0,
      op: 0,
    },
    dropDownGroup: "utils",
  } as Def<ITransitionTimingMod>,
};

export const modDefinitionsArr: Def<any>[] = Object.keys(modsDefinitions).map(
  (key) => modsDefinitions[key]
);
