import { IThemeColorChangeMod } from "@blings/blings-player";
import { Select, Tag } from "antd";
import {
  darkTextColorIfBGisBright,
  getRelevantLayerColors,
  getRelevantLayersColors,
} from "./ColorModsHelpers";
import {
  gradientToMultipleHex,
  hexToRgba,
  rgbaArrToHexStr,
} from "../../../../helpers/colors";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
const { Option } = Select;

function tagRender(props) {
  const { label, closable, onClose } = props;
  const hexWithoutOpacity = label.slice(0, 7);
  return (
    <Tag
      color={hexWithoutOpacity}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        color: darkTextColorIfBGisBright(hexWithoutOpacity),
      }}
    >
      {label}
    </Tag>
  );
}

interface IHEXColorSelector {
  change: IThemeColorChangeMod;
  json: any;
  onChange: (...params) => void;
  index: number;
  mode?: "GLOBAL" | "LAYER" | undefined;
}
export const HEXColorSelector = observer((props: IHEXColorSelector) => {
  const { change, json, onChange, index, mode } = props;
  // Colors array state
  const [colorsArray, setColorsArray] = useState<string[]>([]);
  const [currentMode, setCurrentMode] = useState<string | undefined>(mode);
  const [oldValue, setOldValue] = useState<any>([]);
  const [newValue, setNewValue] = useState<any>([]);
  const [gradientLookup, setGradientLookup] = useState<any>({});

  // Colors dict state
  // #HEX -> [[colorsRGBA]]
  const [colorHexDict, setColorHexDict] = useState<any>({});

  // Select colors state
  const [selectColors, setSelectColors] = useState<any>(
    change.froms
      .map((c) => {
        // If it's already HEX, return it
        if (typeof c === "string") {
          return c;
        }
        // If it's RGBA, return it as HEX
        else {
          if (c.length >= 12) {
            // Get the current gradient color and stop
            const gradient = c.slice(0, c.length - 1) as ColorArr;
            const stop = c[c.length - 1];
            const correctIndex = Math.floor((stop - 1) / 4);

            // Get all the colors that are part of the gradient
            const multipleHex = gradientToMultipleHex(gradient, true);

            // Select the correct hex color to receive the current stop color
            const correctHex = multipleHex[correctIndex];
            return correctHex;
          } else {
            return rgbaArrToHexStr(c);
          }
        }
      })
      .filter(function (item, pos, self) {
        return self.indexOf(item) === pos;
      })
  );

  useEffect(() => {
    // Check if 'isLayers' property exists in 'change' and if it's true.
    if ("isLayers" in change && change.isLayers) {
      // If 'isLayers' is true, get relevant layers colors.
      var colors = getRelevantLayersColors(json, change);
    } else {
      // Otherwise, get relevant layer colors.
      var colors = getRelevantLayerColors(json);
    }
    // Process the colors.
    var processedColors = colors.map((v) => {
      // Convert the color value to a string representation.
      return JSON.stringify(v);
    });

    // Filter out duplicate string representations of colors.
    var uniqueColors = processedColors.filter(function (item, pos, self) {
      return self.indexOf(item) === pos;
    });

    // Return the unique colors.
    setColorsArray(uniqueColors);
  }, [props, change, json]);

  useEffect(() => {
    // Make a copy of the hex dict
    let _colorHexDict = {};
    let _gradientLookup = {};
    // For each color in the colors array
    for (let i = 0; i < colorsArray.length; i++) {
      const v = colorsArray[i];

      // Array of RGBA colors or already HEX
      const newV = JSON.parse(v);
      let RGBA: ColorArr = [0, 0, 0];
      let hex = "";

      // If it's already HEX, transform it to RGBA
      if (typeof newV === "string") {
        hex = newV;
        RGBA = hexToRgba(newV);
      } else {
        if (newV.length >= 12) {
          // Get the current gradient color and stop
          const gradient = newV.slice(0, newV.length - 1);
          const stop = newV[newV.length - 1];
          const correctIndex = Math.floor((stop - 1) / 4);

          // Get all the colors that are part of the gradient
          const multipleHex = gradientToMultipleHex(gradient, true);

          // Select the correct hex color to receive the current stop color
          const correctHex = multipleHex[correctIndex];

          // Set the hex and the RGBA
          hex = correctHex;
          RGBA = newV;

          // Set the gradient lookup
          _gradientLookup[rgbaArrToHexStr(newV, true)] = hex;
        } else {
          hex = rgbaArrToHexStr(newV);
          RGBA = newV;
        }
      }
      // If it's not in the dict, add it
      if (!(hex in _colorHexDict)) {
        _colorHexDict[hex] = [RGBA];
      }
      // If it's in the dict, add it to the array
      else {
        _colorHexDict[hex].push(RGBA);
      }
    }
    setColorHexDict(_colorHexDict);
    setGradientLookup(_gradientLookup);
  }, [colorsArray]);

  useEffect(() => {
    setCurrentMode(mode);
  }, [mode]);

  function areElementsEqual(elem1, elem2) {
    if (Array.isArray(elem1) && Array.isArray(elem2)) {
      return areArraysEqual(elem1, elem2); // Recursive call for sub-arrays
    } else {
      return elem1 === elem2; // Direct comparison for primitive types
    }
  }

  function areArraysEqual(array1, array2) {
    if (array1.length !== array2.length) {
      return false; // Different lengths, not equal
    }

    for (let i = 0; i < array1.length; i++) {
      if (!areElementsEqual(array1[i], array2[i])) {
        return false; // Elements (or sub-arrays) are not equal
      }
    }

    return true; // All elements/sub-arrays are equal
  }

  useEffect(() => {
    if (selectColors !== undefined) {
      let colorsToChange: any = [];
      if (Array.isArray(selectColors)) {
        colorsToChange = selectColors
          .map((v) => {
            // If it's already HEX it will start the string with a #, get the RGBA from the dict
            if (v.startsWith("#")) {
              return colorHexDict[v] ? colorHexDict[v] : v;
            }
            // If it's RGBA, return it
            else {
              return JSON.parse(v);
            }
          })
          .flat();
      } else {
        colorsToChange = selectColors;
        // If it's already HEX it will start the string with a #, get the RGBA from the dict
        if (selectColors.startsWith("#")) {
          colorsToChange = colorHexDict[selectColors]
            ? colorHexDict[selectColors]
            : selectColors;
        }
        // If it's RGBA, return it
        else {
          colorsToChange = JSON.parse(selectColors);
        }
      }

      // If every item is an array, call the change function
      if (!Array.isArray(colorsToChange)) colorsToChange = [colorsToChange];
      setNewValue(colorsToChange);
    }
  }, [colorHexDict, selectColors]);

  useEffect(() => {
    const areEqual = areArraysEqual(oldValue, newValue);
    if (!areEqual) {
      setOldValue(newValue);
      onChange(index, "froms", newValue);
    }
  }, [newValue]);

  return (
    <Select<string[]>
      mode="multiple"
      showArrow
      size={"large"}
      allowClear
      placeholder={"Select colors for change"}
      notFoundContent={
        <div className={"notFoundContent"}>
          {"isLayers" in change && change.isLayers
            ? "Selected layers has no colors"
            : "This project has no defined colors"}
        </div>
      }
      tagRender={tagRender}
      value={selectColors}
      style={{ width: "100%" }}
      onChange={(v) => {
        let _selectedColors: string[] = [];
        setSelectColors([]);
        let colorsToChange: any = [];
        colorsToChange = v
          .map((v) => {
            // If it's already HEX it will start the string with a #, get the RGBA from the dict
            if (v.startsWith("#")) {
              return colorHexDict[v] ? colorHexDict[v] : v;
            }
            // If it's RGBA, return it
            else {
              return JSON.parse(v);
            }
          })
          .flat();
        _selectedColors = colorsToChange
          .map((c) => {
            if (c === undefined) return;
            // If it's already HEX, return it
            if (typeof c === "string") {
              return c;
            }
            // If it's RGBA, return it as HEX
            else {
              return rgbaArrToHexStr(c);
            }
          })
          .filter(function (item, pos, self) {
            return self.indexOf(item) === pos;
          });
        for (let i = 0; i < _selectedColors.length; i++) {
          if (_selectedColors[i].length >= 12) {
            _selectedColors[i] = gradientLookup[_selectedColors[i]];
          }
        }

        // Filter out duplicate colors
        _selectedColors = _selectedColors.filter(function (item, pos, self) {
          return self.indexOf(item) === pos;
        });
        setSelectColors(
          _selectedColors.length > 1 ? _selectedColors : _selectedColors[0]
        );

        onChange(
          index,
          "froms",
          colorsToChange
          //v.map((v) => JSON.parse(v))
        );
      }}
    >
      {
        // Add the keys of the dictionary as options
        Object.keys(colorHexDict).map((hex) => {
          const RGBA = colorHexDict[hex];
          const hexWithoutOpacity = hex.slice(0, 7);
          return (
            <Option
              key={hex}
              value={hex}
              style={{
                backgroundColor: hexWithoutOpacity,
                color: darkTextColorIfBGisBright(hexWithoutOpacity),
              }}
            >
              {hex}
            </Option>
          );
        })
      }
    </Select>
  );
});
