import { Select, Space } from "antd";
import { ModsPanelCompProps } from "../ModsPanel";
import {
  Animation,
  ITransformChangeMod,
  findLayersOrLayerAssets,
  transformChangeTypes,
} from "@blings/blings-player";
import {
  DynamicValueMod,
  DynamicValueModTypes,
  dynamicValueType,
} from "../DynamicValueMod";
import { LayerSelector } from "../../LayerSelector";
import { useEffect, useState } from "react";
import { InfoTooltip } from "../../../BaseComps/InfoTooltip";
import { VisualLayer } from "@blings/blings-player/lib/src/libs/l/types";
import { SplitVector } from "@blings/blings-player/lib/src/libs/l/types/animated-properties";

export function TransformChangeForm(
  props: ModsPanelCompProps<ITransformChangeMod>
) {
  const [selectedValueType, setSelectedValueType] = useState<dynamicValueType>(
    "value"
  );
  const { change, json } = props;
  const [mainTransformChangeType, setMainTransformChangeType] = useState<
    "position" | "rotation" | "scale"
  >(
    change.transformChangeType === transformChangeTypes.rotation
      ? "rotation"
      : change.transformChangeType === transformChangeTypes.positionX ||
        change.transformChangeType === transformChangeTypes.positionY
      ? "position"
      : "scale"
  );
  useEffect(() => {
    const { transformChangeType } = change;
    if (
      (transformChangeType === transformChangeTypes.positionX ||
        transformChangeType === transformChangeTypes.positionY) &&
      mainTransformChangeType === "position"
    )
      return;
    if (
      transformChangeType === transformChangeTypes.rotation &&
      mainTransformChangeType === "rotation"
    )
      return;
    if (
      (transformChangeType === transformChangeTypes.scale ||
        transformChangeType === transformChangeTypes.scaleX ||
        transformChangeType === transformChangeTypes.scaleY) &&
      mainTransformChangeType === "scale"
    )
      return;
    const type =
      mainTransformChangeType === "scale"
        ? transformChangeTypes.scale
        : mainTransformChangeType === "position"
        ? transformChangeTypes.positionX
        : transformChangeTypes.rotation;
    props.onChange(props.index, "transformChangeType", type);
    if (selectedValueType !== "value") return;
    const value = extractValue(json, {
      ...change,
      transformChangeType: type,
    });
    if (value != null) {
      props.onChange(props.index, "value", value);
    }
  }, [mainTransformChangeType]);
  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <div>
        <LayerSelector
          isMulti={true}
          assetLayerPairs={props.assetLayerPairs}
          selectedAssetId={change.assetId}
          selectedLayerName={change.layerName}
          selectedLayerUid={change.layerUid}
          selectedAdditionals={change.additionals}
          onChange={(
            index: number,
            assetId?: string,
            layerName?: string,
            layerUid?: number,
            additionals?: Array<{
              assetId?: string;
              layerName?: string;
            }>
          ) => {
            if (!change.layerName && layerName) {
              const value = extractValue(json, {
                ...change,
                transformChangeType: change.transformChangeType,
                layerName,
                assetId,
                layerUid,
              });
              if (value != null) {
                props.onChange(props.index, "value", value);
              }
            }
            props.onLayerChange(
              index,
              assetId,
              layerName,
              layerUid,
              additionals
            );
          }}
          index={props.index}
          jsonVid={json}
        />
      </div>
      <Select
        size="large"
        style={{ width: "100%" }}
        onChange={(val) => {
          setMainTransformChangeType(val);
        }}
        value={mainTransformChangeType}
      >
        <Select.Option value={"position"}>Position</Select.Option>
        <Select.Option value={"rotation"}>Rotation</Select.Option>
        <Select.Option value={"scale"}>Scale</Select.Option>
      </Select>

      {mainTransformChangeType !== transformChangeTypes.rotation ? (
        <Select
          size="large"
          style={{ width: "100%" }}
          value={change.transformChangeType}
          onChange={(val) => {
            props.onChange(props.index, "transformChangeType", val);
            const value = extractValue(json, {
              ...change,
              transformChangeType: val,
            });
            if (value != null) {
              props.onChange(props.index, "value", value);
            }
          }}
        >
          {mainTransformChangeType === "position" ? (
            <>
              <Select.Option value={transformChangeTypes.positionX}>
                X position
              </Select.Option>
              <Select.Option value={transformChangeTypes.positionY}>
                Y position
              </Select.Option>
            </>
          ) : (
            <>
              <Select.Option value={transformChangeTypes.scale}>
                Uniform (keep aspect ration)
              </Select.Option>
              <Select.Option value={transformChangeTypes.scaleX}>
                Only X
              </Select.Option>
              <Select.Option value={transformChangeTypes.scaleY}>
                Only Y
              </Select.Option>
            </>
          )}
        </Select>
      ) : null}
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Select
          className="select-select"
          style={{ display: "flex", flex: 1 }}
          size="large"
          value={change.isAbsolute}
          onChange={(val) => {
            props.onChange(props.index, "isAbsolute", val);
            if (selectedValueType !== "value") return;
            const value = extractValue(json, {
              ...change,
              isAbsolute: val,
            });
            if (value != null) {
              props.onChange(props.index, "value", value);
            }
          }}
        >
          <Select.Option value={false}>Relative scaling</Select.Option>
          <Select.Option value={true}>Absolute value</Select.Option>
        </Select>
        <InfoTooltip info="" style={{ marginLeft: "0.5rem" }}></InfoTooltip>
      </div>
      <DynamicValueMod
        change={props.change}
        onChange={props.onChange}
        index={props.index}
        type={
          change.isAbsolute
            ? DynamicValueModTypes.number
            : DynamicValueModTypes.percentage
        }
        onChangeType={(type) => {
          if (type !== "value" || change.value != null) return;
          const value = extractValue(json, {
            ...change,
            transformChangeType: change.transformChangeType,
          });
          if (value != null) {
            props.onChange(props.index, "value", value);
          }
          setSelectedValueType(type);
        }}
      />
    </Space>
  );
}
function extractValue(json: Animation, change: ITransformChangeMod) {
  const layers = findLayersOrLayerAssets({
    jsonVid: json,
    layerName: change.layerName,
    assetId: change.assetId,
    layerUid: change.layerUid,
  }) as Array<VisualLayer>;
  if (!layers || !layers.length) return;
  const layer = layers[0];
  if (!change.isAbsolute) return 100;
  const transformScale = layer.ks?.s;
  const transformPosition = layer.ks?.p;
  const transformRotation = "r" in layer.ks ? layer.ks?.r : undefined;
  switch (change.transformChangeType) {
    case transformChangeTypes.rotation:
      if (!transformRotation) return;
      if (transformRotation.a === 0) {
        return transformRotation.k;
      } else {
        return transformRotation.k[0].s[0];
      }
    case transformChangeTypes.positionX:
      if (!transformPosition) return;
      if (isSplitVector(transformPosition)) {
        if (transformPosition && transformPosition.x?.a === 0) {
          return transformPosition.x.k;
        } else {
          return transformPosition?.x?.k[0].s[0];
        }
      } else {
        if (transformPosition && transformPosition.a === 1) {
          return transformPosition.k[0].s[0];
        } else {
          return transformPosition.k[0];
        }
      }
    case transformChangeTypes.positionY:
      if (!transformPosition) return;
      if (isSplitVector(transformPosition)) {
        if (transformPosition && transformPosition.x?.a === 0) {
          return transformPosition.y.k;
        } else {
          return transformPosition?.y?.k[0].s[0];
        }
      } else {
        if (transformPosition && transformPosition.a === 1) {
          return transformPosition.k[0].s[1];
        } else {
          return transformPosition.k[1];
        }
      }
    case transformChangeTypes.scale:
    case transformChangeTypes.scaleX:
      if (!transformScale) return;
      if (transformScale.a === 0) {
        return transformScale.k[0];
      } else {
        return transformScale.k[0].s[0];
      }

    case transformChangeTypes.scaleY:
      if (!transformScale) return;
      if (transformScale.a === 0) {
        return transformScale.k[1];
      } else {
        return transformScale.k[0].s[1];
      }

    default:
      break;
  }
}

function isSplitVector(value: any): value is SplitVector {
  return typeof value === "object" && "s" in value;
}
