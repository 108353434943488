import { Instance, types } from "mobx-state-tree";
import {
  deleteNotification,
  updateNotification,
} from "../../graphql/mutations";
import { NotificationStatus } from "../../API";
import { NotificationType } from "./Notification";
import { generateClient } from "aws-amplify/api";

const client = generateClient();

/**
 * This is a Normal Notification received from the server when querying the database or through a subscription
 * This defines the type of the notification to be used in the mobx-state-tree store
 */
export const Notification = types
  .model("Notification", {
    id: types.identifier,
    type: types.enumeration(
      "NotificationType",
      Object.values(NotificationType)
    ),
    subType: types.maybeNull(types.string),
    status: types.enumeration(
      "NotificationStatus",
      Object.values(NotificationStatus)
    ),
    title: types.string,
    message: types.string,
    read: false,
    owner: types.string,
    projectId: types.maybeNull(types.string),
    extra: types.maybeNull(types.frozen()),
    createdAt: types.string,
    updatedAt: types.string,
  })
  .volatile((self) => ({
    /**
     * The callback function that will be called when a notification is clicked on the notification bell dropdown.
     * @param notification The notification received from the server
     * @returns Should not return anything because the notification store will not handle the returned value
     */
    onClickCallback: null as any,
  }))
  .views((self) => ({
    /**
     * Get the notification info in a normal object
     * @returns The notification info in a normal object
     */
    getNotificationInfo: () => {
      return {
        id: self.id,
        type: self.type,
        subType: self.subType,
        status: self.status,
        title: self.title,
        message: self.message,
        read: self.read,
        owner: self.owner,
        projectId: self.projectId,
        extra: self.extra,
        createdAt: self.createdAt,
        updatedAt: self.updatedAt,
      };
    },
  }))
  .actions((self) => ({
    setRead(read: boolean) {
      self.read = read;
    },
    /**
     * This function will update the notification with the new values.
     * @param notification The notification new values to be updated
     */
    __setNotification(notification: any) {
      self.id = notification.id || self.id;
      self.type = notification.type || self.type;
      self.subType = notification.subType || self.subType;
      self.status = notification.status || self.status;
      self.title = notification.title || self.title;
      self.message = notification.message || self.message;
      self.read = notification.read || self.read;
      self.owner = notification.owner || self.owner;
      self.projectId = notification.projectId || self.projectId;
      try {
        self.extra = JSON.parse(notification.extra);
      } catch (e) {
        self.extra = self.extra;
      }
      self.createdAt = notification.createdAt || self.createdAt;
      self.updatedAt = notification.updatedAt || self.updatedAt;
    },
  }))
  // This defines the actions that can be done on the notification
  .actions((self) => ({
    /**
     * This function will mark the notification as read
     * This will be done by sending a mutation to the server and updating locally
     * If the notification is already read, this function will do nothing
     * @returns The updated notification
     * @throws An error if the notification could not be updated
     */
    markAsRead: async () => {
      if (!self.read) {
        // Update the notification on the database to be marked as read
        await client.graphql({
          query: updateNotification,
          variables: {
            input: { id: self.id, read: true },
          },
        });
        // Update the notification locally to be marked as read
        self.setRead(true);
      }
    },

    /**
     * Updates the current notification with the new values and updates it on the database
     * @param notification The notification new values to be updated
     */
    updateNotification: (notification: any, shouldUpdateOnDB = false) => {
      self.__setNotification(notification);
      if (shouldUpdateOnDB) {
        client.graphql({
          query: updateNotification,
          variables: { input: notification },
        });
      }
    },

    /**
     * Delete the current notification from the database
     */
    deleteNotification: async () => {
      // Remove the notification from the database
      await client.graphql({
        query: deleteNotification,
        variables: { input: { id: self.id } },
      });
    },

    /**
     * Since the MobX State Tree does now allow to create callbacks in the model nor in the volatile fields,
     * We need to create a function that will set the callback.
     * @param callback The callback function that will be called when a notification that matches the subscription is received.
     */
    setCallback(callback: (params: any) => void) {
      self.onClickCallback = callback;
    },

    /**
     * Based on the notification type, this function will define the callback function that will be set on this notification
     */
    processNotificationOnClickCallback() {
      switch (self.type) {
        case NotificationType.Render:
          // If the notification is of the render type, check the subType to see what to do
          if (self.subType === "download-ready") {
            // Add the callback to download the render using the extra.downloadUrl field
            this.setCallback(() => {
              window.open(self.extra.downloadUrl, "_blank");
            });
            this.updateNotification({ message: "Downloaded" });
          }
          break;
      }
    },
  }));

export type INotification = Instance<typeof Notification>;
